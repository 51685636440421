import React, { useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ethers } from 'ethers';
import Skeleton from 'react-loading-skeleton';
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from '@material-ui/icons';
import Loader from 'react-loader-spinner';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import axios from 'axios';
import ReactPlayer from 'react-player';

import SuspenseImg from 'components/SuspenseImg';
import { formatNumber } from 'utils';
import { useApi } from 'api';
import { useAuctionContract } from 'contracts';
import useTokens from 'hooks/useTokens';
import RenderPrice from './Price/index';

import Verified from 'assets/imgs/verified.png';

import iconPlus from 'assets/svgs/plus.svg';
import ETHBlack from 'assets/imgs/ethblack.png';
import ETHGray from 'assets/imgs/ethgray.png';
import ImageError from 'assets/imgs/error1.png';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const ONE_MIN = 60;
const ONE_HOUR = ONE_MIN * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;

const BaseCard = ({
  item,
  loading,
  style,
  create,
  onCreate,
  onLike,
  newestListing,
}) => {
  const { likeItem, likeBundle } = useApi();
  const { getAuction, getHighestBidder } = useAuctionContract();
  const { getTokenByAddress } = useTokens();

  const { t } = useTranslation('common');

  const [now, setNow] = useState(new Date());
  const [fetching, setFetching] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [info, setInfo] = useState(null);
  const [index, setIndex] = useState(0);
  const [isLike, setIsLike] = useState(false);
  const [liked, setLiked] = useState(0);
  const [auction, setAuction] = useState(null);

  const { collections } = useSelector(state => state.Collections);
  const { authToken } = useSelector(state => state.ConnectWallet);

  // const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  const collection = collections.find(
    col => col.address === item?.contractAddress
  );

  const getTokenURI = async tokenURI => {
    setFetching(true);
    try {
      const { data } = await axios.get(tokenURI);
      setInfo(data);
    } catch {
      setInfo(null);
    }
    setFetching(false);
  };

  const getCurrentAuction = async () => {
    try {
      const _auction = await getAuction(item.contractAddress, item.tokenID);
      if (_auction.endTime !== 0) {
        const token = getTokenByAddress(_auction.payToken);
        _auction.reservePrice = parseFloat(
          ethers.utils.formatUnits(_auction.reservePrice, token.decimals)
        );
        _auction.token = token;
        const bidder = await getHighestBidder(
          item.contractAddress,
          item.tokenID,
          _auction.payToken
        );
        setAuction({
          ..._auction,
          highestBid: bidder.bid,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (item && !item.name) {
      getTokenURI(item.tokenURI);
    }
    if (item) {
      setLiked(item.liked);
      if (item.items) {
        setAuction(null);
      } else {
        getCurrentAuction();
      }
    }
  }, [item]);

  useEffect(() => {
    if (item?.isLiked !== undefined) {
      setIsLike(item.isLiked);
    }
  }, [item?.isLiked]);

  useEffect(() => {
    setInterval(() => {
      setNow(new Date());
    }, 1000);
  }, []);

  const auctionStarted = now.getTime() / 1000 >= auction?.startTime;

  const auctionEnded = auction?.endTime <= now.getTime() / 1000;

  const auctionActive = auctionStarted && !auctionEnded;

  const toggleFavorite = async e => {
    e.preventDefault();
    if (isLiking) return;

    setIsLiking(true);
    try {
      if (item.items) {
        const { data } = await likeBundle(item._id, authToken);
        setLiked(data);
      } else {
        const { data } = await likeItem(
          item.contractAddress,
          item.tokenID,
          authToken
        );
        setLiked(data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLike(!isLike);
    setIsLiking(false);

    onLike && onLike();
  };

  const formatDiff = diff => {
    if (diff >= ONE_MONTH) {
      const m = Math.ceil(diff / ONE_MONTH);
      return `${m} ${m > 1 ? t('units.months') : t('units.month')}`;
    }
    if (diff >= ONE_DAY) {
      const d = Math.ceil(diff / ONE_DAY);
      return `${d} ${d > 1 ? t('units.days') : t('units.day')}`;
    }
    if (diff >= ONE_HOUR) {
      const h = Math.ceil(diff / ONE_HOUR);
      return `${h} ${h > 1 ? t('units.hours') : t('units.hour')}`;
    }
    if (diff >= ONE_MIN) {
      const mm = Math.ceil(diff / ONE_MIN);
      return `${mm} ${mm > 1 ? t('units.minutes') : t('units.minute')}`;
    }
    return `${diff} ${diff > 1 ? t('units.seconds') : t('units.second')}`;
  };

  const formatDuration = endTime => {
    const diff = endTime - Math.floor(now.getTime() / 1000);
    return formatDiff(diff);
  };

  const renderSlides = () => {
    return item.items.map((v, idx) => (
      <div className={styles.imageBox} key={idx}>
        {(v.imageURL || v.thumbnailPath?.length > 10) &&
          (v.imageURL?.includes('youtube') ? (
            <ReactPlayer
              className={styles.media}
              url={v.imageURL}
              controls={true}
              width="100%"
              height="100%"
            />
          ) : (
            <Suspense
              fallback={
                <Loader
                  type="Oval"
                  color="#007BFF"
                  height={32}
                  width={32}
                  className={styles.loader}
                />
              }
            >
              <SuspenseImg
                src={v.imageURL}
                className={styles.media}
                alt={v.name}
              />
            </Suspense>
          ))}
      </div>
    ));
  };

  const renderContent = () => {
    return (
      <>
        <div className={styles.mediaBox}>
          <div className={styles.wrapper}>
            <div className={styles.mediaPanel}>
              {loading || fetching ? (
                <Skeleton
                  width="100%"
                  height="100%"
                  className={styles.mediaLoading}
                />
              ) : item.items ? (
                <>
                  <Carousel
                    className={styles.carousel}
                    plugins={['fastSwipe']}
                    value={index}
                    onChange={_index =>
                      setIndex(Math.min(Math.max(_index, 0), 2))
                    }
                    slides={renderSlides()}
                    numberOfInfiniteClones={1}
                  />
                </>
              ) : (
                <div className={styles.imageBox}>
                  {(item?.imageURL ||
                    !item?.imageURL ||
                    info?.image ||
                    item?.thumbnailPath?.length > 10 ||
                    item?.thumbnailPath === 'embed') &&
                    (item?.thumbnailPath === 'embed' ? (
                      <iframe src={item?.imageURL} className={styles.media} />
                    ) : (item?.imageURL || info?.image)?.includes('youtube') ? (
                      <ReactPlayer
                        className={styles.media}
                        url={item?.imageURL || info?.image}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <Suspense
                        fallback={
                          <Loader
                            type="Oval"
                            color="#007BFF"
                            height={32}
                            width={32}
                            className={styles.loader}
                          />
                        }
                      >
                        <SuspenseImg
                          src={
                            item?.imageURL ===
                            'https://fookstaging.mypinata.cloud/ipfs/undefined'
                              ? ImageError
                              : item?.imageURL || info?.image
                          }
                          className={styles.media}
                          alt={item.name}
                        />
                      </Suspense>
                    ))}
                </div>
              )}
            </div>
            <div className={styles.content}>
              <div className={styles.topLine}>
                <div className={styles.itemName}>
                  {loading || fetching ? (
                    <Skeleton width={100} height={20} />
                  ) : (
                    <div className={styles.label}>
                      <div className={styles.colName}>
                        {collection?.collectionName || collection?.name}
                        {collection?.isVerified && (
                          <img
                            src={Verified}
                            className={styles.verified}
                            alt="verified"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {!loading && (
                  <div className={styles.label}>
                    {auctionActive ? t('generic.auction') : t('generic.price')}
                  </div>
                )}
              </div>
              <div className={styles.alignBottom}>
                {loading || fetching ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  <div className={styles.name}>{item?.name || info?.name}</div>
                )}
                <div className={styles.priceCol}>
                  <RenderPrice
                    auction={auction}
                    item={item}
                    auctionActive={auctionActive}
                    loading={loading}
                    fetching={fetching}
                  />
                  {auctionActive ? (
                    <div className={styles.timeLeft}>
                      {!loading && (
                        <>
                          <span className={styles.label2}>
                            {t('generic.timeLeft')}
                          </span>
                          <span className={styles.name2}>
                            {formatDuration(auction.endTime)}
                          </span>
                        </>
                      )}
                    </div>
                  ) : (
                    item?.lastSalePrice > 0 && (
                      <div className={styles.alignRight}>
                        {!loading && (
                          <div className={styles.label2}>
                            {t('generic.lastPrice')}
                          </div>
                        )}
                        {loading || fetching ? (
                          <Skeleton width={80} height={20} />
                        ) : (
                          <div className={cx(styles.label2, styles.price2)}>
                            <img src={ETHBlack} />
                            <span className={styles.lastPrice}>
                              {formatNumber(item.lastSalePrice)}
                            </span>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>

                {/* {loading || fetching ? (
              <Skeleton width={80} height={20} />
            ) : (
              <div className={styles.label}>
                {item.items
                  ? `${item.items.length} item${
                      item.items.length !== 1 ? 's' : ''
                    }`
                  : `${formatNumber(
                      item?.holderSupply || item?.supply || 1
                    )} of ${formatNumber(item?.supply || 1)}`}
              </div>
            )} */}
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles.cardFooter, isLike && styles.liking)}>
          {!item ? (
            <Skeleton width={'100%'} height={20} />
          ) : (
            <>
              {item.price ? (
                <span className={styles.buyNow}>On Sale</span>
              ) : newestListing ? (
                <div className={styles.ETHIcon}>
                  <img src={ETHGray} />
                </div>
              ) : (
                <img src={ETHGray} />
              )}

              <div className={styles.footerRight}>
                {isLike ? (
                  <FavoriteIcon
                    className={styles.favIcon}
                    onClick={toggleFavorite}
                  />
                ) : (
                  <FavoriteBorderIcon
                    className={styles.favIcon}
                    onClick={toggleFavorite}
                  />
                )}
                <span className={styles.favLabel}>
                  {liked || item.liked || 0}
                </span>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      style={style}
      className={cx(styles.root, newestListing && styles.newestListing)}
      onClick={onCreate}
    >
      <div className={styles.card}>
        {create ? (
          <div className={styles.createBtn}>
            <div className={styles.createIcon}>
              <img src={iconPlus} />
            </div>
            <div className={styles.createLabel}>
              {t('bundleModal.createBundle')}
            </div>
          </div>
        ) : item ? (
          <Link
            to={
              item.items
                ? `/bundle/${item._id}`
                : `/explore/${item.contractAddress}/${item.tokenID}`
            }
            className={styles.link}
          >
            {renderContent()}
          </Link>
        ) : (
          renderContent()
        )}
      </div>
      {item?.tokenType === 1155 && (
        <>
          <div className={styles.card} />
          <div className={styles.card} />
        </>
      )}
    </div>
  );
};

export default React.memo(BaseCard);
