import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import toast from 'utils/toast';
import { useApi } from 'api';
import { getSigner } from 'contracts';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import { useTranslation } from 'react-i18next';

const ModModal = ({ visible, onClose, isAdding }) => {
  const { t } = useTranslation('common');
  const { getNonce, addMod, removeMod } = useApi();
  const { account } = useWeb3React();

  const { authToken } = useSelector(state => state.ConnectWallet);

  const [adding, setAdding] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (visible) {
      setAdding(false);
    }
  }, [visible]);

  const handleAddMod = async () => {
    if (adding) return;

    try {
      setAdding(true);

      const { data: nonce } = await getNonce(account, authToken);

      let signature;
      let addr;
      try {
        const signer = await getSigner();
        const msg = `Validate user action with ID: ${nonce}`;
        signature = await signer.signMessage(msg);
        addr = ethers.utils.verifyMessage(msg, signature);
      } catch (err) {
        toast(
          'error',
          t('errors.modSign', {
            arg1: isAdding ? t('modModal.add') : t('modModal.remove'),
          })
        );
        setAdding(false);
        return;
      }

      if (isAdding) {
        await addMod(name, address, authToken, signature, addr);
        toast('success', t('modModal.modAdded'));
        onClose();
      } else {
        await removeMod(address, authToken, signature, addr);
        toast('success', t('modModal.modRemoved'));
        onClose();
      }
    } catch (e) {
      console.log(e);
    }
    setAdding(false);
  };

  return (
    <Modal
      visible={visible}
      title={isAdding ? t('modModal.modAdd') : t('modModal.modRemove')}
      onClose={onClose}
      submitDisabled={adding}
      submitLabel={
        adding ? (
          <ClipLoader color="#FFF" size={16} />
        ) : isAdding ? (
          t('modModal.add').toUpperCase()
        ) : (
          t('modModal.remove').toUpperCase()
        )
      }
      onSubmit={!adding ? () => handleAddMod() : null}
    >
      {isAdding && (
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>{t('generic.name')}</div>
          <div className={styles.formInputCont}>
            <input
              className={styles.formInput}
              placeholder={t('generic.name')}
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={adding}
            />
          </div>
        </div>
      )}
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>{t('generic.address')}</div>
        <div className={styles.formInputCont}>
          <input
            className={styles.formInput}
            placeholder="0x0000"
            value={address}
            onChange={e => setAddress(e.target.value)}
            disabled={adding}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModModal;
