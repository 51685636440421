import React, { useState, useEffect } from 'react';
import DateTime from '../DateTimeModal'; 

const DateTimePicker = ({value, onChange, disabled, isValidDate}) => {
	const [currentDateTime, setCurrentDateTime] = useState(value);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModalSubmit = (newDateTime) => {
		onChange(newDateTime);
		setCurrentDateTime(newDateTime);
		setIsModalOpen(false);
	};

	useEffect(() => {
		setCurrentDateTime(value);
	}, [value]);

	return (
		<div data-testid='date-time-wrapper'>
			<span onClick={() => setIsModalOpen(true)} data-testid='datetime-input'>
				{currentDateTime.toLocaleString()}
			</span>
			{isModalOpen && 
				<DateTime 
					visible={isModalOpen} 
					onClose={() => setIsModalOpen(false)} 
					handleSubmit={handleModalSubmit}
					disabled={disabled}
					onStart={value}
					isValidDate={isValidDate}
				/>
			}
		</div>
	)
}

export default DateTimePicker;