import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import NotFound from 'components/NotFound';

// Error logging function (to Sentry)
const logErrorToService = (error, info) => {
  console.error('Caught an error:', error, info);
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={() => (
        <NotFound isInternal={true} title={500} description={'notFound.500'} />
      )}
      onReset={() => {}}
      onError={logErrorToService}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
