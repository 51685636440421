// eslint-disable-next-line no-undef

import { useApi } from 'api';
import { useNFTContract } from 'contracts';
import { useCallback, useState } from 'react';
import { shortenAddress } from 'utils';

const useGetOwner = () => {
  const { getUserAccountDetails } = useApi();
  const { getERC721Contract } = useNFTContract();
  const [owner, setOwner] = useState('');

  const getOwner = useCallback(async (tokenID, collection) => {
    if (typeof tokenID !== 'undefined' && typeof collection !== 'undefined') {
      const contract = await getERC721Contract(collection);
      const ownerAddress = await contract.ownerOf(tokenID);
      if (ownerAddress) {
        const { data } = await getUserAccountDetails(ownerAddress);
        if (data && typeof data.alias !== 'undefined') {
          setOwner(data.alias);
          return data.alias;
        }
        setOwner(shortenAddress(ownerAddress));
        return shortenAddress(ownerAddress);
      }
    }
    return owner;
  }, []);

  return { getOwner };
};

export default useGetOwner;
