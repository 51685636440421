import styles from '../styles.module.scss';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';
import { formatNumberCard } from '../../../utils';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import ETHBlack from 'assets/imgs/ethblack.png';

const RenderPrice = ({ auction, item, auctionActive, loading, fetching }) => {
  const auctionActiveOrPending =
    !auctionActive && auction && auction.highestBid !== 0;

  return auction?.reservePrice || item?.price ? (
    <div className={styles.alignRight}>
      {loading || fetching ? (
        <Skeleton width={80} height={20} />
      ) : (
        <div className={cx(styles.label, styles.price)}>
          <img src={ETHBlack} />
          {formatNumberCard(
            auctionActive
              ? auction.highestBid > auction.reservePrice
                ? auction.highestBid
                : auction.reservePrice
              : auctionActiveOrPending
              ? auction.highestBid
              : item.price
          )}
        </div>
      )}
    </div>
  ) : (
    ''
  );
};

export default RenderPrice;
