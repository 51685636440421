import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';

const TransferModal = ({
  visible,
  totalSupply,
  transferring,
  onTransfer,
  onClose,
}) => {
  const [address, setAddress] = useState('');
  const [quantity, setQuantity] = useState('1');
  const { t } = useTranslation('common');

  useEffect(() => {
    if (visible) {
      setAddress('');
      setQuantity('1');
    }
  }, [visible]);

  const handleQuantityChange = e => {
    const val = e.target.value;
    if (!val) {
      setQuantity('');
      return;
    }

    if (isNaN(val)) return;

    const _quantity = parseInt(val);
    setQuantity(Math.min(_quantity, totalSupply));
  };

  const handleTransfer = () => {
    let quant = 1;
    if (totalSupply > 1) {
      quant = parseInt(quantity);
    }
    onTransfer(address, quant);
  };

  return (
    <Modal
      visible={visible}
      title={t('transferModal.transferItem')}
      onClose={onClose}
      submitDisabled={transferring}
      submitLabel={
        transferring ? (
          <ClipLoader color="#FFF" size={16} />
        ) : (
          t('transferModal.transfer')
        )
      }
      onSubmit={!transferring ? () => handleTransfer() : null}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>{t('transferModal.transferTo')}</div>
        <div className={styles.formInputCont}>
          <input
            className={styles.formInput}
            placeholder="0x0000"
            value={address}
            onChange={e => setAddress(e.target.value)}
            disabled={transferring}
          />
        </div>
      </div>
      {totalSupply !== null && (
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>{t('generic.quantity')}</div>
          <div className={styles.formInputCont}>
            <input
              className={styles.formInput}
              placeholder={totalSupply}
              value={quantity}
              onChange={handleQuantityChange}
              disabled={transferring || totalSupply === 1}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TransferModal;
