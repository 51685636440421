import * as React from "react"

const IconMyOffers = (props) => (
  <svg
    width={16}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.285 3.408a.5.5 0 0 0-1 0v.781c-.903.199-1.636.922-1.636 1.878 0 1.127 1.021 1.932 2.136 1.932.692 0 1.136.478 1.136.932 0 .454-.444.931-1.136.931s-1.136-.477-1.136-.931a.5.5 0 1 0-1 0c0 .955.733 1.679 1.636 1.877v.782a.5.5 0 1 0 1 0v-.782c.903-.198 1.636-.922 1.636-1.877 0-1.128-1.02-1.932-2.136-1.932-.692 0-1.136-.478-1.136-.932 0-.45.437-.924 1.12-.932H7.8c.683.008 1.12.482 1.12.932a.5.5 0 0 0 1 0c0-.956-.733-1.68-1.636-1.878v-.78Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.285 7.5a7.501 7.501 0 0 1-6.75 7.463v3.287a.75.75 0 0 1-1.5 0v-3.287a7.501 7.501 0 1 1 8.25-7.463Zm-7.5 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      fill="#000"
    />
  </svg>
)

export default IconMyOffers
