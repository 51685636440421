require('dotenv').config()
const ethers = require('ethers');

// // eslint-disable-next-line no-undef
// const isMainnet = window.__RUNTIME_CONFIG__.REACT_APP_ENV === 'MAINNET';

const connectionInfo = {
    url: window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_RPC_URL,
    user: window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_USERNAME,
    password: window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_PASSWORD,
}

const provider = new ethers.providers.JsonRpcProvider(
    connectionInfo,
    parseInt(window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_CHAINID)
    // ethers.utils.hexlify(parseInt(window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_CHAINID))
);

export const ethProvider = new ethers.providers.JsonRpcProvider(
    {url: window.__RUNTIME_CONFIG__.REACT_APP_RPC_URL,},
    1
)

export default provider
