import { MainButton } from 'components/MainButton';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BootstrapTooltip from 'components/BootstrapTooltip';

import styles from './styles.module.scss';
import HeroBannerCarousel from 'components/HeroBannerCarousel';
import { useSelector } from 'react-redux';

const HeroBanner = () => {
  const { t } = useTranslation('common');
  const isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);

  return (
    <div 
      className={styles.wrapper} 
      style={!isBannerShown ? {paddingTop: '120px'}: {paddingTop: '155px'}}
    >
      <div className={styles.content}>
        <div className={styles.background} />
        <div className={styles.title}>{t('landing.title')}</div>
        <div className={styles.subtitle}>{t('landing.subtitle')}</div>
        <div className={styles.mainButtons}>
          <Link to="/explore" className={styles.exploreButton}>
            <MainButton small>{t('buttons.explore')}</MainButton>
          </Link>
          <BootstrapTooltip
            title={'Coming soon'}
            placement="bottom"
          >
            <div className={styles.exploreButton}>
              <MainButton disabled={true} small outlined={true} black>
                {t('buttons.create')}
              </MainButton>
            </div>
          </BootstrapTooltip>
        </div>
        <div className={styles.card}>
          <HeroBannerCarousel />
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
