import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  Suspense,
} from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import axios from 'axios';
import { ethers } from 'ethers';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Skeleton from 'react-loading-skeleton';
import ReactResizeDetector from 'react-resize-detector';
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Line,
} from 'recharts';
import nahmiiChainId from '../../constants/nahmiiChainId';
import { useWeb3React } from '@web3-react/core';
import { ClipLoader } from 'react-spinners';
import { Menu, MenuItem } from '@material-ui/core';
import {
  ViewModule as ViewModuleIcon,
  Timeline as TimelineIcon,
  LocalOffer as LocalOfferIcon,
  Toc as TocIcon,
  Label as LabelIcon,
  Loyalty as LoyaltyIcon,
  Redeem as RedeemIcon,
  DvrOutlined,
} from '@material-ui/icons';
import toast from 'react-hot-toast';

import Panel from 'components/Panel';
import Identicon from 'components/Identicon';
import { useApi } from 'api';
import {
  useNFTContract,
  useSalesContract,
  useAuctionContract,
  getSigner,
} from 'contracts';
import { formatNumber, formatError, isScientificNotation, getNumberAfterMinus } from 'utils';
import { Contracts } from 'constants/networks';
import showToast from 'utils/toast';
import NFTCard from 'components/NFTCard';
import TxButton from 'components/TxButton';
import TransferModal from 'components/TransferModal';
import SellModal from 'components/SellModal';
import OfferModal from 'components/OfferModal';
import AuctionModal from 'components/AuctionModal';
import BidModal from 'components/BidModal';
import OwnersModal from 'components/OwnersModal';
import LikesModal from 'components/LikesModal';
import Header from 'components/header';
import SuspenseImg from 'components/SuspenseImg';
import HeaderActions from 'actions/header.actions';
import useTokens from 'hooks/useTokens';
import usePrevious from 'hooks/usePrevious';

import filterIcon from 'assets/svgs/filter.svg';
import checkIcon from 'assets/svgs/check.svg';
import shareIcon from 'assets/svgs/share.svg';
import iconKiwii from 'assets/icons/kiwii-4.png';
import iconFacebook from 'assets/imgs/facebook.png';
import iconTwitter from 'assets/svgs/twitter_blue.svg';
// import SvgComponent from 'assets/svgs/attributes.svg';

import styles from './styles.module.scss';
import RenderProperties from '../../components/NFTItem/Properties';
import RenderCollectionPanel from '../../components/NFTItem/Collection';
import RenderAboutPanel from '../../components/NFTItem/AboutCollection';
import RenderStats from '../../components/NFTItem/Stats';
import RenderItemOwner from '../../components/NFTItem/ItemOwner';
import { useTranslation } from 'react-i18next';
import { MainButton } from 'components/MainButton';
import ModalActions from 'actions/modal.actions';

import ErrorBoundary from 'components/ErrorBoundary';

import WalletUtils from '../../utils/wallet';

const ONE_MIN = 60;
const ONE_HOUR = ONE_MIN * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_MONTH = ONE_DAY * 30;

const filters = ['Trade History', 'Transfer History'];

// eslint-disable-next-line no-undef
const ENV = window.__RUNTIME_CONFIG__.REACT_APP_ENV;
const withdrawBidDuration = parseInt(
  window.__RUNTIME_CONFIG__.REACT_APP_WITHDRAW_BID_DURATION
);
// const CHAIN = ENV === 'MAINNET' ? nahmiiChainId.MAINNET : nahmiiChainId.TESTNET;

const NFTItem = () => {
  const dispatch = useDispatch();
  const pageHistory = useHistory();
  const { t } = useTranslation('common');

  const {
    explorerUrl,
    fetchItemDetails,
    increaseViewCount,
    getTransferHistory,
    fetchCollection,
    getUserAccountDetails,
    get1155Info,
    getTokenHolders,
    isLikingItem,
    isLikingBundle,
    likeItem,
    likeBundle,
    getItemLikeUsers,
    getBundleLikeUsers,
    getItemsLiked,
    getNonce,
    retrieveUnlockableContent,
  } = useApi();
  const { getERC721Contract, getERC20Contract } = useNFTContract();
  const {
    getSalesContract,
    buyItemETH,
    buyItemERC20,
    cancelListing,
    listItem,
    updateListing,
    createOffer,
    cancelOffer,
    acceptOffer,
    getCollectionRoyalty,
  } = useSalesContract();
  const {
    getAuctionContract,
    getAuction,
    cancelAuction,
    createAuction,
    getHighestBidder,
    placeBid,
    resultAuction,
    updateAuctionStartTime,
    updateAuctionEndTime,
    updateAuctionReservePrice,
    withdrawBid,
  } = useAuctionContract();

  const { addr: address, id: tokenID, bundleID } = useParams();
  const { getTokenByAddress, tokens } = useTokens();

  const { account, chainId } = useWeb3React();

  const [salesContractApproved, setSalesContractApproved] = useState(false);
  const [salesContractApproving, setSalesContractApproving] = useState(false);

  const [itemOnSalesContractApproved, setItemOnSalesContractApproved] = useState(false);
  const [itemOnSalesContractApproving, setItemOnSalesContractApproving] = useState(false);

  const [
    bundleSalesContractApproved,
    setBundleSalesContractApproved,
  ] = useState({});
  const [auctionContractApproved, setAuctionContractApproved] = useState(false);
  const [auctionContractApproving, setAuctionContractApproving] = useState(
    false
  );

  const [previewIndex, setPreviewIndex] = useState(0);
  const [minBidIncrement, setMinBidIncrement] = useState(0);
  const [info, setInfo] = useState();
  const [owner, setOwner] = useState();
  const [ownerInfo, setOwnerInfo] = useState();
  const [ownerInfoLoading, setOwnerInfoLoading] = useState(false);
  const [tokenOwnerLoading, setTokenOwnerLoading] = useState(false);
  const tokenType = useRef();
  const contentType = useRef();
  const [tokenInfo, setTokenInfo] = useState();
  const [holders, setHolders] = useState([]);
  const likeUsers = useRef([]);
  const [collection, setCollection] = useState();
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [collectionRoyalty, setCollectionRoyalty] = useState(null);

  const [transactionHash, setTransactionHash] = useState('');
  const [blockNumberValue, setBlockNumberValue] = useState('');

  const [transferModalVisible, setTransferModalVisible] = useState(false);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [offerModalVisible, setOfferModalVisible] = useState(false);
  const [auctionModalVisible, setAuctionModalVisible] = useState(false);
  const [bidModalVisible, setBidModalVisible] = useState(false);
  const [ownersModalVisible, setOwnersModalVisible] = useState(false);
  const [likesModalVisible, setLikesModalVisible] = useState(false);

  const [transferring, setTransferring] = useState(false);
  const [listingItem, setListingItem] = useState(false);
  const [cancelingListing, setCancelingListing] = useState(false);
  const [priceUpdating, setPriceUpdating] = useState(false);
  const [offerPlacing, setOfferPlacing] = useState(false);
  const [offerCanceling, setOfferCanceling] = useState(false);
  const [offerAccepting, setOfferAccepting] = useState(false);
  const [buyingItem, setBuyingItem] = useState(false);
  const [auctionStarting, setAuctionStarting] = useState(false);
  const [auctionUpdating, setAuctionUpdating] = useState(false);
  const [auctionCanceling, setAuctionCanceling] = useState(false);
  // const [auctionWithdrawing, setAuctionWithdrawing] = useState(false);
  const [bidPlacing, setBidPlacing] = useState(false);
  const [bidWithdrawing, setBidWithdrawing] = useState(false);
  const [resulting, setResulting] = useState(false);
  const [resulted, setResulted] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [likeUsersFetching, setLikeUsersFetching] = useState(false);
  const [likeFetching, setLikeFetching] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [liked, setLiked] = useState();
  const [hasUnlockable, setHasUnlockable] = useState(false);
  const [revealing, setRevealing] = useState(false);
  const [unlockableContent, setUnlockableContent] = useState('');
  const [rerenderNewTradeHistory, setRerenderNewTradeHistory] = useState(true);

  const [listings, setListings] = useState([]);
  const [attritbutes, setAttributes] = useState([]);

  const [bid, setBid] = useState(null);
  const [views, setViews] = useState(0);
  const [now, setNow] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const auction = useRef(null);
  const bundleListing = useRef(null);
  const bundleItems = useRef([]);
  const offers = useRef([]);
  const tradeHistory = useRef([]);
  const transferHistory = useRef([]);
  const moreItems = useRef([]);
  const [prices, setPrices] = useState({});
  const [priceInterval, setPriceInterval] = useState(null);

  const [likeCancelSource, setLikeCancelSource] = useState(null);
  const [filter, setFilter] = useState(0);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const prevSalesContract = useRef(null);
  const prevAuctionContract = useRef(null);

  const priceData = useSelector(state => state.Price.price);
  const isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);
  const { authToken } = useSelector(state => state.ConnectWallet);
  const prevAuthToken = usePrevious(authToken);

  const [selectedOffer, setSelectedOffer] = useState(null);

  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  const isLoggedIn = () => {
    return (
      account &&
      (ENV === 'MAINNET'
        ? chainId === nahmiiChainId.MAINNET
        : chainId === nahmiiChainId.TESTNET)
    );
  };

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
  }, []);

  const getPrices = async () => {
    try {
      const _prices = {
        '0x4200000000000000000000000000000000000006': priceData,
        '0x0000000000000000000000000000000000000000': priceData,
      };
      setPrices(_prices);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!tokens) return;

    if (priceInterval) {
      clearInterval(priceInterval);
    }

    getPrices();
    setPriceInterval(setInterval(getPrices, 1000 * 10));

    return () => {
      if (priceInterval) {
        clearInterval(priceInterval);
      }
    };
  }, [tokens]);

  const getItemDetails = async () => {
    setLoading(true);
    setTokenOwnerLoading(true);
    setHistoryLoading(true);
    tradeHistory.current = [];

    try {
      const {
        data: {
          contentType: _contentType,
          history,
          likes,
          listings: _listings,
          offers: _offers,
          nfts,
          tokenType: type,
          uri,
          hasUnlockable: _hasUnlockable,
        },
      } = await fetchItemDetails(address, tokenID);
      contentType.current = _contentType;
      tradeHistory.current = history
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .map(history => ({
          ...history,
          token: getTokenByAddress(history.paymentToken),
        }));
      setLiked(likes);
      setHasUnlockable(_hasUnlockable);
      setRerenderNewTradeHistory(false);
      setListings(
        _listings.length
          ? _listings.map(listing => ({
              ...listing,
              token: getTokenByAddress(listing.paymentToken),
            }))
          : []
      );

      offers.current = _offers.map(offer => ({
        ...offer,
        token: getTokenByAddress(offer.paymentToken),
      }));
      moreItems.current = nfts;

      try {
        const metadatData = await axios({
          method: 'get',
          url: `${uri}`,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setAttributes(metadatData?.data?.attributes);
      } catch (error) {
        console.error(error);
      }

      try {
        tokenType.current = type;
        if (type === 721) {
          const contract = await getERC721Contract(address);
          const res = await contract.ownerOf(tokenID);
          setOwner(res);
        } else if (type === 1155) {
          const { data: _tokenInfo } = await get1155Info(address, tokenID);
          setTokenInfo(_tokenInfo);
          try {
            const { data: _holders } = await getTokenHolders(address, tokenID);
            setHolders(_holders);
          } catch {
            setHolders([]);
          }
          setOwner(null);
        }
      } catch {
        setOwner(null);
      }

      let data;
      const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (base64regex.test(uri)) {
        const string = atob(uri);
        data = JSON.parse(string);
      } else {
        const realUri = uri.includes('ipfs://')
          ? `${infuraUrl}/${uri.split('//')[1]}`
          : uri;

        new URL(realUri);

        const response = await axios.get(realUri);
        data = response.data;
      }
      if (data.properties) {
        data.properties.royalty = parseInt(data.properties.royalty) / 100;
      }
      setInfo(data);
    } catch (error) {
      if (error.message === '(intermediate value).data is undefined') {
        pageHistory.replace('/404');
      }
      showToast('error', 'Something went wrong, please refresh the page.');
    }
    setLoading(false);
    setTokenOwnerLoading(false);
    setHistoryLoading(false);
  };

  const getOwnerInfo = async () => {
    setOwnerInfoLoading(true);
    try {
      const { data } = await getUserAccountDetails(owner);
      setOwnerInfo(data);
    } catch {
      setOwnerInfo(null);
    }
    setOwnerInfoLoading(false);
  };

  const getItemTransferHistory = async () => {
    setHistoryLoading(true);
    try {
      const { data } = await getTransferHistory(
        address,
        tokenID,
        tokenType.current
      );
      transferHistory.current = data.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );
    } catch (e) {
      console.log(e);
    }
    setHistoryLoading(false);
  };

  const getAuctions = async () => {
    try {
      const _auction = await getAuction(address, tokenID);
      if (_auction.endTime !== 0) {
        const token = getTokenByAddress(_auction.payToken);
        const reservePrice = parseFloat(
          ethers.utils.formatUnits(_auction.reservePrice, token.decimals)
        );
        auction.current = { ..._auction, reservePrice, token };
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBid = async () => {
    try {
      const bid = await getHighestBidder(
        address,
        tokenID,
        auction.current.token.address
      );

      if (bid.bid !== 0) {
        setBid(bid);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const eventMatches = (nft, id) => {
    return (
      address?.toLowerCase() === nft?.toLowerCase() &&
      parseFloat(tokenID) === parseFloat(id.toString())
    );
  };

  const itemUpdatedHandler = (owner, paymentToken, newPrice) => {
    const token = getTokenByAddress(paymentToken);
    const newListings = listings.map(listing => {
      if (listing.minter.toLowerCase() === owner.toLowerCase()) {
        listing.token = token;
        listing.price = parseFloat(
          ethers.utils.formatUnits(newPrice, token.decimals)
        );
      }
      return listing;
    });
    setListings(newListings);
  };

  const itemSoldHandler = useCallback(
    async (seller, buyer, _quantity, paymentToken, price) => {
      if (!rerenderNewTradeHistory) return;
      const quantity = parseFloat(_quantity.toString());
      const filteredListings = listings.filter(
        listing => listing.owner.toLowerCase() !== seller.toLowerCase()
      );
      setListings(filteredListings);

      const token = getTokenByAddress(paymentToken);
      const newTradeHistory = {
        from: seller,
        to: buyer,
        price: price,
        value: quantity,
        createdAt: new Date().toISOString(),
        paymentToken,
        token,
      };
      try {
        const from = await getUserAccountDetails(seller);
        newTradeHistory.fromAlias = from?.data?.alias;
        newTradeHistory.fromImage = from?.data?.imageHash;
      } catch (e) {
        console.log(e);
      }
      try {
        const to = await getUserAccountDetails(buyer);
        newTradeHistory.toAlias = to?.data?.alias;
        newTradeHistory.toImage = to?.data?.imageHash;
      } catch (e) {
        console.log(e);
      }
      tradeHistory.current.unshift(newTradeHistory);
    },
    []
  );

  const offerCreatedHandler = async (
    creator,
    quantity,
    payToken,
    pricePerItem,
    deadline
  ) => {
    const token = getTokenByAddress(payToken);
    const newOffer = {
      creator,
      deadline: parseFloat(deadline.toString()) * 1000,
      token,
      pricePerItem: parseFloat(
        ethers.utils.formatUnits(pricePerItem, token.decimals)
      ),
      quantity: parseFloat(quantity.toString()),
    };
    try {
      const { data } = await getUserAccountDetails(creator);
      newOffer.alias = data.alias;
      newOffer.image = data.imageHash;
    } catch (e) {
      console.log(e);
    }
    const existingOfferIndex = offers.current.findIndex(
      offer => offer.creator.toLowerCase() === newOffer.creator.toLowerCase()
    );
    if (existingOfferIndex >= 0) {
      offers.current[existingOfferIndex] = newOffer;
    } else {
      offers.current.push(newOffer);
    }
  };

  const offerCanceledHandler = async (creator, nft, id) => {
    if (eventMatches(nft, id)) {
      const newOffers = offers.current.filter(
        offer => offer.creator?.toLowerCase() !== creator?.toLowerCase()
      );
      offers.current = newOffers;
      const args = [creator, nft, id];
      const blockNumber = blockNumberValue ? blockNumberValue : 0;
      try {
        const data = { args, blockNumber, transactionHash };
        const res = await axios({
          method: 'post',
          url: `marketplace/offerCanceled`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return res.data;
      } catch (err) {
        console.log(err);
        showToast('error', t('messages.somethingWrong'));
      }
    }
  };

  const auctionCreatedHandler = () => {
    getAuctions();
  };

  const auctionEndTimeUpdatedHandler = _endTime => {
    const endTime = parseFloat(_endTime.toString());
    if (auction.current) {
      const newAuction = { ...auction.current, endTime };
      auction.current = newAuction;
    }
  };

  const auctionStartTimeUpdatedHandler = _startTime => {
    const startTime = parseFloat(_startTime.toString());
    if (auction.current) {
      const newAuction = { ...auction.current, startTime };
      auction.current = newAuction;
    }
  };

  const auctionReservePriceUpdatedHandler = (_payToken, _price) => {
    if (auction.current) {
      const price = _price;
      const newAuction = { ...auction.current, reservePrice: price };
      auction.current = newAuction;
    }
  };

  const minBidIncrementUpdatedHandler = _minBidIncrement => {
    if (minBidIncrement !== _minBidIncrement) {
      const minBidIncrement = parseFloat(_minBidIncrement.toString());
      setMinBidIncrement(minBidIncrement);
    }
  };

  const bidPlacedHandler = (bidder, _bid) => {
    const bid = parseFloat(_bid.toString()) / 10 ** 18;
    setBid({
      bidder,
      bid,
      lastBidTime: Math.floor(new Date().getTime() / 1000),
    });
  };

  const bidWithdrawnHandler = () => {
    setBid(null);
  };

  const addEventListeners = async () => {
    const salesContract = await getSalesContract();
    const auctionContract = await getAuctionContract();

    prevSalesContract.current = salesContract;
    prevAuctionContract.current = auctionContract;

    auctionContract.on('UpdateMinBidIncrement', minBidIncrementUpdatedHandler);
  };

  const removeEventListeners = async () => {
    prevSalesContract.current?.removeAllListeners();
    prevAuctionContract.current?.removeAllListeners();
  };

  const getAuctionConfiguration = async () => {
    const contract = await getAuctionContract();

    const _minBidIncrement = await contract.minBidIncrement();
    const minBidIncrement = parseFloat(_minBidIncrement.toString()) / 10 ** 18;
    setMinBidIncrement(minBidIncrement);
  };

  const getCollection = async () => {
    setCollectionLoading(true);
    try {
      const { data } = await fetchCollection(address);
      setCollection(data);
    } catch (err) {
      console.log(err);
    }
    setCollectionLoading(false);
  };

  useEffect(() => {
    if (address && tokenID) {
      addEventListeners();
      getAuctionConfiguration();
    }

    setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      if (address && tokenID) {
        removeEventListeners();
      }
    };
  }, [chainId, holders]);

  useEffect(() => {
    setLiked(null);

    getItemDetails();
    getAuctions().then(() => {
      getBid();
    });
    increaseViewCount(address, tokenID).then(({ data }) => {
      setViews(data);
    });
    isLikingItem(address, tokenID, account).then(({ data }) => {
      setIsLike(data);
    });

    getLikeInfo();
  }, [address, tokenID, bundleID]);

  useEffect(() => {
    if (!chainId || !address) {
      setCollectionRoyalty(null);
      return;
    }

    getCollectionRoyalty(address)
      .then(res => {
        if (res.royalty) {
          setCollectionRoyalty({
            royalty: res.royalty / 100,
            feeRecipient: res.feeRecipient,
          });
        } else {
          setCollectionRoyalty(null);
        }
      })
      .catch(console.log);
  }, [chainId, address]);

  useEffect(() => {
    if (address && tokenID && tokenType.current && filter === 1) {
      getItemTransferHistory();
    }
  }, [address, tokenID, tokenType.current, filter]);

  useEffect(() => {
    getOwnerInfo();
  }, [owner]);

  const updateItems = async () => {
    try {
      if (!authToken) {
        moreItems.current = tokens.map(tk => ({
          ...tk,
          isLiked: false,
        }));
        return;
      }
      let missingTokens = moreItems.current.map((tk, index) =>
        tk.items
          ? {
              index,
              isLiked: tk.isLiked,
              bundleID: tk._id,
            }
          : {
              index,
              isLiked: tk.isLiked,
              contractAddress: tk.contractAddress,
              tokenID: tk.tokenID,
            }
      );
      if (prevAuthToken) {
        missingTokens = missingTokens.filter(tk => tk.isLiked === undefined);
      }

      const cancelTokenSource = axios.CancelToken.source();
      setLikeCancelSource(cancelTokenSource);
      const { data, status } = await getItemsLiked(
        missingTokens,
        authToken,
        cancelTokenSource.token
      );
      if (status === 'success') {
        const newTokens = [...moreItems.current];
        missingTokens.map((tk, idx) => {
          newTokens[tk.index].isLiked = data[idx].isLiked;
        });
        // eslint-disable-next-line require-atomic-updates
        moreItems.current = newTokens;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLikeCancelSource(null);
    }
  };

  useEffect(() => {
    if (likeCancelSource) {
      likeCancelSource.cancel();
    }
    if (moreItems.current.length) {
      updateItems();
    }
  }, [moreItems, authToken]);

  const getLikeInfo = async () => {
    setLikeFetching(true);
    try {
      if (bundleID) {
        const { data } = await isLikingBundle(bundleID, account);
        setIsLike(data);
      } else {
        const { data } = await isLikingItem(address, tokenID, account);
        setIsLike(data);
      }
    } catch (err) {
      console.log(err);
    }
    setLikeFetching(false);
  };

  const toggleFavorite = async e => {
    e.preventDefault();
    if (isLiking) return;

    setIsLiking(true);
    try {
      if (bundleID) {
        const { data } = await likeBundle(bundleID, authToken);
        setLiked(data);
      } else {
        const { data } = await likeItem(address, tokenID, authToken);
        setLiked(data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLike(!isLike);
    setIsLiking(false);
  };

  const showLikeUsers = async () => {
    if (likeUsersFetching) return;

    setLikesModalVisible(true);
    setLikeUsersFetching(true);
    try {
      if (bundleID) {
        const { data } = await getBundleLikeUsers(bundleID);
        likeUsers.current = data;
      } else {
        const { data } = await getItemLikeUsers(address, tokenID);
        likeUsers.current = data;
      }
    } catch (err) {
      console.log(err);
    }
    setLikeUsersFetching(false);
  };

  const getSalesContractStatus = async () => {
    const contract = await getERC721Contract(address);
    try {
      const approved = await contract.isApprovedForAll(
        account,
        Contracts.sales
      );
      setSalesContractApproved(approved);
    } catch (e) {
      console.log(e);
    }
  };

  const getItemApprovalStatus = async () => {
    const contract = await getERC721Contract(address);
    try {
      const approvedAddress = await contract.getApproved(tokenID);
      setItemOnSalesContractApproved(approvedAddress === Contracts.sales);
    } catch (e) {
      console.log(e);
    }
  };

  const getBundleSalesContractStatus = async () => {
    let contractAddresses = bundleItems.current.map(
      item => item.contractAddress
    );
    contractAddresses = contractAddresses.filter(
      (addr, idx) => contractAddresses.indexOf(addr) === idx
    );
    const approved = {};
    await Promise.all(
      contractAddresses.map(async address => {
        const contract = await getERC721Contract(address);
        try {
          const _approved = await contract.isApprovedForAll(
            account,
            Contracts.bundleSales
          );
          approved[address] = _approved;
        } catch (e) {
          console.log(e);
        }
      })
    );
    setBundleSalesContractApproved(approved);
  };

  const getAuctionContractStatus = async () => {
    const contract = await getERC721Contract(address);
    try {
      const approved = await contract.isApprovedForAll(
        account,
        Contracts.auction
      );
      setAuctionContractApproved(approved);
    } catch (e) {
      console.log(e);
    }
  };

  const addNFTContractEventListeners = async () => {
    const contract = await getERC721Contract(address);

    contract.on('ApprovalForAll', (owner, operator, approved) => {
      if (account?.toLowerCase() === owner?.toLowerCase()) {
        if (operator === Contracts.auction) {
          setAuctionContractApproved(approved);
        } else if (operator === Contracts.sales) {
          setSalesContractApproved(approved);
        }
      }
    });
  };

  useEffect(() => {
    if (address && account && chainId) {
      getSalesContractStatus();
      getAuctionContractStatus();
      getItemApprovalStatus();
    }
  }, [address, account, chainId]);

  useEffect(() => {
    if (bundleItems.current && account && chainId) {
      getBundleSalesContractStatus();
    }
  }, [bundleItems.current, account, chainId]);

  useEffect(() => {
    if (address && chainId) {
      addNFTContractEventListeners();
      getCollection();
    }
  }, [address, chainId]);

  const handleApproveSalesContract = async () => {
    setSalesContractApproving(true);
    try {
      const contract = await getERC721Contract(address);
      const tx = await contract.setApprovalForAll(Contracts.sales, true);
      await tx.wait();
      setSalesContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSelectedOffer(null);
      setSalesContractApproving(false);
    }
  };

  const handleApproveItem = async () => {
    setItemOnSalesContractApproving(true);
    try {
      const contract = await getERC721Contract(address);
      const tx = await contract.approve(Contracts.sales, tokenID);
      await tx.wait();
      setItemOnSalesContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSelectedOffer(null);
      setItemOnSalesContractApproving(false);
    }
  };

  const handleApproveBundleSalesContract = async () => {
    if (salesContractApproving) return;
    if (bundleItems.current.length === 0) return;

    setSalesContractApproving(true);
    try {
      let contractAddresses = bundleItems.current.map(
        item => item.contractAddress
      );
      contractAddresses = contractAddresses.filter(
        (addr, idx) => contractAddresses.indexOf(addr) === idx
      );
      const approved = {};
      await Promise.all(
        contractAddresses.map(async address => {
          const contract = await getERC721Contract(address);
          const _approved = await contract.isApprovedForAll(
            account,
            Contracts.bundleSales
          );
          if (!_approved) {
            const tx = await contract.setApprovalForAll(
              Contracts.bundleSales,
              true
            );
            await tx.wait();
          }
          approved[address] = true;
        })
      );
      setBundleSalesContractApproved(approved);
    } catch (e) {
      console.log(e);
    } finally {
      setSalesContractApproving(false);
    }
  };

  const handleApproveAuctionContract = async () => {
    setAuctionContractApproving(true);
    try {
      const contract = await getERC721Contract(address);
      const tx = await contract.setApprovalForAll(Contracts.auction, true);
      await tx.wait();
      setAuctionContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setAuctionContractApproving(false);
    }
  };

  const myHolding = useMemo(
    () =>
      holders.find(
        holder => holder.address.toLowerCase() === account?.toLowerCase()
      ),
    [holders, account]
  );

  const isMine =
    tokenType.current === 721 || bundleID
      ? owner?.toLowerCase() === account?.toLowerCase()
      : !!myHolding;

  const handleTransfer = async to => {
    if (bundleID) return;

    if (!ethers.utils.isAddress(to)) {
      showToast('error', t('errors.invalidAddress'));
      return;
    }

    if (transferring) return;

    setTransferring(true);

    try {
      const contract = await getERC721Contract(address);
      const tx = await contract.transferFrom(account, to, tokenID, {
        gasPrice: parseInt(window.__RUNTIME_CONFIG__.REACT_APP_GAS_PRICE),
      });
      await tx.wait();
      showToast('success', t('messages.transferSuccess'));
      setOwner(to);
      setTransferModalVisible(false);
      getItemDetails();
    } catch {
      showToast('error', t('errors.transferFail'));
    }

    setTransferring(false);
  };

  const handleListItem = async (token, _price, quantity) => {
    if (listingItem) return;

    try {
      setListingItem(true);

      const price = ethers.utils.parseUnits(_price, token.decimals);
      const startingTime = ethers.BigNumber.from(
        Math.floor(new Date().getTime() / 1000)
      );
      // const listings = await getListing(address, ethers.BigNumber.from(tokenID), account);
      const tx = await listItem(
        address,
        ethers.BigNumber.from(tokenID),
        ethers.BigNumber.from(quantity),
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price,
        startingTime
      );
      await tx.wait();
      setListings(prev => [
        ...prev,
        {
          minter: address,
          owner: account,
          paymentToken: token.address,
          price: _price,
          quantity: quantity,
          startTime: new Date(),
          token: token,
          tokenID: tokenID,
        },
      ]);
      showToast('success', t('messages.listSuccess'));

      setSellModalVisible(false);
      setListingItem(false);
    } catch (err) {
      showToast('error', formatError(err?.data?.message || err?.message));
      console.log(err);
      setListingItem(false);
    }
  };

  const isBundleContractApproved = (() => {
    if (bundleItems.current.length === 0) return false;
    let contractAddresses = bundleItems.current.map(
      item => item.contractAddress
    );
    contractAddresses = contractAddresses.filter(
      (addr, idx) => contractAddresses.indexOf(addr) === idx
    );
    const approved = contractAddresses
      .map(addr => bundleSalesContractApproved[addr])
      .reduce((cur, _approved) => cur && _approved, true);
    return approved;
  })();

  const handleRevealContent = async () => {
    if (revealing) return;

    try {
      setRevealing(true);

      const { data: nonce } = await getNonce(account, authToken);
      let signature;
      let addr;
      try {
        const signer = await getSigner();
        const msg = `Validate user action with ID: ${nonce}`;
        signature = await signer.signMessage(msg);
        addr = ethers.utils.verifyMessage(msg, signature);
      } catch {
        showToast('error', t('errors.signFailed'));
        setRevealing(false);
        return;
      }

      const { data } = await retrieveUnlockableContent(
        address,
        tokenID,
        signature,
        addr,
        authToken
      );
      setUnlockableContent(data);
      setRevealing(false);
    } catch {
      setRevealing(false);
    }
  };

  const handleUpdateListing = async (token, _price, quantity) => {
    if (priceUpdating) return;

    try {
      setPriceUpdating(true);

      const price = ethers.utils.parseUnits(_price, token.decimals);

      const tx = await updateListing(
        address,
        tokenID,
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price,
        ethers.BigNumber.from(quantity)
      );
      await tx.wait();

      showToast('success', t('messages.priceUpdated'));
      itemUpdatedHandler(address, token.address, price);
      setPriceUpdating(false);
      setSellModalVisible(false);
    } catch (e) {
      showToast('error', formatError(e.message));
      setPriceUpdating(false);
    }
  };

  const cancelList = async () => {
    if (cancelingListing) return;

    setCancelingListing(true);
    try {
      await cancelListing(address, tokenID);
      const filteredListings = listings.filter(
        listing => listing.owner.toLowerCase() !== account.toLowerCase()
      );
      setListings(filteredListings);
      showToast('success', t('messages.unlistSuccess'));
    } catch (e) {
      showToast('error', formatError(e.message));
      console.log(e);
    }
    setCancelingListing(false);
  };

  const handleBuyItem = async listing => {
    if (buyingItem) return;

    try {
      setBuyingItem(true);
      const _price = listing.price * listing.quantity;

      let formattedPrice;
      if (isScientificNotation(_price)) {
        const numberAfterMinus = getNumberAfterMinus(`${_price}`);
        if (numberAfterMinus !== null) {
          formattedPrice = _price.toFixed(numberAfterMinus);
        }
      } else {
        formattedPrice = _price.toString();
      }
      const price = ethers.utils.parseUnits(
        formattedPrice,
        listing.token.decimals
      );

      let balance;
      let erc20;

      if (listing.token.address === ethers.constants.AddressZero) {
        balance = await WalletUtils.checkBalanceBigNumber(account);
      } else {
        erc20 = await getERC20Contract(listing.token.address);
        balance = await erc20.balanceOf(account);
      }

      if (balance.lt(price)) {
        const toastId = showToast(
          'error',
          t('errors.insufficientBalance', { token: listing.token.symbol }),
          () => {
            toast.dismiss(toastId);
            setBuyingItem(false);
          }
        );
        setBuyingItem(false);
        return;
      }

      if (listing.token.address === ethers.constants.AddressZero) {
        const tx = await buyItemETH(
          address,
          ethers.BigNumber.from(tokenID),
          listing.owner,
          price,
          account
        );
        await tx.wait();
      } else {
        const salesContract = await getSalesContract();
        erc20 = await getERC20Contract(listing?.token?.address);
        const allowance = await erc20.allowance(account, salesContract.address);
        if (allowance.lt(price)) {
          const txw = await erc20.approve(salesContract.address, price);
          await txw.wait();
        }

        const tx = await buyItemERC20(
          address,
          ethers.BigNumber.from(tokenID),
          listing.token.address,
          listing.owner,
          price
        );
        await tx.wait();
      }

      setBuyingItem(false);

      showToast('success', t('messages.boughtItem'));
      itemSoldHandler(
        listing.owner,
        account,
        listing.quantity,
        listing.token.address,
        listing.price
      );

      setOwner(account);

      const filteredListings = listings.filter(
        _listing => _listing.owner.toLowerCase() !== listing.owner.toLowerCase()
      );
      setListings(filteredListings);
    } catch (error) {
      showToast(
        'error',
        formatError(error.data.message ?? error.message ?? 'An error occcureed')
      );
      setBuyingItem(false);
    }
  };
  const handleConnectWallet = () => {
    dispatch(ModalActions.showConnectWalletModal());
  };

  const handleMakeOffer = async (token, _price, quantity, endTime) => {
    if (!account) {
      setOfferModalVisible(false);
      handleConnectWallet();
      return;
    }
    if (offerPlacing) return;
    try {
      setOfferPlacing(true);

      if (token.address === ethers.constants.AddressZero) {
        const toastId = showToast(
          'error',
          t('errors.onlyTokenOperations'),
          () => {
            toast.dismiss(toastId);
            setOfferModalVisible(false);
          }
        );
        setOfferPlacing(false);
        return;
      }

      const price = ethers.utils.parseUnits(_price, token.decimals);
      const deadline = Math.floor(endTime.getTime() / 1000);
      const amount = price.mul(quantity);

      const erc20 = await getERC20Contract(token.address);
      const balance = await erc20.balanceOf(account);

      if (balance.lt(amount)) {
        const toastId = showToast(
          'error',
          t('errors.insufficientBalance', { token: token.symbol }),
          () => {
            toast.dismiss(toastId);
            setOfferModalVisible(false);
          }
        );
        setOfferPlacing(false);
        return;
      }

      const salesContract = await getSalesContract();
      const allowance = await erc20.allowance(account, salesContract.address);
      if (allowance.lt(price)) {
        const txw = await erc20.approve(salesContract.address, price);
        await txw.wait();
      }

      const tx = await createOffer(
        address,
        ethers.BigNumber.from(tokenID),
        token.address,
        ethers.BigNumber.from(quantity),
        price,
        ethers.BigNumber.from(deadline)
      );
      await tx.wait();

      setTransactionHash(tx.hash);
      setBlockNumberValue(tx.blockNumber);

      showToast('success', t('messages.offerPlaced'));
      offerCreatedHandler(account, quantity, token.address, price, deadline);

      setOfferModalVisible(false);
    } catch (e) {
      showToast('error', formatError(e.message));
      console.log(e);
    } finally {
      setOfferPlacing(false);
    }
  };
  
  const handleAcceptOffer = async offer => {
    if (offerAccepting) return;

    try {
      setOfferAccepting(true);
      const tx = await acceptOffer(address, tokenID, offer.creator);
      await tx.wait();

      setOfferAccepting(false);
      setSelectedOffer(null)

      showToast('success', t('messages.offerAccepted'));

      setOwner(offer.creator);

      offers.current = offers.current.filter(
        _offer => _offer.creator !== offer.creator
      );
      itemSoldHandler(
        owner,
        offer.creator,
        offer.quantity,
        offer.token.address,
        offer.pricePerItem
      );
    } catch (error) {
      showToast('error', formatError(error.message));
      setOfferAccepting(false);
      setSelectedOffer(null);
    }
  };

  const handleCancelOffer = async () => {
    if (offerCanceling) return;

    try {
      setOfferCanceling(true);

      const tx = await cancelOffer(address, tokenID);
      await tx.wait();

      showToast('success', t('messages.offerWithdrawn'));

      offerCanceledHandler(account, address, ethers.BigNumber.from(tokenID));

      setOfferCanceling(false);
    } catch (error) {
      showToast(
        'error',
        formatError(error.data?.message ?? error.message ?? 'An error occured')
      );
      setOfferCanceling(false);
    }
  };

  const handleStartAuction = async (
    token,
    _price,
    _startTime,
    _endTime,
    minBidReserve
  ) => {
    try {
      setAuctionStarting(true);

      const price = ethers.utils.parseUnits(_price, token.decimals);
      const startTime = Math.floor(_startTime.getTime() / 1000);
      const endTime = Math.floor(_endTime.getTime() / 1000);

      const tx = await createAuction(
        address,
        ethers.BigNumber.from(tokenID),
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price,
        ethers.BigNumber.from(startTime),
        ethers.BigNumber.from(endTime),
        minBidReserve
      );
      await tx.wait();

      showToast('success', t('messages.auctionStarted'));
      auctionCreatedHandler();
      setAuctionStarting(false);
      setAuctionModalVisible(false);
    } catch (error) {
      showToast('error', formatError(error?.data?.message || error?.message));
      setAuctionStarting(false);
    }
  };

  const handleUpdateAuction = async (token, _price, _startTime, _endTime) => {
    if (!auction.current) return;

    try {
      setAuctionUpdating(true);

      if (parseFloat(_price) !== auction.current.reservePrice) {
        const price = ethers.utils.parseUnits(_price, token.decimals);
        await updateAuctionReservePrice(
          address,
          ethers.BigNumber.from(tokenID),
          ethers.BigNumber.from(price)
        );
        auctionReservePriceUpdatedHandler(token.address, parseFloat(_price));
        showToast('success', t('messages.auctionReserveUpdate'));
      }

      const startTime = Math.floor(_startTime.getTime() / 1000);
      if (startTime !== auction.current.startTime) {
        await updateAuctionStartTime(
          address,
          ethers.BigNumber.from(tokenID),
          ethers.BigNumber.from(startTime)
        );
        auctionStartTimeUpdatedHandler(startTime);
        showToast('success', t('messages.auctionStartUpdate'));
      }

      const endTime = Math.floor(_endTime.getTime() / 1000);
      if (endTime !== auction.current.endTime) {
        await updateAuctionEndTime(
          address,
          ethers.BigNumber.from(tokenID),
          ethers.BigNumber.from(endTime)
        );
        auctionEndTimeUpdatedHandler(endTime);
        showToast('success', t('messages.auctionEndUpdate'));
      }

      setAuctionUpdating(false);
      setAuctionModalVisible(false);
    } catch (error) {
      showToast('error', formatError(error.message));
      setAuctionUpdating(false);
    }
  };

  const cancelCurrentAuction = async () => {
    if (auctionCanceling) return;

    try {
      setAuctionCanceling(true);
      await cancelAuction(address, tokenID);
      auction.current = null;
      setBid(null);
      showToast('success', t('messages.auctionCancel'));
    } catch (err) {
      showToast('error', formatError(err.message));
      console.log(err);
    } finally {
      setAuctionCanceling(false);
    }
  };

  const handleResultAuction = async () => {
    if (bid === null) {
      showToast('info', t('generic.noBids'));
      return;
    }

    if (resulting) return;

    // console.log({auction, now: now.getTime()});

    try {
      setResulting(true);
      // const { token } = auction.current;
      await resultAuction(address, tokenID);
      setResulting(false);
      setResulted(true);
      itemSoldHandler(
        auction.current.owner,
        bid.bidder,
        1,
        auction.current.payToken,
        bid.bid
      );
      auction.current = null;
      showToast('success', t('messages.auctionResulted'));
      setOwner(bid.bidder);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error.message));
      setResulting(false);
    }
  };

  const handlePlaceBid = async _price => {
    if (bidPlacing) return;

    try {
      setBidPlacing(true);

      const { token } = auction.current;
      const price = ethers.utils.parseUnits(_price, token.decimals);
      if (token.address !== ethers.constants.AddressZero) {
        const erc20 = await getERC20Contract(token.address);

        const balance = await erc20.balanceOf(account);
        if (balance.lt(price)) {
          const toastId = showToast(
            'error',
            t('errors.insufficientBalance', { token: token.symbol }),
            () => {
              toast.dismiss(toastId);
              setBidModalVisible(false);
            }
          );
          setBidPlacing(false);
          return;
        }
        const auctionContract = await getAuctionContract();
        const allowance = await erc20.allowance(
          account,
          auctionContract.address
        );
        if (allowance.lt(price)) {
          const tx = await erc20.approve(auctionContract.address, price);
          await tx.wait();
        }
      }
      const tx = await placeBid(
        address,
        ethers.BigNumber.from(tokenID),
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price,
        account
      );
      await tx.wait();
      showToast('success', t('messages.bidPlaced'));
      bidPlacedHandler(account, price);
      setBidPlacing(false);
      setBidModalVisible(false);
    } catch (error) {
      if (error.message.startsWith('MetaMask Tx Signature:')) {
        showToast('error', error.message);
        setBidPlacing(false);
        return;
      }

      if (
        error.message.startsWith(
          'transaction failed [ See: https://links.ethers.org/v5-errors-CALL_EXCEPTION ]'
        ) ||
        error.data.message === 'execution reverted: 9'
      ) {
        showToast('error', 'Highest bid has changed, please refresh the page.');
        setBidPlacing(false);
        return;
      }
      showToast('error', formatError(error.message));
      setBidPlacing(false);
    }
  };

  const handleWithdrawBid = async () => {
    if (bidWithdrawing) return;
    try {
      setBidWithdrawing(true);
      await withdrawBid(address, ethers.BigNumber.from(tokenID));
      bidWithdrawnHandler();
      setBidWithdrawing(false);
      showToast('success', t('messages.bidWithdrawn'));
    } catch (error) {
      showToast(
        'error',
        formatError(
          error.data.message ?? error.message ?? 'Something went wrong'
        )
      );
      setBidWithdrawing(false);
    }
  };

  const hasMyOffer = (() =>
    offers.current.findIndex(
      offer =>
        offer.creator?.toLowerCase() === account?.toLowerCase() &&
        offer.deadline >= now.getTime()
    ) > -1)();

  const data = useMemo(() => {
    return [...tradeHistory.current].reverse().map(history => {
      const saleDate = new Date(history.createdAt);
      return {
        date: `${saleDate.getFullYear()}/${saleDate.getMonth() +
          1}/${saleDate.getDate()}`,
        price: history.price,
        amt: 2100,
      };
    });
  }, [rerenderNewTradeHistory]);

  const formatDiff = diff => {
    if (diff >= ONE_MONTH) {
      const m = Math.ceil(diff / ONE_MONTH);
      return `${m} ${m > 1 ? t('units.months') : t('units.month')}`;
    }
    if (diff >= ONE_DAY) {
      const d = Math.ceil(diff / ONE_DAY);
      return `${d} ${d > 1 ? t('units.days') : t('units.day')}`;
    }
    if (diff >= ONE_HOUR) {
      const h = Math.ceil(diff / ONE_HOUR);
      return `${h} ${h > 1 ? t('units.hours') : t('units.hour')}`;
    }
    if (diff >= ONE_MIN) {
      const mm = Math.ceil(diff / ONE_MIN);
      return `${mm} ${mm > 1 ? t('units.minutes') : t('units.minute')}`;
    }
    return `${diff} ${diff > 1 ? t('units.seconds') : t('units.second')}`;
  };

  const formatExpiration = deadline => {
    if (deadline < now.getTime()) return t('errors.expired');

    let diff = new Date(deadline).getTime() - now.getTime();
    diff = Math.floor(diff / 1000);
    return formatDiff(diff);
  };

  const formatDuration = endTime => {
    const diff = endTime - Math.floor(now.getTime() / 1000);
    return formatDiff(diff);
  };

  const formatDate = _date => {
    const date = new Date(_date);
    const diff = Math.floor((now - date.getTime()) / 1000);
    return `${formatDiff(diff)} ${t('generic.timeAgo')}`;
  };

  const auctionStarted = now.getTime() / 1000 >= auction.current?.startTime;

  const auctionEnded =
    auction.current?.endTime <= now.getTime() / 1000 || resulted;

  const auctionActive = () => auctionStarted && !auctionEnded;

  const myListing = () => {
    let item;
    if (listings.length) {
      item = listings.find(
        listing => listing.owner.toLowerCase() === account?.toLowerCase()
      );
    } else {
      item = undefined;
    }
    return item;
  };

  const hasListing = (() => {
    return bundleListing.current || myListing() !== undefined;
  })();

  const bestListing = (() => {
    let idx = 0;
    while (
      idx < listings.length &&
      listings[idx].owner.toLowerCase() === account?.toLowerCase()
    ) {
      idx++;
    }
    if (idx < listings.length) return listings[idx];
    return null;
  })();

  const maxSupply = useCallback(() => {
    let supply = 0;
    holders.map(holder => {
      if (
        holder.address.toLowerCase() !== account?.toLowerCase() &&
        holder.supply > supply
      ) {
        supply = holder.supply;
      }
    });
    return supply;
  }, [holders]);

  const onTransferClick = async () => {
    if (auction.current?.resulted === false) {
      showToast('warning', t('errors.cancelAuction'));
      return;
    }
    if (hasListing) {
      showToast('warning', t('errors.cancelListing'));
      return;
    }
    setTransferModalVisible(true);
  };

  const handleMenuOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setShareAnchorEl(null);
  };

  const handleCopyLink = () => {
    handleClose();
    showToast('success', t('messages.linkCopied'));
  };

  const handleShareOnFacebook = () => {
    handleClose();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      '_blank'
    );
  };

  const handleShareToTwitter = () => {
    handleClose();
    window.open(
      `https://twitter.com/intent/tweet?text=Check%20out%20this%20item%20on%20Kiwii&url=${window.location.href}`,
      '_blank'
    );
  };

  const handleSelectFilter = _filter => {
    setFilter(_filter);
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        paper: styles.filtermenu,
        list: styles.menuList,
      }}
    >
      <div className={styles.menuTitle}>Filter By</div>
      {filters.map((_filter, idx) => (
        <div
          key={idx}
          className={cx(styles.menu, filter === idx && styles.active)}
          onClick={() => handleSelectFilter(idx)}
        >
          {_filter}
          <img src={checkIcon} />
        </div>
      ))}
    </Menu>
  );

  const renderMedia = (image, contentType) => {
    if (contentType === 'video' || image?.includes('youtube')) {
      return (
        <ReactPlayer
          className={styles.content}
          url={image}
          controls={true}
          width="100%"
          height="100%"
        />
      );
    } else if (contentType === 'embed') {
      return <iframe className={styles.content} src={image} />;
    } else if (contentType === 'image' || contentType === 'gif') {
      return (
        <Suspense
          fallback={
            <Loader
              type="Oval"
              color="#007BFF"
              height={32}
              width={32}
              className={styles.loader}
            />
          }
        >
          <SuspenseImg className={styles.content} src={image} />
        </Suspense>
      );
    } else if (!contentType) {
      // temporary solution to display image, not tested
      // ! CHECK THIS WILL WORK
      return (
        <Suspense
          fallback={
            <Loader
              type="Oval"
              color="#007BFF"
              height={32}
              width={32}
              className={styles.loader}
            />
          }
        >
          <SuspenseImg className={styles.content} src={image} />
        </Suspense>
      );
    }
  };

  const renderItemInfo = () => (
    <>
      <div className={styles.itemMenu}>
        {isMine && !bundleID && (
          <div className={styles.itemMenuBtn} onClick={onTransferClick}>
            <RedeemIcon src={shareIcon} className={styles.itemMenuIcon} />
          </div>
        )}
        <div
          className={styles.itemMenuBtn}
          onClick={e => setShareAnchorEl(e.currentTarget)}
        >
          <img src={shareIcon} className={styles.itemMenuIcon} />
        </div>
      </div>
      <Link to={`/explore/${collection?.erc721Address}`}>
        <div className={styles.itemCategory}>
          {collection?.collectionName || collection?.name || ''}
        </div>
      </Link>
      <div className={styles.itemName}>{info?.name || ''}</div>
      {info?.description && (
        <div className={styles.itemDescription}>{info.description}</div>
      )}
      <div className={styles.itemStats}>
        {(ownerInfoLoading || tokenOwnerLoading || owner || tokenInfo) && (
          <RenderItemOwner
            tokenType={tokenType}
            isMine={isMine}
            bundleID={bundleID}
            ownerInfoLoading={ownerInfoLoading}
            tokenOwnerLoading={tokenOwnerLoading}
            owner={owner}
            tokenInfo={tokenInfo}
            ownerInfo={ownerInfo}
            setOwnersModalVisible={setOwnersModalVisible}
            holders={holders}
          />
        )}
        <RenderStats
          views={views}
          liked={liked}
          isLike={isLike}
          likeFetching={likeFetching}
          showLikeUsers={showLikeUsers}
          toggleFavorite={e => toggleFavorite(e)}
        />
      </div>
      {hasUnlockable && (
        <div className={styles.bestBuy}>
          <div className={styles.unlockableLabel}>{`${t(
            'messages.hasUnlockableContent'
          )}${!isMine ? ` ${t('messages.unlockableOnlyOwner')}` : ''}`}</div>
          {isMine ? (
            unlockableContent ? (
              <textarea
                className={styles.unlockableContent}
                value={unlockableContent}
                readOnly
              />
            ) : (
              <div
                className={cx(styles.revealBtn, revealing && styles.disabled)}
                onClick={handleRevealContent}
              >
                {revealing ? (
                  <ClipLoader color="#FFF" size={16} />
                ) : (
                  t('buttons.revealContent')
                )}
              </div>
            )
          ) : null}
        </div>
      )}

      {bestListing && (
        <div className={styles.bestBuy}>
          <div className={styles.currentPriceLabel}>
            {t('messages.currentPrice')}
          </div>
          <div className={styles.currentPriceWrapper}>
            <div className={styles.tokenLogo}>
              <img src={bestListing.token?.icon} />
            </div>
            <div className={styles.currentPrice}>
              {isScientificNotation(bestListing.price)
                ? bestListing.price.toFixed(
                    getNumberAfterMinus(`${bestListing.price}`)
                  )
                : bestListing.price}
            </div>
            <div className={styles.currentPriceUSD}>
              (${(bestListing.price * priceData).toFixed(2)})
            </div>
          </div>
          {bestListing.owner.toLocaleLowerCase() !==
            account?.toLocaleLowerCase() && (
            <TxButton
              className={cx(styles.buyNow, buyingItem && styles.disabled)}
              onClick={() => handleBuyItem(bestListing)}
            >
              {buyingItem ? (
                <ClipLoader color="#FFF" size={16} />
              ) : (
                t('buttons.buyNow')
              )}
            </TxButton>
          )}
        </div>
      )}
    </>
  );

  const renderBundleItem = (item, idx) => {
    if (!item) {
      return (
        <div className={styles.bundleItem} key={idx}>
          <div className={styles.bundleItemImage}>
            <Skeleton width={60} height={60} />
          </div>
          <div className={styles.bundleItemInfo}>
            <div>
              <Skeleton width={180} height={22} />
            </div>
            <div>
              <Skeleton width={180} height={22} />
            </div>
          </div>
          <div className={styles.bundleItemSupply}>
            <Skeleton width={80} height={20} />
          </div>
        </div>
      );
    }

    return (
      <Link
        to={`/explore/${item.contractAddress}/${item.tokenID}`}
        className={styles.bundleItem}
        key={idx}
      >
        <div className={styles.bundleItemImage}>
          <Suspense
            fallback={
              <Loader type="Oval" color="#007BFF" height={32} width={32} />
            }
          >
            <SuspenseImg src={item.metadata.image} />
          </Suspense>
        </div>
        <div className={styles.bundleItemInfo}>
          <div className={styles.bundleItemCategory}>
            {collection?.collectionName || collection?.name}
          </div>
          <div className={styles.bundleItemName}>{item.name}</div>
        </div>
        <div className={styles.bundleItemSupply}>x{item.supply}</div>
      </Link>
    );
  };

  const renderRoyaltyPanel = () =>
    collectionRoyalty && (
      <Panel title={t('generic.royalty')} icon={LoyaltyIcon}>
        <div className={styles.panelBody}>
          <div className={styles.panelLine}>
            <div className={styles.panelLabel}>{t('generic.royalty')}</div>
            <div className={styles.panelValue}>
              {collectionRoyalty.royalty}%
            </div>
          </div>
          <div className={styles.panelLine}>
            <div className={styles.panelLabel}>{t('generic.feeRecipient')}</div>
            <div className={styles.panelValue}>
              {collectionRoyalty.feeRecipient}
            </div>
          </div>
        </div>
      </Panel>
    );

  return (
    <ErrorBoundary>
      <div
        className={styles.container}
        style={
          !isBannerShown ? { paddingTop: '100px' } : { paddingTop: '135px' }
        }
      >
        <Header border />
        {isLoggedIn() && (
          <div className={styles.header}>
            {isMine && (
              <>
                {auction.current?.resulted === false ? (
                  <div
                    className={cx(
                      styles.headerButton,
                      (auctionCanceling || bid?.bid) && styles.disabled
                    )}
                    onClick={bid?.bid ? null : cancelCurrentAuction}
                    data-testid="cancel-auction-button"
                  >
                    {t('buttons.cancelAuction')}
                  </div>
                ) : null}
                {!bundleID &&
                  (!auction.current || !auction.current.resulted) &&
                  !hasListing &&
                  tokenType.current !== 1155 && (
                    <div
                      className={cx(
                        styles.headerButton,
                        (auctionStarting || auctionUpdating || auctionEnded || bid?.bid) &&
                          styles.disabled
                      )}
                      onClick={() => {
                        (!auctionEnded && !bid?.bid) && setAuctionModalVisible(true);
                      }}
                      data-testid="start-auction-button"
                    >
                      {auction.current
                        ? t('buttons.updateAuction')
                        : t('buttons.startAuction')}
                    </div>
                  )}
                {(!auction.current || auction.current.resulted) && (
                  <>
                    {hasListing ? (
                      <div
                        className={cx(
                          styles.headerButton,
                          cancelingListing && styles.disabled
                        )}
                        data-testid="cancel-listing-button"
                        onClick={cancelList}
                      >
                        {t('buttons.cancelListing')}
                      </div>
                    ) : null}
                    <div
                      className={cx(
                        styles.headerButton,
                        (listingItem || priceUpdating) && styles.disabled
                      )}
                      onClick={() =>
                        !(listingItem || priceUpdating)
                          ? setSellModalVisible(true)
                          : null
                      }
                      data-testid="new-listing-button"
                    >
                      {hasListing
                        ? t('buttons.updateListing')
                        : t('buttons.sell')}
                    </div>
                  </>
                )}
              </>
            )}
            {(!isMine ||
              (tokenType.current === 1155 &&
                myHolding.supply < tokenInfo.totalSupply)) &&
              (!auction.current || auction.current.resulted) && (
                <MainButton
                  small
                  className={cx(
                    styles.headerButton,
                    (offerPlacing || offerCanceling) && styles.disabled
                  )}
                  onClick={
                    hasMyOffer
                      ? handleCancelOffer
                      : () => setOfferModalVisible(true)
                  }
                  data-testid="offer-button"
                >
                  {hasMyOffer
                    ? t('buttons.withdrawOffer')
                    : t('buttons.makeOffer')}
                </MainButton>
              )}
          </div>
        )}
        <div className={styles.inner}>
          <div className={styles.topContainer}>
            <div className={styles.itemSummary}>
              <div className={styles.itemMedia}>
                <div className={styles.media}>
                  {loading ? (
                    <Loader
                      type="Oval"
                      color="#007BFF"
                      height={32}
                      width={32}
                      className={styles.loader}
                    />
                  ) : !bundleID || bundleItems.current.length ? (
                    bundleID ? (
                      renderMedia(
                        bundleItems.current[previewIndex].metadata?.image,
                        bundleItems.current[previewIndex].contentType
                      )
                    ) : (
                      renderMedia(info?.image, contentType.current)
                    )
                  ) : null}
                </div>
                {bundleID && (
                  <div className={styles.previewList}>
                    {(loading ? [null, null, null] : bundleItems.current).map(
                      (item, idx) => (
                        <div
                          key={idx}
                          className={cx(
                            styles.preview,
                            !loading && idx === previewIndex && styles.active
                          )}
                          onClick={() => setPreviewIndex(idx)}
                        >
                          {item ? (
                            <Suspense
                              fallback={
                                <Loader
                                  type="Oval"
                                  color="#007BFF"
                                  height={32}
                                  width={32}
                                  className={styles.loader}
                                />
                              }
                            >
                              <SuspenseImg src={item.metadata?.image} />
                            </Suspense>
                          ) : (
                            <Skeleton width={72} height={72} />
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className={styles.itemInfo}>{renderItemInfo()}</div>
              <div className={styles.itemInfoCont}>
                {info?.properties && (
                  <Panel title={t('generic.properties')} icon={LabelIcon}>
                    <div className={styles.panelBody}>
                      <RenderProperties
                        properties={info.properties}
                        explorerUrl={explorerUrl}
                      />
                    </div>
                  </Panel>
                )}
                {!bundleID && (
                  <RenderAboutPanel
                    collectionLoading={collectionLoading}
                    collection={collection}
                  />
                )}
                {!bundleID && (
                  <RenderCollectionPanel
                    explorerUrl={explorerUrl}
                    address={address}
                  />
                )}
                {!bundleID && renderRoyaltyPanel()}
              </div>
            </div>
            <div className={styles.itemMain}>
              <div className={styles.itemInfoWrapper}>{renderItemInfo()}</div>
              {info?.properties && (
                <div className={cx(styles.panelWrapper, styles.infoPanel)}>
                  <Panel title={t('generic.properties')}>
                    <div className={styles.panelBody}>
                      <RenderProperties
                        properties={info.properties}
                        explorerUrl={explorerUrl}
                      />
                    </div>
                  </Panel>
                </div>
              )}
              {!bundleID && (
                <div className={cx(styles.panelWrapper, styles.infoPanel)}>
                  <RenderAboutPanel
                    collectionLoading={false}
                    collection={collection}
                  />
                </div>
              )}
              {!bundleID && (
                <div className={cx(styles.panelWrapper, styles.infoPanel)}>
                  <RenderCollectionPanel
                    explorerUrl={explorerUrl}
                    address={address}
                  />
                </div>
              )}
              {!bundleID && (
                <div className={cx(styles.panelWrapper, styles.infoPanel)}>
                  {renderRoyaltyPanel()}
                </div>
              )}
              {auction.current?.resulted === false && (
                <div className={styles.panelWrapper}>
                  <Panel
                    title={
                      auctionStarted
                        ? auctionEnded
                          ? t('generic.saleEnded')
                          : `${t('generic.saleEnds')} ${formatDuration(
                              auction.current.endTime
                            )} (${new Date(
                              auction.current.endTime * 1000
                            ).toLocaleString()})`
                        : `${t('generic.saleStarts')} ${formatDuration(
                            auction.current.startTime
                          )}`
                    }
                    fixed
                  >
                    <div className={styles.bids}>
                      {bid ? (
                        <div>
                          <div className={styles.bidtitle}>
                            {t('auctionModal.reservePrice')} :&nbsp;
                            <img
                              src={auction.current.token?.icon}
                              className={styles.tokenIcon}
                            />
                            {formatNumber(auction.current.reservePrice)}
                          </div>
                          <br />
                          <div className={styles.bidtitle}>
                            {t('auctionModal.highestBid')} :&nbsp;
                            <img
                              src={auction.current.token?.icon}
                              className={styles.tokenIcon}
                            />
                            {formatNumber(bid.bid)}
                            {bid.bid < auction.current.reservePrice
                              ? ` -- ${t('auctionModal.reserveNotMet')}`
                              : ''}
                          </div>
                        </div>
                      ) : (
                        <div className={styles.bidtitle}>
                          {`${t('auctionModal.noBids')} ( ${t(
                            'auctionModal.reservePrice'
                          )}:`}
                          &nbsp;
                          <img
                            src={auction.current.token?.icon}
                            className={styles.tokenIcon}
                          />
                          {formatNumber(auction.current.reservePrice)} )
                        </div>
                      )}
                      {!isMine &&
                        (!auctionActive() &&
                        bid?.bidder?.toLowerCase() === account?.toLowerCase()
                          ? now.getTime() / 1000 >=
                              auction?.current?.endTime && (
                              <div
                                className={cx(
                                  styles.withdrawBid,
                                  (bidWithdrawing ||
                                    now.getTime() / 1000 <=
                                      auction?.current?.endTime +
                                        withdrawBidDuration) &&
                                    styles.disabled
                                )}
                                onClick={() => {
                                  if (
                                    now.getTime() / 1000 <=
                                    auction?.current?.endTime +
                                      withdrawBidDuration
                                  ) {
                                    showToast(
                                      'error',
                                      'Withdraw bid 12hours after auction timer elapsed'
                                    );
                                    return;
                                  } else {
                                    handleWithdrawBid();
                                  }
                                }}
                              >
                                {bidWithdrawing
                                  ? t('buttons.withdrawingBid')
                                  : t('buttons.withdrawBid')}
                              </div>
                            )
                          : // )
                            !isMine &&
                            bid?.bidder?.toLowerCase() !==
                              account?.toLowerCase() &&
                            auctionActive() && (
                              <div
                                className={cx(
                                  styles.placeBid,
                                  bidPlacing && styles.disabled
                                )}
                                onClick={() => setBidModalVisible(true)}
                                data-testid="place-bid-button"
                              >
                                {t('buttons.placeBid')}
                              </div>
                            ))}
                      {isMine &&
                        auctionEnded &&
                        !auction.current.resulted &&
                        bid?.bid && (
                          <div
                            className={cx(
                              styles.placeBid,
                              resulting && styles.disabled
                            )}
                            onClick={handleResultAuction}
                          >
                            {t('buttons.acceptBid')}
                          </div>
                        )}
                    </div>
                  </Panel>
                </div>
              )}
              {!bundleID && !!data.length && (
                <div className={styles.panelWrapper}>
                  <Panel title={t('generic.priceHistory')} icon={TimelineIcon}>
                    <ReactResizeDetector>
                      {({ width }) =>
                        width > 0 ? (
                          <div className={styles.chartWrapper}>
                            <div className={styles.chart}>
                              <LineChart
                                width={width}
                                height={250}
                                data={data}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <XAxis dataKey="date" />
                                <YAxis />
                                <ChartTooltip
                                  formatter={value => `${value} ETH`}
                                />
                                <CartesianGrid stroke="#eee" />
                                <Line
                                  type="monotone"
                                  dataKey="price"
                                  stroke="#2479FA"
                                />
                              </LineChart>
                            </div>
                          </div>
                        ) : (
                          <div>{width}</div>
                        )
                      }
                    </ReactResizeDetector>
                  </Panel>
                </div>
              )}
              <div className={styles.panelWrapper}>
                <Panel title="Attributes" icon={DvrOutlined} expanded>
                  <div className={styles.listings}>
                    <div className={cx(styles.listing, styles.heading)}>
                      <div className={styles.subTitle}>Type</div>
                      <div className={styles.subTitle}>Value</div>
                      <div className={styles.subTitle}>Frequency</div>
                    </div>
                    {attritbutes?.map((attribute, idx) => (
                      <div
                        data-testid={`listing-${idx}`}
                        className={styles.listing}
                        key={idx}
                      >
                        <div className={styles.owner}>
                          {attribute?.trait_type}
                        </div>
                        <div
                          data-testid={`listing-price-${idx}`}
                          className={styles.price}
                        >
                          {attribute?.value}
                        </div>
                        <div
                          data-testid={`listing-price-${idx}`}
                          className={styles.price}
                        >
                          {attribute?.rarity}
                        </div>
                      </div>
                    ))}
                    {!attritbutes && (
                      <div className={styles.listing}>
                        <div className={styles.owner}>N/A</div>
                        <div className={styles.price}>N/A</div>
                        <div className={styles.price}>N/A</div>
                      </div>
                    )}
                  </div>
                </Panel>
              </div>
              <div className={styles.panelWrapper}>
                <Panel
                  title={t('generic.listings')}
                  icon={LocalOfferIcon}
                  expanded
                >
                  <div className={styles.listings}>
                    <div className={cx(styles.listing, styles.heading)}>
                      <div className={styles.owner}>{t('generic.from')}</div>
                      <div className={styles.price}>{t('generic.price')}</div>
                      {tokenInfo?.totalSupply > 1 && (
                        <div className={styles.quantity}>
                          {t('generic.quantity')}
                        </div>
                      )}
                      <div className={styles.buy} />
                    </div>
                    {listings.map((listing, idx) => (
                      <div
                        data-testid={`listing-${idx}`}
                        className={styles.listing}
                        key={idx}
                      >
                        <div className={styles.owner}>
                          <Link
                            data-testid={`listing-link-${idx}`}
                            to={`/account/${listing.owner}`}
                          >
                            <div className={styles.userAvatarWrapper}>
                              {listing.image ? (
                                <img
                                  src={`${infuraUrl}/${listing.image}`}
                                  className={styles.userAvatar}
                                />
                              ) : (
                                <Identicon
                                  account={listing.owner}
                                  size={24}
                                  className={styles.userAvatar}
                                />
                              )}
                            </div>
                            {listing.alias || listing.owner?.substr(0, 6)}
                          </Link>
                        </div>
                        <div
                          data-testid={`listing-price-${idx}`}
                          className={styles.price}
                        >
                          <img
                            src={listing.token?.icon}
                            className={styles.tokenIcon}
                          />
                          {formatNumber(listing.price)}&nbsp;(
                          {priceData !== 0 ? (
                            `$${(listing.price * priceData).toFixed(2)}`
                          ) : (
                            <Skeleton width={60} height={24} />
                          )}
                          )
                        </div>
                        {tokenInfo?.totalSupply > 1 && (
                          <div className={styles.quantity}>
                            {formatNumber(listing.quantity)}
                          </div>
                        )}
                        <div className={styles.buy}>
                          {listing.owner.toLowerCase() !==
                            account?.toLowerCase() && (
                            <TxButton
                              className={cx(
                                styles.buyButton,
                                buyingItem && styles.disabled
                              )}
                              onClick={() => handleBuyItem(listing)}
                            >
                              {buyingItem ? (
                                <ClipLoader color="#FFF" size={16} />
                              ) : (
                                t('generic.buy')
                              )}
                            </TxButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Panel>
              </div>
              <div className={styles.panelWrapper}>
                <Panel
                  title={t('generic.directOffers')}
                  icon={TocIcon}
                  expanded
                >
                  <div className={styles.offers}>
                    {offers.current.length ? (
                      <>
                        <div className={cx(styles.offer, styles.heading)}>
                          <div className={styles.owner}>
                            {t('generic.from')}
                          </div>
                          <div className={styles.price}>
                            {t('generic.price')}
                          </div>
                          {tokenInfo?.totalSupply > 1 && (
                            <div className={styles.quantity}>
                              {t('generic.quantity')}
                            </div>
                          )}
                          <div className={styles.deadline}>
                            {t('generic.expiresIn')}
                          </div>
                          <div className={styles.buy} />
                        </div>
                        {offers.current
                          .filter(
                            offer =>
                              offer.deadline > now.getTime() ||
                              offer.creator.toLowerCase() ===
                                account?.toLowerCase()
                          )
                          .sort((a, b) =>
                            a.pricePerItem < b.pricePerItem ? 1 : -1
                          )
                          .map((offer, idx) => (
                            <div
                              data-testid={`offer-${idx}`}
                              className={styles.offer}
                              key={idx}
                            >
                              <div className={styles.owner}>
                                <Link
                                  data-testid={`offer-owner-link-${idx}`}
                                  to={`/account/${offer.creator}`}
                                >
                                  <div className={styles.userAvatarWrapper}>
                                    {offer.image ? (
                                      <img
                                        src={`${infuraUrl}/${offer.image}`}
                                        className={styles.userAvatar}
                                      />
                                    ) : (
                                      <Identicon
                                        account={offer.creator}
                                        size={24}
                                        className={styles.userAvatar}
                                      />
                                    )}
                                  </div>
                                  {offer.alias || offer.creator?.substr(0, 6)}
                                </Link>
                              </div>
                              <div
                                data-testid={`offer-price-${idx}`}
                                className={styles.price}
                              >
                                <img
                                  src={offer.token?.icon}
                                  className={styles.tokenIcon}
                                />
                                {formatNumber(
                                  offer.pricePerItem || offer.price
                                )}
                                &nbsp;(
                                {prices[offer.token.address] !== undefined ? (
                                  `$${(
                                    formatNumber(offer.pricePerItem) * priceData
                                  ).toFixed(2)}`
                                ) : (
                                  <Skeleton width={60} height={24} />
                                )}
                                )
                              </div>
                              {tokenInfo?.totalSupply > 1 && (
                                <div className={styles.quantity}>
                                  {formatNumber(offer.quantity)}
                                </div>
                              )}
                              <div
                                data-testid={`offer-deadline-${idx}`}
                                className={styles.deadline}
                              >
                                {formatExpiration(offer.deadline)}
                              </div>
                              <div className={styles.buy}>
                                {(isMine ||
                                  (myHolding &&
                                    myHolding.supply >= offer.quantity)) &&
                                  offer.creator?.toLowerCase() !==
                                    account?.toLowerCase() && (
                                    <div
                                      className={cx(
                                        styles.buyButton,
                                        (itemOnSalesContractApproving ||
                                          offerAccepting) &&
                                          styles.disabled
                                      )}
                                      onClick={
                                        bundleID
                                          ? isBundleContractApproved
                                            ? () => handleAcceptOffer(offer)
                                            : handleApproveBundleSalesContract
                                          : itemOnSalesContractApproved
                                          ? !offerAccepting ? () => {
                                            setSelectedOffer(offer)
                                            handleAcceptOffer(offer)
                                          } : null
                                          : () => {
                                            setSelectedOffer(offer)
                                            handleApproveItem()
                                          }
                                      
                                      }
                                    >
                                      {!(bundleID
                                        ? isBundleContractApproved
                                        : itemOnSalesContractApproved) ? (
                                        (itemOnSalesContractApproving && (offer?.creator === selectedOffer?.creator)) ? (
                                          <ClipLoader color="#FFF" size={16} />
                                        ) : (
                                          t('buttons.approve')
                                        )
                                      ) : (offerAccepting && (offer?.creator === selectedOffer?.creator)) ? 
                                      (
                                        <ClipLoader color="#FFF" size={16} />
                                      ) : (
                                        t('buttons.accept')
                                      )}
                                    </div>
                                  )}
                                {offer.creator?.toLowerCase() ===
                                  account?.toLowerCase() && (
                                  <div
                                    className={cx(
                                      styles.buyButton,
                                      offerCanceling && styles.disabled
                                    )}
                                    onClick={() => handleCancelOffer()}
                                  >
                                    {offerCanceling ? (
                                      <ClipLoader color="#FFF" size={16} />
                                    ) : (
                                      t('buttons.withdraw')
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <div className={styles.noOffers}>
                        <div className={styles.noOffersLabel}>
                          {t('generic.noOffers')}
                        </div>
                        {(!isMine ||
                          (tokenType.current === 1155 &&
                            myHolding.supply < tokenInfo.totalSupply)) &&
                          (!auction.current || auction.current.resulted) && (
                            <MainButton
                              small
                              className={cx(
                                styles.makeOffer,
                                offerPlacing && styles.disabled
                              )}
                              onClick={() => setOfferModalVisible(true)}
                            >
                              {t('buttons.makeOffer')}
                            </MainButton>
                          )}
                      </div>
                    )}
                  </div>
                </Panel>
              </div>
              {bundleID && (
                <div className={styles.panelWrapper}>
                  <Panel
                    title={t('generic.items')}
                    icon={ViewModuleIcon}
                    expanded
                  >
                    <div className={styles.items}>
                      {(loading
                        ? [null, null, null]
                        : bundleItems.current
                      ).map((item, idx) => renderBundleItem(item, idx))}
                    </div>
                  </Panel>
                </div>
              )}
            </div>
          </div>
          <div className={styles.tradeHistoryWrapper}>
            <div className={styles.tradeHistoryHeader}>
              <div className={styles.tradeHistoryTitle}>{filters[filter]}</div>
              {!bundleID && (
                <div className={styles.filter} onClick={handleMenuOpen}>
                  <img src={filterIcon} className={styles.filterIcon} />
                </div>
              )}
            </div>
            <div className={styles.histories}>
              <div className={cx(styles.history, styles.heading)}>
                {filter === 0 && (
                  <div className={styles.historyPrice}>
                    {t('generic.price')}
                  </div>
                )}
                {tokenType.current === 1155 && (
                  <div className={styles.quantity}>{t('generic.quantity')}</div>
                )}
                <div className={styles.from}>{t('generic.from')}</div>
                <div className={styles.to}>{t('generic.to')}</div>
                <div className={styles.saleDate}>{t('generic.date')}</div>
              </div>
              {(historyLoading
                ? [null, null, null]
                : filter === 0
                ? tradeHistory.current
                : transferHistory.current
              ).map((history, idx) => {
                const saleDate = history ? new Date(history.createdAt) : null;
                return (
                  <div className={styles.history} key={idx}>
                    {filter === 0 && (
                      <div className={styles.historyPrice}>
                        {history ? (
                          <>
                            <img
                              src={history.token?.icon}
                              className={styles.tokenIcon}
                            />
                            {formatNumber(history.price)}
                          </>
                        ) : (
                          <Skeleton width={100} height={20} />
                        )}
                      </div>
                    )}
                    <div className={styles.from}>
                      {history ? (
                        <Link to={`/account/${history.from}`}>
                          <div className={styles.userAvatarWrapper}>
                            {history.fromImage ? (
                              <img
                                src={`${infuraUrl}/${history.fromImage}`}
                                className={styles.userAvatar}
                              />
                            ) : (
                              <Identicon
                                account={history.from}
                                size={24}
                                className={styles.userAvatar}
                              />
                            )}
                          </div>
                          {history.fromAlias || history.from?.substr(0, 6)}
                        </Link>
                      ) : (
                        <Skeleton width={180} height={20} />
                      )}
                    </div>
                    <div className={styles.to}>
                      {history ? (
                        <Link to={`/account/${history.to}`}>
                          <div className={styles.userAvatarWrapper}>
                            {history.toImage ? (
                              <img
                                src={`${infuraUrl}/${history.toImage}`}
                                className={styles.userAvatar}
                              />
                            ) : (
                              <Identicon
                                account={history.to}
                                size={24}
                                className={styles.userAvatar}
                              />
                            )}
                          </div>
                          {history.toAlias || history.to?.substr(0, 6)}
                        </Link>
                      ) : (
                        <Skeleton width={180} height={20} />
                      )}
                    </div>
                    <div className={styles.saleDate}>
                      {saleDate ? (
                        formatDate(saleDate)
                      ) : (
                        <Skeleton width={150} height={20} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {!bundleID && (
              <div className={styles.panelWrapper}>
                <Panel
                  title={t('generic.more')}
                  icon={ViewModuleIcon}
                  responsive
                >
                  <div className={styles.panelBody}>
                    {loading ? (
                      <div className={styles.loadingIndicator}>
                        <ClipLoader color="#007BFF" size={16} />
                      </div>
                    ) : (
                      <div className={styles.itemsList}>
                        {moreItems.current.map((item, idx) => (
                          <div key={idx} className={styles.moreItem}>
                            <NFTCard item={item} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Panel>
              </div>
            )}
          </div>
        </div>

        {renderMenu}

        <Menu
          id="simple-menu"
          anchorEl={shareAnchorEl}
          keepMounted
          open={Boolean(shareAnchorEl)}
          onClose={handleClose}
          classes={{ paper: styles.shareMenu, list: styles.shareMenuList }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CopyToClipboard text={window.location.href} onCopy={handleCopyLink}>
            <MenuItem classes={{ root: styles.menuItem }}>
              <img src={iconKiwii} alt="Fook Icon" />
              {t('messages.copyLink')}
            </MenuItem>
          </CopyToClipboard>
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleShareOnFacebook}
          >
            <img src={iconFacebook} />
            {t('social.shareFb')}
          </MenuItem>
          <MenuItem
            classes={{ root: styles.menuItem }}
            onClick={handleShareToTwitter}
          >
            <img src={iconTwitter} />
            {t('social.shareTwt')}
          </MenuItem>
        </Menu>
        <TransferModal
          visible={transferModalVisible}
          totalSupply={tokenType.current === 1155 ? myHolding?.supply : null}
          transferring={transferring}
          onTransfer={handleTransfer}
          onClose={() => setTransferModalVisible(false)}
        />
        <SellModal
          visible={sellModalVisible}
          onClose={() => setSellModalVisible(false)}
          onSell={hasListing ? handleUpdateListing : handleListItem}
          startPrice={
            bundleID
              ? bundleListing.current?.price || 0
              : myListing()?.price || 0
          }
          confirming={listingItem || priceUpdating}
          approveContract={
            bundleID
              ? handleApproveBundleSalesContract
              : handleApproveSalesContract
          }
          contractApproving={salesContractApproving}
          contractApproved={
            bundleID ? isBundleContractApproved : salesContractApproved
          }
          totalSupply={tokenType.current === 1155 ? myHolding?.supply : null}
        />
        <OfferModal
          visible={offerModalVisible}
          onClose={() => setOfferModalVisible(false)}
          onMakeOffer={handleMakeOffer}
          confirming={offerPlacing}
          totalSupply={tokenType.current === 1155 ? maxSupply() : null}
        />
        <AuctionModal
          visible={auctionModalVisible}
          onClose={() => setAuctionModalVisible(false)}
          onStartAuction={
            auction.current ? handleUpdateAuction : handleStartAuction
          }
          auction={auction.current}
          auctionStarted={auctionStarted}
          confirming={auctionStarting || auctionUpdating}
          approveContract={handleApproveAuctionContract}
          contractApproving={auctionContractApproving}
          contractApproved={auctionContractApproved}
        />
        <BidModal
          visible={bidModalVisible}
          onClose={() => setBidModalVisible(false)}
          onPlaceBid={handlePlaceBid}
          minBidAmount={(bid?.bid || 0) + minBidIncrement}
          reservePrice={formatNumber(auction?.current?.reservePrice)}
          confirming={bidPlacing}
          token={auction.current?.token}
        />
        <OwnersModal
          visible={ownersModalVisible}
          onClose={() => setOwnersModalVisible(false)}
          holders={holders}
        />
        <LikesModal
          visible={likesModalVisible}
          onClose={() => setLikesModalVisible(false)}
          users={
            likeUsersFetching ? new Array(5).fill(null) : likeUsers.current
          }
        />
      </div>
    </ErrorBoundary>
  );
};

export default NFTItem;
