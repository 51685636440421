import React from 'react';

import footerLogo from 'assets/imgs/footer-logo.png';
import twitterLogo from 'assets/imgs/twitter-icon-footer.png';
import discordLogo from 'assets/imgs/discord-icon-footer.png';

import styles from './styles.module.scss';

import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.contentTop}>
        <div className={styles.logoWrapper}>
          <img src={footerLogo} alt="footer logo" />
        </div>
        <div className={styles.listWrapper}>
          <div className={styles.list}>
            <h3> {t('footer.marketplace')}</h3>
            <ul>
              <li className={styles.listItem}>
                <a href="/explore">{t('footer.exploreAll')}</a>
              </li>
              <li className={styles.listItem}>
                <span>{t('footer.create')}</span>
              </li>
            </ul>
          </div>
          <div className={styles.list}>
            <h3> {t('footer.resources')}</h3>
            <ul>
              <li className={styles.listItem}>
                <a href="https://discord.gg/mc7Qd39rew" target="_blank" rel="noreferrer">
                  {t('footer.helpCenter')}
                </a>
              </li>
              {/* <li className={styles.listItem}>
                <a href="#">{t('footer.datasets')}</a>
              </li>
              <li className={styles.listItem}>
                <a href="#">{t('footer.notebooks')}</a>
              </li> */}
                <li className={styles.listItem}>
                  <span>{t('footer.publicApi')}</span>
                </li>
            </ul>
          </div>
          <div className={styles.list}>
            <h3> {t('footer.company')}</h3>
            <ul>
              <li className={styles.listItem}>
                <a href="https://skunkdao.io/" target="_blank" rel="noreferrer">
                  {t('footer.ourTeam')}
                </a>
              </li>
              <li className={styles.listItem}>
                <a href="https://medium.com/skunkdao" target="_blank" rel="noreferrer">
                  {t('footer.blog')}
                </a>
              </li>
              <li className={styles.listItem}>
                <a href="https://discord.gg/mc7Qd39rew" target="_blank" rel="noreferrer">
                  {t('footer.contactUs')}
                </a>
              </li>
              <li className={styles.listItem}>
                <div className={styles.mediaIcons}>
                  <div className={styles.mediaItem}>
                    <a href="https://twitter.com/KiwiiDAO" target="_blank" rel="noreferrer">
                      <img src={twitterLogo} alt="twitter icon" />
                    </a>
                  </div>
                  <div className={styles.mediaItem}>
                    <a href="https://discord.gg/mc7Qd39rew" target="_blank" rel="noreferrer">
                      <img src={discordLogo} alt="discord icon" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.contentBottom}>
        {new Date().getFullYear()} kiwii.io. |
        <span>
          {t('footer.terms')}
        </span>
        |
        <span>
          {t('footer.privacy')}
        </span>
      </div>
    </div>
  );
};

export default Footer;
