import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(90deg, #D905A6 0%, #FCBE0B 100%)',
    backgroundColor: 'red',
    borderRadius: '10px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '24px',
    fontWeight: 600,
    gap: '10px',
    lineHeight: '36px',
    padding: '13px 32px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    textDecoration: 'none',
  },
  disabled: {
    background: 'gray',
    cursor: 'not-allowed',
    '&:hover': {
      background: 'gray',
    },
  },
  outlined: {
    border: '1px solid #000000',
    background: 'none',
    color: 'black',
    width: '97%',
    height: '97%',
    '&:hover': {
      background: 'none',
    },
  },
  reversed: {
    background: 'white',
    '&:hover': {
      background: 'white',
    },
  },
  subBg: {
    height: '100%',
    width: '100%',
    background: 'linear-gradient(90deg, #D905A6 0%, #FCBE0B 100%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  small: {
    padding: '4px 16px',
    fontSize: '16px',
  },
}));

export const MainButton = ({
  onClick,
  disabled,
  children,
  outlined,
  reversed,
  small,
}) => {
  const classes = useStyles();

  const cx = classNames.bind(classes);

  return (
    <Button
      variant="contained"
      className={cx(classes.button, {
        disabled: disabled,
        outlined: outlined,
        reversed: reversed,
        small: small,
      })}
      onClick={() => (disabled || !onClick ? null : onClick())}
    >
      <div className={cx({ subBg: reversed })}>{children}</div>
    </Button>
  );
};
