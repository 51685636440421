import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './hero.css'

import styles from './styles.module.scss';

import nft1 from 'assets/imgs/heroNFT1.png';
import nft2 from 'assets/imgs/heroNFT2.png';
import nft3 from 'assets/imgs/heroNFT3.png';
import nft4 from 'assets/imgs/heroNFT4.jpeg';
import nft5 from 'assets/imgs/heroNFT5.png';
import nft6 from 'assets/imgs/heroNFT6.jpeg';
import logo from 'assets/imgs/heroNFT-x.png';
// import logoVerified from 'assets/imgs/verified.png';
import { useTranslation } from 'react-i18next';

const HeroBannerCarousel = () => {
  const { t } = useTranslation('common');
  // const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  // const isVerified = true;
  return (
    <Carousel
      autoPlay
      showThumbs={false}
      interval={2000}
      infiniteLoop
      showStatus={false}
      swipeable
      className={styles.root}
      showArrows={false}
  
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        const defStyle = {
          borderRadius: '10px',
          color: 'transparent',
          cursor: 'pointer',
          display: 'inline-block',
          marginLeft: '20px',
          transform: 'translate(-10px, 12px)',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          width: '10%',
          padding: '2px 0',
        };

        const style = isSelected
          ? {
              ...defStyle,
              border: '1px solid #FFFFFF',
              backgroundColor: 'white',
            }
          : {
              ...defStyle,
              border: '1px solid rgba(255, 255, 255, 0)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            };
        return (
          <span
            style={style}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft1} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft2} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            {/* <img src={`${infuraUrl}/QmVYdHEVHjt3ivnuW4t9xiztFYhnkBKAm73pT8wUhMn3j5/Nahmii%20Mark.svg`} /> */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft3} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            {/* <img src={`${infuraUrl}/QmVYdHEVHjt3ivnuW4t9xiztFYhnkBKAm73pT8wUhMn3j5/Nahmii%20Mark.svg`} /> */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft4} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            {/* <img src={`${infuraUrl}/QmVYdHEVHjt3ivnuW4t9xiztFYhnkBKAm73pT8wUhMn3j5/Nahmii%20Mark.svg`} /> */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft5} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            {/* <img src={`${infuraUrl}/QmVYdHEVHjt3ivnuW4t9xiztFYhnkBKAm73pT8wUhMn3j5/Nahmii%20Mark.svg`} /> */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardMedia}>
          <div className={styles.imageItem}>
            <img src={nft6} />
          </div>
        </div>
        <div className={styles.cardInfo}>
          <div className={styles.logoWrapper}>
            {/* {isVerified && (
              <div className={styles.logoVerified}>
                <img src={logoVerified} alt="logo verified" />
              </div>
            )} */}
            {/* <img src={`${infuraUrl}/QmVYdHEVHjt3ivnuW4t9xiztFYhnkBKAm73pT8wUhMn3j5/Nahmii%20Mark.svg`} /> */}
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.cardDescription}>
            <div className={styles.cardCategory}>
              {t('landing.mainCard.category')}
            </div>
            <div className={styles.cardNameContainer}>
              Created by: &nbsp;
              <div className={styles.cardName}>{t('landing.mainCard.name')}</div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default HeroBannerCarousel;
