import React, { useRef, useState } from 'react';

import styles from './styles.module.scss';
import cx from 'classnames';

import settingsIcon from 'assets/imgs/settingsIcon.png';
import reportIcon from 'assets/icons/Report.svg';
import { useTranslation } from 'react-i18next';
import CollectionDescriptionModal from 'components/CollectionDescriptionModal';
import { useApi } from 'api';
import toast from 'utils/toast';

const SocialBarOptions = ({
  socialBarActive,
  setSocialBarActive,
  isOwner,
  authToken,
  collectionAddress,
  setUpdatedCollectionBanner,
  setUpdatedCollectionDescription,
}) => {
  const inputRef = useRef(null);

  const { t } = useTranslation('common');

  const [modalActive, setModalActive] = useState(false);

  const { updateCollectionBanner } = useApi();

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      setSocialBarActive(false);

      reader.onload = async function(e) {
        const { data } = await updateCollectionBanner(
          collectionAddress,
          e.target.result,
          authToken
        );
        setUpdatedCollectionBanner(data);
        toast('success', t('messages.collectionBannerChange'));
      };
      reader.readAsDataURL(file);
    }
    e.target.value = null;
  };

  return (
    <div className={cx(styles.wrapper, !socialBarActive && styles.inactive)}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div className={styles.iconWrapper}>
            <img src={reportIcon} alt="report icon" />
          </div>
          {t('collectionPage.report')}
        </li>
        {isOwner ? (
          <li
            className={styles.listItem}
            onClick={() => inputRef.current?.click()}
          >
            <div className={styles.iconWrapper}>
              <img src={settingsIcon} alt="report icon" />
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileSelect}
              />
            </div>
            {t('collectionPage.changeBanner')}
          </li>
        ) : (
          <></>
        )}
        {isOwner && (
          <li className={styles.listItem} onClick={() => setModalActive(true)}>
            <div className={styles.iconWrapper}>
              <img src={settingsIcon} alt="settings icon" />
            </div>
            {t('collectionPage.changeDescription')}
          </li>
        )}
      </ul>
      <CollectionDescriptionModal
        setSocialBarActive={setSocialBarActive}
        visible={modalActive}
        onClose={() => setModalActive(false)}
        collectionAddress={collectionAddress}
        setUpdatedCollectionDescription={setUpdatedCollectionDescription}
      />
    </div>
  );
};

export default SocialBarOptions;
