import React, { useState, useEffect, useRef, useCallback } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import cx from 'classnames';
import Modal from '../Modal';
import styles from './styles.module.scss';
import modalStyles from '../Modal/styles.module.scss';

import CustomTimePicker from '../TimePicker';

const DateTimePicker = ({
  visible,
  onClose,
  handleSubmit,
  disabled,
  onStart,
  isValidDate,
}) => {
  const [value, onChange] = useState(new Date());
  const [isTypingMinute, setIsTypingMinute] = useState(false);
  const [isTypingHour, setIsTypingHour] = useState(false);
  const minuteInputRef = useRef(null);

  const currentHour = onStart.getHours();
  const currentMinute = onStart.getMinutes();

  const [minute, setMinute] = useState(currentMinute);
  const [hour, setHour] = useState(currentHour);
  const [isValid, setIsValid] = useState(true);

  const formatTime = () => {
    const calendarDate = new Date(value);
    const customTime = `${hour}:${minute}:${new Date().getSeconds()}`;
    // Split the custom time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = customTime.split(':').map(Number);
    const newTime = new Date(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      calendarDate.getDate(),
      hours,
      minutes,
      seconds
    );
    return newTime;
  };

  const incrementHour = useCallback(() => {
    setHour((prevHour) => (prevHour + 1) % 24); // Hours wrap from 23 to 00
  }, [setHour]);

  const decrementHour = useCallback(() => {
    setHour((prevHour) => (prevHour - 1 + 24) % 24); // Hours wrap from 00 to 23
  }, [setHour]);

  const incrementMinute = useCallback(() => {
    setMinute((prevMinute) => {
      if (prevMinute === 59) {
        incrementHour();
        return 0;
      } else {
        return prevMinute + 1;
      }
    });
  }, [incrementHour, setMinute]);

  const decrementMinute = useCallback(() => {
    setMinute((prevMinute) => {
      if (prevMinute === 0) {
        decrementHour();
        return 59;
      } else {
        return prevMinute - 1;
      }
    });
  }, [decrementHour, setMinute]);

  const handleHourChange = (newHour) => {
    setHour(newHour);
    if(!isNaN(newHour) && newHour.toString().length === 2) {
      minuteInputRef.current.focus();
      setIsTypingHour(false)
      setIsTypingMinute(true)
    }
  };

  const handleMinuteChange = (newMinute) => {
    setMinute(newMinute);
  };

  const getCurrentTime = () => {
    let time = formatTime();
    setIsValid(time.getTime() > isValidDate);
  };

  useEffect(() => {
    // Update the hour and minute when the component first mounts
    setHour(currentHour);
    setMinute(currentMinute);
  }, [currentHour, currentMinute]);

  useEffect(() => {
    // get current value from datetime comonent
    getCurrentTime();
  }, [hour, minute, value]);

  return (
    <Modal visible={visible} onClose={onClose} showHeader={false}>
      <div className={styles.calendarContainer}>
        <div onClick={onClose} className={styles.closeBtn}>x</div>
        <div className={styles.calendarWrapper}>
          <Calendar onChange={onChange} value={value} />
        </div>
        <div className={styles.timeWrrapper}>
          <CustomTimePicker
            incrementHour={incrementHour}
            decrementHour={decrementHour}
            incrementMinute={incrementMinute}
            decrementMinute={decrementMinute}
            onHourChange={handleHourChange}
            onMinuteChange={handleMinuteChange}
            hour={hour}
            minute={minute}
            minuteInputRef={minuteInputRef}
            isTypingMinute={isTypingMinute}
            setIsTypingMinute={setIsTypingMinute}
            isTypingHour={isTypingHour}
            setIsTypingHour={setIsTypingHour}
          />
          {!isValid && 
            <p className={styles.invalidDate}>Invalid date</p>
          }
          <div
            className={cx(
              modalStyles.submitButton,
              modalStyles.dateTime,
              (disabled || !isValid) && modalStyles.disabled
            )}
            onClick={() => (!isValid ? null : handleSubmit(formatTime()))}
            data-testid="date-time-submit-button"
          >
            Apply
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DateTimePicker;
