import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import toast from 'utils/toast';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import { useTranslation } from 'react-i18next';

import { useApi } from 'api';

const CollectionDescriptionModal = ({
  visible,
  onClose,
  setSocialBarActive,
  collectionAddress,
  setUpdatedCollectionDescription,
}) => {
  const { t } = useTranslation('common');
  const { authToken } = useSelector(state => state.ConnectWallet);

  const [processing, setProcessing] = useState(false);
  const [description, setDescription] = useState('');

  const { updateCollectionDescription } = useApi();

  useEffect(() => {
    if (visible) {
      setProcessing(false);
    }
  }, [visible]);

  const handleUpdateCollectionDescription = async () => {
    setProcessing(true);

    const data = await updateCollectionDescription(
      collectionAddress,
      description,
      authToken
    );

    setUpdatedCollectionDescription(data);
    toast('success', t('messages.collDescriptionUpdate'));
    setProcessing(false);
    setDescription('');
    onClose();
    setSocialBarActive(false);
  };

  const onCloseModal = () => {
    setDescription('');
    onClose();
    setSocialBarActive(false);
  };

  return (
    <Modal
      visible={visible}
      title={t('collectionPage.changeDescription')}
      onClose={onCloseModal}
      submitDisabled={processing}
      submitLabel={
        processing ? <ClipLoader color="#FFF" size={16} /> : 'Update'
      }
      onSubmit={!processing ? () => handleUpdateCollectionDescription() : null}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>
          {t('collectionPage.collectionDescription')}
        </div>
        <div className={styles.formTextAreaControl}>
          <textarea
            className={styles.formTextArea}
            placeholder="New Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            disabled={processing}
            rows={5}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CollectionDescriptionModal;
