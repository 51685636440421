import { MainButton } from 'components/MainButton';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export default function Discover() {
  const { t } = useTranslation('common');
  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionTitle}>{t('discover.title')}</div>
      <div className={styles.container}>
        <div className={`${styles.box} ${styles.boxLeft}`}>
          <div className={styles.expander} />
          <div className={styles.content}>
            <div className={styles.title}>{t('discover.createCollection')}</div>
            {/* <Link to="/collection/create" className={styles.linkButton}> */}
              <MainButton disabled small reversed>{t('discover.createNow')}</MainButton>
            {/* </Link> */}
          </div>
        </div>
        <div className={`${styles.box} ${styles.boxRight}`}>
          <div className={styles.expander} />
          <div className={styles.content}>
            <div className={styles.title}>{t('discover.learnMore')}</div>
            <Link to="/about" className={styles.linkButton}>
              <MainButton small reversed>{t('generic.about')}</MainButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
