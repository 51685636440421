import React, { Suspense } from 'react';
import Loader from 'react-loader-spinner';

import styles from './styles.module.scss';

import ethereumIcon from 'assets/imgs/Ethereum.png';
import verifiedIcon from 'assets/imgs/verified.png';

import { shortenAddress } from 'utils';
import SuspenseImg from 'components/SuspenseImg';

const ExploreCard = ({
  name,
  description,
  owner,
  bannerIpfs,
  logoIpfs,
  isVerified,
  handleCollectionDetails,
}) => {
  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;
  return (
    <div className={styles.cardWrapper} onClick={handleCollectionDetails}>
      <div className={styles.iconContainer}>
        <img src={ethereumIcon} alt="Ethereum icon" />
      </div>
      <div className={styles.circleWrapper}>
        {logoIpfs && (
          <Suspense
            fallback={
              <Loader
                type="Oval"
                color="#007BFF"
                height={32}
                width={32}
                className={styles.loader}
              />
            }
          >
            <SuspenseImg
              src={`${infuraUrl}/${logoIpfs}`}
              className={styles.mediaImage}
              alt={name}
            />
          </Suspense>
        )}
      </div>
      <div className={styles.imageContainer}>
        {bannerIpfs ? (
          <Suspense
            fallback={
              <Loader
                type="Oval"
                color="#007BFF"
                height={32}
                width={32}
                className={styles.loader}
              />
            }
          >
            <SuspenseImg
              src={`${infuraUrl}/${bannerIpfs}`}
              className={styles.mediaImage}
              alt={name}
            />
          </Suspense>
        ) : (
          <div className={styles.imagePlaceholder} />
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          <span>{name}</span>
          {isVerified && (
            <div className={styles.verifiedIcon}>
              <img src={verifiedIcon} alt="verified icon" />
            </div>
          )}
        </div>
        <div className={styles.subtitle}>
          <span>By</span>
          <span>{shortenAddress(owner)}</span>
        </div>
        <div className={styles.text}>{description}</div>
      </div>
    </div>
  );
};

export default ExploreCard;
