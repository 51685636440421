import React from 'react';
import styles from './styles.module.scss';
import arrowDown from 'assets/svgs/arrowDown.svg';
import arrowUp from 'assets/svgs/arrowUp.svg';
import cx from 'classnames';

function CustomTimePicker({
  incrementHour,
  decrementHour,
  incrementMinute,
  decrementMinute,
  onHourChange,
  onMinuteChange,
  hour,
  minute,
  minuteInputRef,
  isTypingMinute,
  setIsTypingMinute,
  isTypingHour,
  setIsTypingHour,
}) {
  
  const handleHourChange = (e) => {
    const newHour = parseInt(e.target.value, 10);
    if(isNaN(newHour)) {
      onHourChange(0);
      return;
    }
    if (!isNaN(newHour) && newHour >= 0 && newHour <= 23) {
      onHourChange(newHour);
    }
  };

  const handleMinuteChange = (e) => {
    const newMinute = parseInt(e.target.value, 10);
    if(isNaN(newMinute)) {
      onMinuteChange(0);
      return;
    }
    if (!isNaN(newMinute) && newMinute >= 0 && newMinute <= 59) {
      onMinuteChange(newMinute);
    }
  };

  const handleKeyDown = (e, increment, decrement) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      increment();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      decrement();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <img src={arrowUp} data-testid="increment-hour" alt="arrowDown" onClick={incrementHour} />
        <div className={styles.timeBoxContainer}>
          <input
            type="text"
            value={hour}
            onChange={handleHourChange}
            maxLength={2}
            placeholder="HH"
            onFocus={(e) => e.target.select()}
            data-testid='date-time-hour'
            onKeyDown={(e) => handleKeyDown(e, incrementHour, decrementHour)}
            className={cx(styles.timeBox, isTypingHour && styles.timeBoxActive)}
            onClick={() => setIsTypingHour(true)}
            onBlur={() => setIsTypingHour(false)}
          />
          <div className={cx(styles.visibleValue, isTypingHour && styles.hideVisibleValue)}>
            {hour.toString().padStart(2, '0')}
          </div>
        </div>
        <img src={arrowDown} data-testid="decrement-hour" alt="arrowDown" onClick={decrementHour} />
      </div>
      <span>:</span>
      <div className={styles.group}>
        <img src={arrowUp} alt="arrowDown" onClick={incrementMinute} data-testid="increment-minute" />
        <div className={styles.timeBoxContainer}>
          <input
            ref={minuteInputRef}
            type="text"
            value={minute}
            onChange={handleMinuteChange}
            maxLength={2}
            placeholder="MM"
            onFocus={(e) => e.target.select()}
            className={cx(styles.timeBox, isTypingMinute && styles.timeBoxActive)}
            onClick={() => setIsTypingMinute(true)}
            onBlur={() => setIsTypingMinute(false)}
            data-testid='date-time-minute'
            onKeyDown={(e) => handleKeyDown(e, incrementMinute, decrementMinute)}
          />
          <div className={cx(styles.visibleValue, isTypingMinute && styles.hideVisibleValue)}>
            {minute.toString().padStart(2, '0')}
          </div>
        </div>
        <img src={arrowDown} alt="arrowDown" data-testid="decrement-minute" onClick={decrementMinute} />
      </div>
    </div>
  );
}

export default CustomTimePicker;
