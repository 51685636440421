import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Header from 'components/header';
import banner from '../../assets/imgs/exploreBanner.png';
import ExploreCard from 'components/ExploreCard';
import { Categories } from 'constants/filter.constants';
import { useSelector } from 'react-redux';
import ErrorBoundary from 'components/ErrorBoundary';

import { useApi } from 'api';

import Footer from 'components/Footer/Footer';

import './styles.scss';

const allCategories = [
  { id: Categories.length, icon: '', label: 'All' },
  ...Categories,
];

const ExplorePage = () => {
  const { t } = useTranslation('common');
  const isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);

  const [collectionsDetails, setCollectionsDetails] = useState();
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [activeSortItem, setActiveSortItem] = useState(allCategories[0]);

  const history = useHistory();

  const { getAllCollectionsDetails } = useApi();

  const fetchAllCollectionDetails = async () => {
    try {
      const res = await getAllCollectionsDetails();
      setCollectionsDetails(res);
    } catch (err) {
      console.log(err);
    }
  };

  const filterCollections = activeId =>
    collectionsDetails.filter(collection =>
      collection.categories.includes(activeId.toString())
    );

  const handleCollectionDetails = addr => {
    history.push(`/explore/${addr}`, { collection: addr });
  };

  useEffect(() => {
    fetchAllCollectionDetails();
  }, []);

  useEffect(() => {
    if (collectionsDetails) {
      const filteredElements = filterCollections(activeSortItem.id);
      if (activeSortItem.id === allCategories.length - 1)
        setFilteredCollections(collectionsDetails);
      else setFilteredCollections(filteredElements);
      setInitialRender(false);
    }
  }, [activeSortItem]);

  const sortItems = allCategories.map(item => {
    let className;
    if (item.id === activeSortItem.id) {
      className = 'sortItem active';
    } else className = 'sortItem';
    return (
      <div
        id={item.id}
        key={item.id}
        className={className}
        onClick={() => {
          setActiveSortItem(item);
        }}
      >
        {item.label}
      </div>
    );
  });

  const collectionsToMap =
    collectionsDetails && initialRender
      ? collectionsDetails
      : filteredCollections;

  return (
    <ErrorBoundary>
      <div
        className="container"
        style={!isBannerShown ? { paddingTop: '0px' } : { paddingTop: '38px' }}
      >
        <Header />
        <div className="containerWrapper">
          <div className="imageBanner">
            <img src={banner} alt="explore page banner" />
          </div>
          <div className="content">
            <h1 className="title">
              {t('pages.explore.explore')}
              <span>{activeSortItem.label}</span>
            </h1>
            <div className="sortPanel">{sortItems}</div>
            <div className="cardsWrapper">
              {collectionsToMap.map(collection => (
                <ExploreCard
                  key={collection.name}
                  bannerIpfs={collection.bannerIpfs}
                  description={collection.collectionDescription}
                  isVerified={collection.isVerified}
                  logoIpfs={collection.logoIpfs}
                  name={collection.collectionName}
                  owner={collection.collectionOwner}
                  handleCollectionDetails={() =>
                    handleCollectionDetails(collection.collectionAddress)
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default ExplorePage;
