import React, { useEffect, useState, useRef, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames/bind';
import { useWeb3React } from '@web3-react/core';
import { useResizeDetector } from 'react-resize-detector';

import NFTsGrid from 'components/NFTsGrid';
import Header from 'components/header';
import { useApi } from 'api';
import TokensActions from 'actions/tokens.actions';
import HeaderActions from 'actions/header.actions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import usePrevious from 'hooks/usePrevious';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import CollectionsActions from 'actions/collections.actions';
import { shortenAddress } from 'utils';
import NewExploreFilterHeader from './Body/NewFilterHeader';
import SocialBar from 'components/SocialBar';
import SocialBarOptions from 'components/SocialBar/SocialBarOptions';
import Loader from 'react-loader-spinner';
import SuspenseImg from 'components/SuspenseImg';
import toast from 'utils/toast';

import MultiDropDown from './Body/NestedDropdown';

const ExploreCollection = () => {
  const {
    fetchCollections,
    fetchTokens,
    getItemsLiked,
    getCollectionDetails,
    getAttributes,
    getNFTsByAttributes,
  } = useApi();

  const cx = classNames.bind(styles);

  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { chainId } = useWeb3React();
  const history = useHistory();

  const { addr } = useParams();

  const priceData = useSelector(state => state.Price.price);
  const isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);

  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
    ];
    const item = lookup
      .slice()
      .reverse()
      .find(function check(item1) {
        return num >= item1.value;
      });
    return item ? (num / item.value).toFixed(digits) + item.symbol : '0';
  }

  const { account } = useWeb3React();

  const { width: gridWidth, ref } = useResizeDetector();
  const { width } = useWindowDimensions();

  const conRef = useRef();
  const [collectionDetails, setCollectionDetails] = useState({});
  const [collectionFilters, setCollectionFilters] = useState([]);
  const [filterAttributeBy, setFilterAttributesBy] = useState({});
  const [cancelSource, setCancelSource] = useState(null);
  const [collectionOwner, setIsCollectionOwner] = useState(false);

  const [likeCancelSource, setLikeCancelSource] = useState(null);

  const [socialBarOptionsActive, setSocialBarOptionsActive] = useState(false);
  const [updatedCollectionBanner, setUpdatedCollectionBanner] = useState(null);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [currentSort, setCurrentSort] = useState('');
  const [
    updatedCollectionDescription,
    setUpdatedCollectionDescription,
  ] = useState(null);

  const { authToken } = useSelector(state => state.ConnectWallet);
  const {
    groupType,
    category,
    sortBy,
    statusBuyNow,
    statusHasBids,
    statusHasOffers,
    statusOnAuction,
  } = useSelector(state => state.Filter);

  const prevAuthToken = usePrevious(authToken);
  const numPerRow = Math.floor(gridWidth / 340);
  const fetchCount = numPerRow <= 3 ? 18 : numPerRow === 4 ? 16 : numPerRow * 3;

  const fetchAttributes = async () => {
    const response = await getAttributes(addr);
    setCollectionFilters(response?.data);
  };

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    fetchAttributes();
  }, []);

  useEffect(() => {
    if (Object.keys(filterAttributeBy).length > 0) {
      fetchNFTsByAttributes();
    }
  }, [filterAttributeBy]);

  const clearFilter = () => {
    setFilterAttributesBy({});
    fetchNFTs();
  };

  const fetchNFTsByAttributes = async () => {
    setFetching(true);
    if (cancelSource) {
      cancelSource.cancel();
    }
    if (isNaN(fetchCount)) return;

    try {
      const cancelTokenSource = axios.CancelToken.source();
      setCancelSource(cancelTokenSource);

      const newTokens = await getNFTsByAttributes(
        addr,
        filterAttributeBy,
        cancelTokenSource.token
      );

      setTokens(newTokens?.data);
      setFetching(false);
      setIsFetchedAll(true);
    } catch (e) {
      toast('error', 'Something went wrong', 'Tokens could no be retrieved');
    } finally {
      setFetching(false);
      setCancelSource(null);
    }
  };

  const fetchNFTs = async () => {
    setFetching(true);
    if (cancelSource) {
      cancelSource.cancel();
    }
    if (isNaN(fetchCount)) return;

    try {
      const filterBy = [];
      if (statusBuyNow) filterBy.push('buyNow');
      if (statusHasBids) filterBy.push('hasBids');
      if (statusHasOffers) filterBy.push('hasOffers');
      if (statusOnAuction) filterBy.push('onAuction');

      const sortChanged = sortBy !== currentSort;

      if (sortChanged) {
        setCurrentSort(sortBy);
      }

      let offset = sortChanged ? 0 : tokens.length;

      const cancelTokenSource = axios.CancelToken.source();
      setCancelSource(cancelTokenSource);

      const collections = [addr];

      const { data } = await fetchTokens(
        offset,
        fetchCount,
        groupType,
        collections,
        category,
        sortBy,
        filterBy,
        null,
        cancelTokenSource.token
      );

      let newTokens = sortChanged
        ? [...data.tokens]
        : [...tokens, ...data.tokens];
      newTokens = newTokens.filter(
        (tk, idx) =>
          newTokens.findIndex(_tk =>
            tk.items
              ? tk._id === _tk._id
              : tk.contractAddress === _tk.contractAddress &&
                tk.tokenID === _tk.tokenID
          ) === idx
      );
      setTokens(newTokens);
    } catch (e) {
      toast('error', 'Something went wrong', 'Tokens could no be retrieved');
    } finally {
      setFetching(false);
      setCancelSource(null);
    }
  };
  const handleScroll = e => {
    if (fetching) return;
    if (isFetchedAll) return;
    const obj = e.target;
    if (obj.scrollHeight - obj.clientHeight - obj.scrollTop < 200) {
      fetchNFTs();
    }
  };

  useEffect(() => {
    if (tokens.length >= collectionDetails.numItems) {
      setIsFetchedAll(true);
    }
  }, [tokens, collectionDetails]);

  useEffect(() => {
    fetchNFTs();
  }, [
    sortBy,
    statusBuyNow,
    statusHasBids,
    statusHasOffers,
    statusOnAuction,
    chainId,
    numPerRow,
  ]);

  const updateItems = async () => {
    try {
      if (!authToken) {
        if (prevAuthToken) {
          dispatch(
            TokensActions.updateTokens(
              tokens.map(tk => ({
                ...tk,
                isLiked: false,
              }))
            )
          );
        }
        return;
      }
      let missingTokens = tokens.map((tk, index) =>
        tk.items
          ? {
              index,
              isLiked: tk.isLiked,
              bundleID: tk._id,
            }
          : {
              index,
              isLiked: tk.isLiked,
              contractAddress: tk.contractAddress,
              tokenID: tk.tokenID,
            }
      );
      if (prevAuthToken) {
        missingTokens = missingTokens.filter(tk => tk.isLiked === undefined);
      }

      if (missingTokens.length === 0) return;

      const cancelTokenSource = axios.CancelToken.source();
      setLikeCancelSource(cancelTokenSource);
      const { data, status } = await getItemsLiked(
        missingTokens,
        authToken,
        cancelTokenSource.token
      );
      if (status === 'success') {
        const newTokens = [...tokens];
        missingTokens.map((tk, idx) => {
          newTokens[tk.index].isLiked = data[idx].isLiked;
        });
        dispatch(TokensActions.updateTokens(newTokens));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLikeCancelSource(null);
    }
  };
  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));

    updateCollectionDetails();
    updateCollections();
  }, []);

  const updateCollectionDetails = async () => {
    try {
      const res = await getCollectionDetails(addr);
      setCollectionDetails(res);
      setIsCollectionOwner(
        res.collectionOwner.toLowerCase() === account?.toLowerCase()
      );
    } catch (err) {
      console.log({ err });
      history.replace('/404');
    }
  };

  const updateCollections = async () => {
    try {
      dispatch(CollectionsActions.fetchStart());
      const res = await fetchCollections();
      if (res.status === 'success') {
        const verified = [];
        const unverified = [];
        res.data.map(item => {
          if (item.isVerified) verified.push(item);
          else unverified.push(item);
        });
        dispatch(CollectionsActions.fetchSuccess([...verified, ...unverified]));
      }
    } catch {
      dispatch(CollectionsActions.fetchFailed());
    }
  };

  useEffect(() => {
    if (likeCancelSource) {
      likeCancelSource.cancel();
    }
    if (tokens.length) {
      updateItems();
    }
  }, [tokens, authToken]);
  return (
    <div
      className={styles.collectionWrap}
      ref={conRef}
      onScroll={width > 600 ? handleScroll : null}
      style={!isBannerShown ? { paddingTop: '0px' } : { paddingTop: '38px' }}
    >
      <Header border />
      <div className={styles.banner}>
        <div className={styles.socialsWrap}>
          <SocialBar
            socials={collectionDetails.socials}
            setActive={setSocialBarOptionsActive}
            active={socialBarOptionsActive}
          />
          <SocialBarOptions
            socialBarActive={socialBarOptionsActive}
            setSocialBarActive={setSocialBarOptionsActive}
            isOwner={collectionOwner}
            authToken={authToken}
            collectionAddress={collectionDetails?.collectionAddress}
            setUpdatedCollectionBanner={setUpdatedCollectionBanner}
            setUpdatedCollectionDescription={setUpdatedCollectionDescription}
          />
        </div>
        <div className={styles.detailsBody}>
          <div className={styles.detailHead}>
            <div className={styles.logoContainer}>
              {collectionDetails.logoIpfs && (
                <Suspense
                  fallback={
                    <Loader
                      type="Oval"
                      color="#007BFF"
                      height={50}
                      width={50}
                      className={styles.loader}
                    />
                  }
                >
                  <SuspenseImg
                    src={`${infuraUrl}/${collectionDetails.logoIpfs}`}
                    className={styles.collectionLogo}
                    alt={collectionDetails.name}
                  />
                </Suspense>
              )}
              <div
                className={cx({
                  verified: collectionDetails.isVerified,
                })}
              ></div>
            </div>
            <div className={styles.title}>
              <h1 className={styles.collectionName}>
                {collectionDetails.collectionName}
              </h1>
              <p className={styles.owner}>
                Created by{' '}
                <span className={styles.collectionCreator}>
                  {shortenAddress(collectionDetails?.collectionOwner)}
                </span>
              </p>
            </div>
          </div>
          <div className={styles.statContainer}>
            <div className={styles.statWrap}>
              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.highestSale')}
                </div>
                <div className={styles.statValue}>
                  ${nFormatter(collectionDetails.highestSale * priceData)}
                </div>
              </div>
              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.floorPrice')}
                </div>
                <div className={styles.statValue}>
                  $
                  {nFormatter(
                    Number(collectionDetails.floorPrice) * priceData,
                    1
                  )}
                </div>
              </div>
              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.marketCap')}
                </div>
                <div className={styles.statValue}>
                  {nFormatter(
                    Number(collectionDetails.floorPrice) *
                      Number(collectionDetails.numItems) *
                      priceData,
                    1
                  )}
                </div>
              </div>
              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.items')}
                </div>
                <div className={styles.statValue}>
                  {collectionDetails.numItems}
                </div>
              </div>
              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.owners')}
                </div>
                <div className={styles.statValue}>
                  {collectionDetails.numOwners}
                </div>
              </div>

              <div className={styles.statBox}>
                <div className={styles.statName}>
                  {t('collectionPage.totalVolume')}
                </div>
                <div className={styles.statValue}>
                  ${nFormatter(collectionDetails.volumeTraded * priceData)}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.collectionDescription}>
            {updatedCollectionDescription
              ? updatedCollectionDescription
              : collectionDetails.collectionDescription}
          </div>
        </div>
        {updatedCollectionBanner || collectionDetails.bannerIpfs ? (
          <Suspense
            fallback={
              <Loader
                type="Oval"
                color="#007BFF"
                height={50}
                width={50}
                className={styles.loaderBig}
              />
            }
          >
            <SuspenseImg
              src={
                updatedCollectionBanner
                  ? `${infuraUrl}/${updatedCollectionBanner}`
                  : `${infuraUrl}/${collectionDetails.bannerIpfs}`
              }
              useOverlay={true}
              className={styles.bannerImg}
              alt={collectionDetails.name}
            />
          </Suspense>
        ) : (
          <div className={styles.bannerPlaceholder}></div>
        )}
      </div>
      <div className={styles.filterHeader}>
        <NewExploreFilterHeader loading={fetching} category={category} />
        <MultiDropDown
          menu={collectionFilters}
          setFilterAttributesBy={setFilterAttributesBy}
        />
        {Object.keys(filterAttributeBy).length > 0 && (
          <div onClick={clearFilter} className={styles.resetGroup}>
            <span>Reset</span>
          </div>
        )}
      </div>
      <div className={styles.exploreAll} ref={ref}>
        {tokens.length === 0 && !fetching ? (
          'Empty Data'
        ) : (
          <NFTsGrid
            items={tokens}
            uploading={fetching}
            loading={fetching}
            numPerRow={numPerRow}
            category={category}
          />
        )}
      </div>
    </div>
  );
};

export default ExploreCollection;
