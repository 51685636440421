import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import ethereumLogo from 'assets/imgs/Ethereum.png';
import axios from 'axios';

const CardSmall = ({ item }) => {
  const [info, setInfo] = useState(null);

  const getTokenURI = async tokenURI => {
    try {
      const { data } = await axios.get(tokenURI);
      setInfo(data);
    } catch {
      setInfo(null);
    }
  };
  useEffect(() => {
    if (typeof item?.tokenURI !== 'undefined') {
      getTokenURI(item?.tokenURI);
    }
  }, []);
  return (
    <div className={styles.cardWrapper}>
      <Link
        to={`/explore/${item.contractAddress}/${item.tokenID}`}
        className={styles.link}
      >
        <div className={styles.iconWrapper}>
          <img src={ethereumLogo} alt={item.name} />
        </div>
        <div className={styles.imageWrapper}>
          <img src={item.imageURL} alt={item.name} />
        </div>
        <div className={styles.textContent}>
          <h3 className={styles.collectionName}>{item?.name}</h3>
          <span>{info?.properties?.collection}</span>
        </div>
      </Link>
    </div>
  );
};

export default CardSmall;
