// import nahmiiChainId from '../constants/nahmiiChainId';

import { Contracts } from 'constants/networks';
import useContract from 'hooks/useContract';

import { FACTORY_ABI } from './abi';

// eslint-disable-next-line no-undef
// const isMainnet = window.__RUNTIME_CONFIG__.REACT_APP_ENV === 'MAINNET';

export const useFactoryContract = () => {
  const { getContract } = useContract();

  const getFactoryContract = async () =>
    await getContract(Contracts.factory, FACTORY_ABI);

  const getPrivateFactoryContract = async () =>
    await getContract(Contracts.privateFactory, FACTORY_ABI);

  const getArtFactoryContract = async () =>
    await getContract(Contracts.artFactory, FACTORY_ABI);

  const getPrivateArtFactoryContract = async () =>
    await getContract(Contracts.privateArtFactory, FACTORY_ABI);

  const createNFTContract = async (contract, name, symbol, from) => {
    const args = [name, symbol];
    const fee = await getFactoryFee();
    const options = {
      from,
      value: fee,
    };
    // try {
    //   console.log('getting gas estimate');
    //   const gasEstimate = await contract.estimateGas.createNFTContract(...args);
    //   options.gasLimit = calculateGasMargin(gasEstimate);
    // } catch (err) {
    //   console.log('failed to get gas estimate', err);
    // }

    return await contract.createNFTContract(...args, options);
  };

  const getFactoryFee = async () => {
    const contract = await getContract(Contracts.factory, FACTORY_ABI);
    const fee = await contract.platformFee();
    return fee;
  };

  const getPrivateFactoryFee = async () => {
    const contract = await getContract(Contracts.privateFactory, FACTORY_ABI);
    const fee = await contract.platformFee();
    return fee;
  };

  const getFactoryContractAddress = async () => {
    return Contracts.factory;
  };

  const getPrivateFactoryContractAddress = async () => {
    return Contracts.privateFactory;
  };

  return {
    getFactoryContract,
    getPrivateFactoryContract,
    getArtFactoryContract,
    getPrivateArtFactoryContract,
    createNFTContract,
    getFactoryFee,
    getFactoryContractAddress,
    getPrivateFactoryContractAddress,
    getPrivateFactoryFee,
  };
};
