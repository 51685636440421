import { useApi } from 'api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardBig from './cards/cardBig/CardBig';
import CardSmall from './cards/cardSmall/CardSmall';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import styles from './styles.module.scss';
import '../HeroBannerCarousel/hero.css'

const TrendingListings = () => {
  const { t } = useTranslation('common');

  const [trendingListings, setTrendingListings] = useState([]);
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const { fetchTokens } = useApi();

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  useEffect(() => {
    const fetchItems = async () => {
      const tokens = await fetchTokens(
        0,
        5,
        'single',
        [],
        null,
        'viewed',
        [],
        ''
      );
      setTrendingListings(tokens.data.tokens);
    };
    fetchItems();
  }, []);

  const translateIndicators =
    windowDimension.winWidth > 2000
      ? 'translate(270px, 10px)'
      : 'translate(220px, 10px)';

  
  return (
    <div className={styles.wrapper}>
      <h1>{t('landing.trendingListings')}</h1>
      <div className={styles.carouselWrapper}>
        {trendingListings && (
          <Carousel
            autoPlay
            showThumbs={false}
            interval={20000000}
            infiniteLoop
            showStatus={false}
            showArrows={false}
            onClickItem={() => {}}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = {
                borderRadius: '10px',
                color: 'transparent',
                cursor: 'pointer',
                display: 'inline-block',
                marginLeft: '20px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                transform: translateIndicators,
                width: '7%',
                padding: '2px 0',
              };

              const style = isSelected
                ? {
                    ...defStyle,
                    border: '2px solid #FFFFFF',
                    backgroundColor: 'white',
                  }
                : {
                    ...defStyle,
                    border: '1px solid rgba(255, 255, 255, 0)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            <CardBig item={trendingListings?.[0]} key={0} />
            <CardBig item={trendingListings?.[1]} key={1} />
            <CardBig item={trendingListings?.[2]} key={2} />
            <CardBig item={trendingListings?.[3]} key={3} />
            <CardBig item={trendingListings?.[4]} key={4} />
          </Carousel>
        )}
      </div>
      <div className={styles.smallCardsWrapper}>
        {trendingListings.slice(1).map((item, i) => (
          <CardSmall key={item.tokenURI + '-' + i} item={item} />
        ))}
      </div>
    </div>
  );
};

export default TrendingListings;
