import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import HeaderActions from 'actions/header.actions';
import Header from 'components/header';

import man from 'assets/imgs/404_01.png';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const NotFound = ({ title, description, isInternal = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(false));
  }, []);

  return (
    <div className={styles.container}>
      {!isInternal && <Header />}
      <div className={styles.body}>
        <div className={styles.main}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subContainer}>
            <div className={styles.ooops}>{t('notFound.ooops')}</div>
            <div className={styles.subtitle}>{t(description)}</div>
          </div>
          <Link to="/" className={styles.button}>
            {t('buttons.backHome')}
          </Link>
        </div>
        <img src={man} alt="man" className={styles.man} />
      </div>
    </div>
  );
};

export default NotFound;
