import * as React from "react"

const IconMyNFT = (props) => (
  <svg
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.25 1A.75.75 0 0 1 1 .25h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75H1A.75.75 0 0 1 .25 7V1Zm1.5.75v4.5h4.5v-4.5h-4.5ZM.25 10A.75.75 0 0 1 1 9.25h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75H1A.75.75 0 0 1 .25 16v-6Zm1.5.75v4.5h4.5v-4.5h-4.5ZM9.25 1A.75.75 0 0 1 10 .25h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75h-6A.75.75 0 0 1 9.25 7V1Zm1.5.75v4.5h4.5v-4.5h-4.5ZM9.25 10a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-6Zm1.5.75v4.5h4.5v-4.5h-4.5Z"
      fill="#000"
    />
  </svg>
)

export default IconMyNFT