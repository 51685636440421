import nahmiiChainId from './nahmiiChainId';

export const NETWORK_LABEL = {
  [nahmiiChainId.MAINNET]: 'Nahmii Mainnet',
  [nahmiiChainId.TESTNET]: 'Nahmii Testnet',
};
export const Contracts = {
  auction: window.__RUNTIME_CONFIG__.REACT_APP_AUCTION, // Nahmii Auction Proxy
  sales: window.__RUNTIME_CONFIG__.REACT_APP_SALES, //Marketplace Proxy
  bundleSales: '', //BundleMarketplace Proxy
  factory: window.__RUNTIME_CONFIG__.REACT_APP_FACTORY, //NahmiiNFTFactory
  privateFactory: window.__RUNTIME_CONFIG__.REACT_APP_PRIVATE_FACTORY, //NahmiiNFTFactoryPrivate
  artFactory: window.__RUNTIME_CONFIG__.REACT_APP_ART_FACTORY, //NahmiiArtFactory
  privateArtFactory: window.__RUNTIME_CONFIG__.REACT_APP_PRIVATE_ART_FACTORY, //NahmiiArtFactoryPrivate
  registryAddress: window.__RUNTIME_CONFIG__.REACT_APP_REGISTORY_ADDRESS, //NahmiiAddressRegistry
  registryToken: window.__RUNTIME_CONFIG__.REACT_APP_REGISTORY_TOKEN, //NahmiiTokenRegistry
};
