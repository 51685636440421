import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const AboutAccordion = ({ defaultExpanded, title, description }) => {
  const [active, setActive] = useState(defaultExpanded);

  return (
    <div>
      <Accordion
        square
        disableGutters
        defaultExpanded={defaultExpanded}
        sx={{ boxShadow: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ position: 'relative' }}
          onClick={() => setActive(!active)}
        >
          <ArrowDropDownIcon
            style={{
              transition: '0.2s',
              transform: ` ${active ? 'rotate(180deg) scale(2)' : 'scale(2)'} `,
              color: 'rgba(0, 0, 0, 0.7)',
              position: 'relative',
              top: '8px',
              left: '-16px',
            }}
          />
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '30px',
              color: 'rgba(0, 0, 0, 0.7)',
              position: 'relative',
              left: '-5px',
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '26px',
              color: 'rgba(0, 0, 0, 0.7)',
              marginLeft: '-14px',
            }}
          >
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AboutAccordion;
