import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { SortByOptions } from 'constants/filter.constants';
import FilterActions from 'actions/filter.actions';

import './styles.css';
import { useTranslation } from 'react-i18next';

// import AllIcon from '../../../../assets/icons/all.png';
// import PriceIcon from '../../../../assets/icons/price.png';
// import SortIcon from '../../../../assets/icons/sort.png';
// import TagIcon from '../../../../assets/icons/tag.png';
// import CollectionsIcon from '../../../../assets/icons/collections.png';

const NewExploreFilterHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { sortBy } = useSelector(state => state.Filter);

  const handleSortByChange = e => {
    const newSortBy = e.target.value;
    dispatch(FilterActions.updateSortByFilter(newSortBy));
  };

  return (
    <div className="filterHeaderRoot">
      {/* <div className="filterHeaderLeft">
        <div className="filterOption">
          <img src={AllIcon} /> <span>{t('filterHeader.all')}</span>
        </div>
        <div className="filterOption">
          <img src={CollectionsIcon} />
          <span>{t('generic.collections')}</span>
        </div>
        <div className="filterOption">
          <img src={PriceIcon} /> <span>{t('filterHeader.priceRange')}</span>
        </div>
        <div className="filterOption">
          <img src={TagIcon} /> <span>{t('filterHeader.tag')}</span>
        </div>
        <div className="reset">Reset</div>
      </div> */}
      <div className="filterSelectGroup">
        <FormControl className="filterHeaderFormControl">
          <div className="selectLabel">{t('filterHeader.sort')}</div>
          <Select
            value={sortBy}
            onChange={handleSortByChange}
            className="selectBox"
            classes={{
              select: 'selectInner',
              selectMenu: 'selectMenu',
              icon: 'selectIcon',
            }}
            MenuProps={{
              classes: {
                paper: 'menuPropsPaper',
                list: 'menuItemList',
              },
            }}
            labelId="select-native"
            id="select-native"
          >
            {SortByOptions.map((option, idx) => {
              return (
                <MenuItem
                  value={option.id}
                  key={idx}
                  className="menuItem"
                  classes={{ selected: 'menuItemSelected ' }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default NewExploreFilterHeader;
