import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderActions from 'actions/header.actions';

import styles from './styles.module.scss';

const bridgeUrl = window.__RUNTIME_CONFIG__.REACT_APP_BRIDGE_URL;

const NotificationBanner = () => {
  const dispatch = useDispatch();
  let isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);

  const handleClick = () => {
    const newWindow = window.open(bridgeUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleClose = () => {
    dispatch(HeaderActions.toggleBanner(false));
  };

  return (
    <div
      className={styles.container}
      style={isBannerShown ? { display: 'flex' } : { display: 'none' }}
    >
      <div className={styles.wrapper}>
        <div>
          Visit the{' '}
          <span className={styles.bridge} onClick={handleClick}>
            Nahmii bridge
          </span>{' '}
          to deposit funds on Nahmii and use Kiwii
        </div>
        <div className={styles.cancel} onClick={handleClose}>
          x
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
