import React from 'react';
import styles from '../../../pages/NFTItem/styles.module.scss';
import Panel from 'components/Panel';
import { VerticalSplit as VerticalSplitIcon } from '@material-ui/icons';
import webIcon from '../../../assets/svgs/web.svg';
import twitterIcon from '../../../assets/svgs/twitter.svg';
import mediumIcon from '../../../assets/svgs/medium.svg';
import discordIcon from '../../../assets/svgs/discord.svg';
import telegramIcon from '../../../assets/svgs/telegram.svg';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const RenderAboutPanel = ({ collectionLoading, collection }) => {
  const { t } = useTranslation('common');
  return (
    <Panel
      icon={VerticalSplitIcon}
      title={
        <div className={styles.panelTitle}>
          {t('generic.about')}&nbsp;
          {collectionLoading ? (
            <Skeleton width={80} height={20} />
          ) : (
            collection?.collectionName || collection?.name
          )}
        </div>
      }
    >
      <div className={styles.panelBody}>
        <div className={styles.collectionDescription}>
          {collection?.description || t('generic.unverifiedCollection')}
        </div>

        <div className={styles.socialLinks}>
          {collection?.siteUrl?.length > 0 && (
            <a
              href={collection?.siteUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={webIcon} />
            </a>
          )}
          {collection?.twitterHandle?.length > 0 && (
            <a
              href={collection?.twitterHandle}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={twitterIcon} />
            </a>
          )}
          {collection?.mediumHandle?.length > 0 && (
            <a
              href={collection?.mediumHandle}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={mediumIcon} />
            </a>
          )}
          {collection?.telegram?.length > 0 && (
            <a
              href={collection?.telegram}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={telegramIcon} />
            </a>
          )}
          {collection?.discord?.length > 0 && (
            <a
              href={collection?.discord}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <img src={discordIcon} />
            </a>
          )}
        </div>
      </div>
    </Panel>
  );
};

export default RenderAboutPanel;
