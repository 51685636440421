import React from 'react';

import styles from './styles.module.scss';

import kiwii from 'assets/imgs/kiwii-story1.png';

const OurStory = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.media}>
            <img src={kiwii} alt="kiwii image" />
          </div>
          <div className={styles.description}>
            <h1 className={styles.title}>Our Story</h1>
            <p>
              As one of the first products launched out of SkunkDAO, Nahmii’s
              Web3 startup incubator, Kiwii enables users to engage with
              Nahmii’s ecosystem like never before.
            </p>
            <p>Key benefits of the Kiwii Marketplace include:</p>
            <p>
              <span className={styles.description_title}>
                Easy Connectivity
              </span>
              : Nahmii users can connect their MetaMask to Kiwii with a single
              click and start minting and trading NFTs immediately
            </p>
            <p>
              <span className={styles.description_title}>Lightning Fast</span>:
              Since Kiwii runs on the Nahmii Network, transactions are confirmed
              instantly
            </p>
            <p>
              <span className={styles.description_title}>
                Low Transaction Fees
              </span>
              : Kiwii’s fees are low, allowing users to create and trade many
              NFTs without prohibitively high network fees
            </p>
            <p>
              <span className={styles.description_title}>
                Low Platform Fees
              </span>
              : Trade NFTs via auction or direct offer on Kiwii, with only a
              0.7% platform fee versus platforms like OpenSea, which charges
              2.5% for each transaction
            </p>
            <p>
              Kiwii’s benefits extend far beyond profit-driven NFT markets. More
              specifically, Kiwii’s user-friendly platform demonstrates what you
              can do with Ethereum’s composability, but without banking on your
              NFTs just being speculative assets. If you don’t want to spend
              hundreds of dollars’ worth of ETH each time you mint or trade an
              NFT, you can enjoy and explore new forms of creative expression
              and value creation.
            </p>
            <p>
              Many team members working on SkunkDAO have also worked directly on
              Nahmii’s V1 and V2 protocols. Since 2017, they have racked up
              years of experience building innovative web3 products such as DeFi
              exchanges, NFT metaverses and minting stations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
