import styles from '../../../pages/NFTItem/styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { formatNumber } from '../../../utils';
import cx from 'classnames';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const renderViews = views => {
  return (
    <div className={styles.itemViews}>
      <FontAwesomeIcon icon={faEye} color="#A2A2AD" />
      &nbsp;
      {isNaN(views) ? (
        <Skeleton width={80} height={20} />
      ) : (
        `${formatNumber(views)} view${views !== 1 ? 's' : ''}`
      )}
    </div>
  );
};

const renderLikes = (
  liked,
  isLike,
  likeFetching,
  showLikeUsers,
  toggleFavorite
) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={cx(
        styles.itemViews,
        styles.clickable,
        isLike && styles.liking
      )}
    >
      {isNaN(liked) || likeFetching ? (
        <Skeleton width={80} height={20} />
      ) : (
        <>
          {isLike ? (
            <FavoriteIcon className={styles.favIcon} onClick={toggleFavorite} />
          ) : (
            <FavoriteBorderIcon
              className={styles.favIcon}
              onClick={toggleFavorite}
            />
          )}
          &nbsp;
          <span onClick={liked ? showLikeUsers : null}>
            {formatNumber(liked || 0)}{' '}
            {liked > 1 ? t('generic.favorites') : t('generic.favorite')}
          </span>
        </>
      )}
    </div>
  );
};

const RenderStats = ({
  views,
  liked,
  isLike,
  likeFetching,
  showLikeUsers,
  toggleFavorite,
}) => {
  return (
    <>
      {renderViews(views)}
      {renderLikes(liked, isLike, likeFetching, showLikeUsers, toggleFavorite)}
    </>
  );
};

export default RenderStats;
