import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WebIcon from '../../assets/icons/Website.svg';
import DiscordIcon from '../../assets/icons/Discord.svg';
import InstagramIcon from '../../assets/icons/Instagram.svg';
import MediumIcon from '../../assets/icons/Medium.svg';
import TwitterIcon from '../../assets/icons/Twitter.svg';

import styles from './styles.module.scss';
import cx from 'classnames';

export default function SocialBar({ socials, setActive, active }) {
  const openPage = url => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  return (
    <div className={styles.wrap}>
      <div
        className={cx(styles.box, !socials?.website && styles.disabled)}
        onClick={() => openPage(socials?.website)}
      >
        <img className={styles.icon} src={WebIcon} />
      </div>
      <div
        className={cx(styles.box, !socials?.discord && styles.disabled)}
        onClick={() => openPage(socials?.discord)}
      >
        <img className={styles.icon} src={DiscordIcon} />
      </div>
      <div
        className={cx(styles.box, !socials?.twitter && styles.disabled)}
        onClick={() => openPage(socials?.twitter)}
      >
        <img className={styles.icon} src={TwitterIcon} />
      </div>
      <div
        className={cx(styles.box, !socials?.instagram && styles.disabled)}
        onClick={() => openPage(socials?.instagram)}
      >
        <img className={styles.icon} src={InstagramIcon} />
      </div>
      <div
        className={cx(styles.box, !socials?.medium && styles.disabled)}
        onClick={() => openPage(socials?.medium)}
      >
        <img className={styles.icon} src={MediumIcon} />
      </div>
      <div className={cx(styles.box, styles.btn_background)} onClick={() => setActive(!active)}>
        <MoreVertIcon htmlColor="white" />
      </div>
    </div>
  );
}
