import * as React from "react"

const IconFavorite = (props) => (
  <svg
    width={20}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.759 1.75a3.724 3.724 0 0 0-2.457 6.524l6.483 6.483 6.482-6.483.03-.028a3.724 3.724 0 1 0-5.838-4.397.75.75 0 0 1-1.349 0A3.724 3.724 0 0 0 5.76 1.75ZM.535 5.474a5.224 5.224 0 0 1 9.25-3.328 5.224 5.224 0 1 1 7.53 7.203l-6.525 6.524a1.421 1.421 0 0 1-2.01 0l.53-.53-.53.53L2.255 9.35a5.212 5.212 0 0 1-1.72-3.875Z"
      fill="#000"
    />
  </svg>
)

export default IconFavorite
