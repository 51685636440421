import nahmiiChainId from '../constants/nahmiiChainId';

import iconEth from 'assets/imgs/eth.png';
import iconWeth from 'assets/imgs/weth.png';

// eslint-disable-next-line no-undef
const isMainnet = window.__RUNTIME_CONFIG__.REACT_APP_ENV === 'MAINNET';

const Tokens = {
  [nahmiiChainId.MAINNET]: [
    // {
    //   address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    //   name: 'Ethereum',
    //   symbol: 'ETH',
    //   decimals: 18,
    //   icon: icon,
    // },
  ],
  [nahmiiChainId.TESTNET]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      icon: iconEth,
    },
    {
      address: '0x4200000000000000000000000000000000000006',
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      decimals: 18,
      icon: iconWeth,
    },
  ],
};

export default function useTokens() {
  const chain = isMainnet ? nahmiiChainId.MAINNET : nahmiiChainId.TESTNET;

  const tokens = Tokens[chain];

  const getTokenByAddress = addr => {
    const address = !addr || addr === 'eth' ? '' : addr;
    return (tokens || []).find(
      tk => tk.address.toLowerCase() === address.toLowerCase()
    );
  };

  return { getTokenByAddress, tokens };
}
