import React from 'react';
import BlogCard from './BlogCard';

import styles from './styles.module.scss';

import blogCard1 from 'assets/imgs/blog1.jpeg';
import blogCard2 from 'assets/imgs/blog2.jpeg';
import blogCard3 from 'assets/imgs/blog3.jpeg';
import { MainButton } from 'components/MainButton';

const blogCardData = [
  {
    id: 1,
    title: 'Introducing SkunkDAO: A New Startup Incubator...',
    description:
      'SkunkDAO provides funding and support for early-stage projects building applications on Nahmii',
    image: blogCard1,
    url: 'https://medium.com/skunkdao/introducing-skunkdao-a-new-startup-incubator-and-growth-accelerator-for-the-nahmii-ecosystem-58ecbdc89033'
  },
  {
    id: 2,
    title: 'Introducing Kiwii: Nahmii’s NFT Platform',
    description:
      'Kiwii is an NFT platform powered by Nahmii which offers high throughput, low transaction costs, and full user flexibility.',
    image: blogCard2,
    url: 'https://medium.com/skunkdao/introducing-kiwii-nahmiis-nft-platform-d14a344a6e4'
  },
  {
    id: 3,
    title: 'Announcing Nahmii’s $100 Million Per-project ...',
    description:
      'The first 50 projects to launch on Nahmii will receive up to $100M in fee rebates each.',
    image: blogCard3,
    url: 'https://blog.nahmii.io/announcing-nahmiis-100-million-per-project-development-incentive-program-ab452c5f3461'
  },
];
const handleClick = () => {
  const newWindow = window.open(
    'https://medium.com/skunkdao',
    '_blank',
    'noopener,noreferrer'
  );
  if (newWindow) newWindow.opener = null;
};

const Blog = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.name}>Blog</h2>
      <div className={styles.cardsWrapper}>
        {blogCardData.map(({ id, title, description, image, url }) => (
          <BlogCard
            key={id}
            title={title}
            description={description}
            image={image}
            url={url}
          />
        ))}
      </div>
      <MainButton small onClick={handleClick}>
        {/* add later Link to Posts page*/}
        View all Posts
      </MainButton>
    </div>
  );
};

export default Blog;
