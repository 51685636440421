import axios from 'axios';
import useGetOwner from 'hooks/useGetOwner';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

const CardBig = ({ item }) => {
  const [info, setInfo] = useState(null);
  const [owner, setOwner] = useState('');
  const { getOwner } = useGetOwner();
  
  const getTokenURI = async tokenURI => {
    try {
      const { data } = await axios.get(tokenURI);
      setInfo(data);
    } catch {
      setInfo(null);
    }
  };

  useEffect(() => {
    if (typeof item?.tokenURI !== 'undefined') {
      getTokenURI(item?.tokenURI);
    }

    const getItemOwner = async () => {
      const addressOrName = await getOwner(
        item?.tokenID,
        item?.contractAddress
      );
      setOwner(addressOrName);
    };
    getItemOwner();
  }, [item?.tokenID, item?.contractAddress]);

  return (
    <Link
      to={`/explore/${item?.contractAddress}/${item?.tokenID}`}
      className={styles.link}
    >
      <div className={styles.cardWrapper}>
        <div className={styles.textContent}>
          <div className={styles.textContentTop}>
            <h3 className={styles.title}>{info?.properties?.collection}</h3>
            <h2 className={styles.itemName}>{item?.name}</h2>
            <h3 className={styles.title}>
              by <span className={styles.owner}>{owner}</span>
            </h3>
          </div>
          <div className={styles.description}>{info?.description}</div>
        </div>
        <div className={styles.imageWrapper}>
          <img src={item?.imageURL} alt={item?.name} />
        </div>
      </div>
    </Link>
  );
};

export default CardBig;
