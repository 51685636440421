import * as React from "react"

const IconOffers = (props) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.785.25a.75.75 0 0 1 .583.279l1.895 2.344 2.911-.783a.75.75 0 0 1 .944.686l.155 3.01 2.815 1.078a.75.75 0 0 1 .36 1.11L17.806 10.5l1.644 2.527a.75.75 0 0 1-.36 1.11l-2.816 1.077-.155 3.01a.75.75 0 0 1-.944.686l-2.91-.783-1.896 2.345a.75.75 0 0 1-1.166 0l-1.895-2.345-2.911.783a.75.75 0 0 1-.944-.686l-.155-3.01-2.815-1.078a.75.75 0 0 1-.36-1.11L1.764 10.5.121 7.973a.75.75 0 0 1 .36-1.11l2.816-1.077.155-3.01a.75.75 0 0 1 .944-.686l2.91.783L9.203.529A.75.75 0 0 1 9.785.25Zm0 1.943L8.167 4.195a.75.75 0 0 1-.778.253L4.902 3.78 4.77 6.35a.75.75 0 0 1-.481.662l-2.404.921 1.404 2.158a.75.75 0 0 1 0 .818l-1.404 2.158 2.404.92a.75.75 0 0 1 .48.662l.133 2.572 2.487-.669a.75.75 0 0 1 .778.253l1.618 2.002 1.618-2.002a.75.75 0 0 1 .778-.253l2.487.669.132-2.572a.75.75 0 0 1 .481-.661l2.404-.921-1.404-2.158a.75.75 0 0 1 0-.818l1.404-2.158-2.404-.92a.75.75 0 0 1-.48-.662l-.133-2.571-2.486.668a.75.75 0 0 1-.779-.253L9.785 2.193Z"
      fill="#000"
    />
    <path
      d="M9.785 5.908a.5.5 0 0 1 .5.5v.781c.903.198 1.636.922 1.636 1.878a.5.5 0 0 1-1 0c0-.45-.434-.921-1.113-.932a.51.51 0 0 1-.046 0c-.68.01-1.113.483-1.113.932 0 .45.437.924 1.12.931h.016c1.115 0 2.136.804 2.136 1.932 0 .956-.733 1.679-1.636 1.877v.784a.5.5 0 1 1-1 0v-.784c-.903-.198-1.636-.921-1.636-1.877a.5.5 0 1 1 1 0c0 .454.444.932 1.136.932s1.136-.478 1.136-.932c0-.45-.437-.924-1.12-.932h-.016c-1.115 0-2.136-.804-2.136-1.931 0-.956.733-1.68 1.636-1.878v-.78a.5.5 0 0 1 .5-.5Z"
      fill="#000"
    />
  </svg>
)

export default IconOffers
