import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import './styles.css';
import { ClipLoader } from 'react-spinners';
import Select from 'react-dropdown-select';
import Skeleton from 'react-loading-skeleton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import BootstrapTooltip from 'components/BootstrapTooltip';
import PriceInput from 'components/PriceInput';
import { formatNumber } from 'utils';
import useTokens from 'hooks/useTokens';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DateTimePicker from 'components/DateTimePicker';

const AuctionModal = ({
  visible,
  onClose,
  onStartAuction,
  auction,
  auctionStarted,
  confirming,
  approveContract,
  contractApproving,
  contractApproved,
}) => {
  const { tokens } = useTokens();
  const { t } = useTranslation('common');

  const [now, setNow] = useState(new Date());
  const [reservePrice, setReservePrice] = useState('');
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() + 2 * 60 * 1000)
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [focused, setFocused] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tokenPrice, setTokenPrice] = useState();
  const [tokenPriceInterval, setTokenPriceInterval] = useState();
  const [inputError, setInputError] = useState(null);

  const priceData = useSelector(state => state.Price.price);

  useEffect(() => {
    setInterval(() => setNow(new Date()), 1000);
  }, []);

  useEffect(() => {
    if (tokens?.length) {
      setOptions(tokens.map((t, index) => ({ data: t, id: index })));
    }
  }, [tokens]);

  useEffect(() => {
    setReservePrice(auction?.reservePrice || '');
    setStartTime(
      auction?.startTime
        ? new Date(auction.startTime * 1000)
        : new Date(new Date().getTime() + 2 * 60 * 1000)
    );
    setEndTime(
      auction?.endTime
        ? new Date(auction.endTime * 1000)
        : new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    );
  }, [visible, auction]);

  useEffect(() => {
    if (visible && tokens?.length) {
      setSelected([auction ? auction.token : tokens[0]]);
    }
  }, [visible, auction]);
  const getTokenPrice = () => {
    if (tokenPriceInterval) clearInterval(tokenPriceInterval);
    const func = async () => {
      try {
        setTokenPrice(priceData);
      } catch {
        setTokenPrice(null);
      }
    };
    func();
    setTokenPriceInterval(setInterval(func, 60 * 1000));
  };

  useEffect(() => {
    if (selected.length === 0) return;

    getTokenPrice();
  }, [selected]);

  const validateInput = (() => {
    if (reservePrice.length === 0 || parseFloat(reservePrice) == 0)
      return false;
    if (!auctionStarted && startTime.getTime() < now.getTime()) return false;
    return (
      endTime.getTime() >= now.getTime() + 1000 * 60 * 5 &&
      endTime.getTime() >= startTime.getTime() + 1000 * 60 * 5
    );
  })();

  return (
    <Modal
      visible={visible}
      title={auction ? t('buttons.updateAuction') : t('buttons.startAuction')}
      onClose={onClose}
      submitDisabled={
        contractApproving ||
        confirming ||
        (contractApproved && !validateInput) ||
        inputError
      }
      submitLabel={
        contractApproved ? (
          confirming ? (
            <ClipLoader color="#FFF" size={16} />
          ) : auction ? (
            t('buttons.updateAuction')
          ) : (
            t('buttons.startAuction')
          )
        ) : contractApproving ? (
          t('buttons.approvingItem')
        ) : (
          t('buttons.approveItem')
        )
      }
      onSubmit={() =>
        contractApproved
          ? !confirming && validateInput
            ? onStartAuction(
                selected[0],
                reservePrice,
                startTime,
                endTime,
                reservePrice
              )
            : null
          : approveContract()
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>
          {t('auctionModal.reservePrice')}&nbsp;
          <BootstrapTooltip
            title={t('auctionModal.reserveDesc')}
            placement="top"
          >
            <HelpOutlineIcon />
          </BootstrapTooltip>
        </div>
        <div className={cx(styles.formInputCont, focused && styles.focused)}>
          {/*TODO: fix this gross selector */}
          <Select
            options={options}
            disabled={auction}
            autoFocus
            values={selected}
            onChange={tk => {
              if (tk.length > 0 && tk[0]?.data) setSelected([tk[0]?.data]);
            }}
            className={styles.select}
            valueField="data"
            placeholder=""
            itemRenderer={({ item, itemIndex, methods }) => (
              <div
                key={itemIndex}
                className={styles.token}
                onClick={() => {
                  methods.clearAll();
                  methods.addItem(item);
                }}
              >
                <img src={item?.data.icon} className={styles.tokenIcon} />
                <div className={styles.tokenSymbol}>{item.data.symbol}</div>
              </div>
            )}
            contentRenderer={({ props: { options } }) => {
              const content =
                selected.length > 0
                  ? selected[0]
                  : options.length > 0
                  ? options[0].data
                  : {};
              return (
                <div className={styles.selectedToken}>
                  <img src={content?.icon} className={styles.tokenIcon} />
                  <div className={styles.tokenSymbol}>{content?.symbol}</div>
                </div>
              );
            }}
          />
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            value={'' + reservePrice}
            decimals={selected[0]?.decimals || 0}
            onChange={setReservePrice}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={contractApproving || confirming}
            onInputError={err => setInputError(err)}
          />
          <div
            data-testid="auction-modal-usd-price"
            className={styles.usdPrice}
          >
            {!isNaN(tokenPrice) && tokenPrice !== null ? (
              `$${formatNumber(
                ((parseFloat(reservePrice) || 0) * tokenPrice).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div>
        </div>
        <InputError text={inputError} />
      </div>
      <div className={styles.formGroupDates}>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>{t('auctionModal.startTime')}</div>
          <div className={styles.formInputCont}>
            <DateTimePicker
              value={startTime}
              onChange={setStartTime}
              data-testid="auction-start-time"
              disabled={auctionStarted || contractApproving || confirming}
              isValidDate={now.getTime() - 1000 * 60 * 60 * 24}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>{t('auctionModal.endTime')}</div>
          <div className={styles.formInputCont}>
          <DateTimePicker
              value={endTime}
              onChange={setEndTime}
              data-testid="auction-end-time"
              disabled={contractApproving || confirming}
              isValidDate={startTime.getTime() - 1000 * 60 * 60 * 23}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuctionModal;
