import * as React from 'react';
import Button from '@material-ui/core/Button';

import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from './Dropdown';

import './styles.css';

const MultiDropDown = ({menu, setFilterAttributesBy}) => {
  const category = Object.keys(menu);
  const [queryObject, setQueryObj] = React.useState({})

  const handleClick = (ele, item) => {
    setQueryObj(prev => ({
      ...prev,
      [`${ele}`]: item
    }))
  };

  React.useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      setFilterAttributesBy(queryObject);
    }
  }, [queryObject])

  const renderMenu = () => {
    const items = category.map((ele) => {
        const subMenu = menu[`${ele}`];
        return (
            <DropdownNestedMenuItem
              key={ele}
              label={ele}
              rightIcon={<ArrowRight />}
              menu={subMenu.map((item) => (
                <DropdownMenuItem key={item} onClick={() => handleClick(ele, item)}>
                  {item}
                </DropdownMenuItem>
              ))}
            />
        )
    })
    return items;
  }

  return (
    <Dropdown
      trigger={
        <div className="formGroup">
          <div className="label">Filter</div>
          <Button
            variant="outlined"
            pill
            style={{
              borderRadius: 10,
              padding: "9px 10px 9px 12px",
              fontSize: "15px",
              marginTop: "12px",
              fontWeight: "500",
              textTransform: "capitalize"
            }}
            endIcon={<ArrowDropDownIcon style={{marginLeft: "30px"}} />}
            
          >
            All Categories
          </Button>
        </div>
      }
      menu={renderMenu()}
    />
  );
};

export default MultiDropDown;