import React, { useEffect, useState } from 'react';
import { useApi } from 'api';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import NFTCard from 'components/NFTCard';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useDispatch } from 'react-redux';
import CollectionsActions from 'actions/collections.actions';

const NewestListing = () => {
  const { t } = useTranslation('common');
  const [newListings, setNewListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { fetchTokens, fetchCollections } = useApi();

  const dispatch = useDispatch();

  const updateCollections = async () => {
    try {
      dispatch(CollectionsActions.fetchStart());
      const res = await fetchCollections();
      if (res.status === 'success') {
        const verified = [];
        const unverified = [];
        res.data.map(item => {
          if (item.isVerified) verified.push(item);
          else unverified.push(item);
        });
        dispatch(CollectionsActions.fetchSuccess([...verified, ...unverified]));
      }
    } catch {
      dispatch(CollectionsActions.fetchFailed());
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      setIsLoading(true);
      const tokens = await fetchTokens(
        0,
        20,
        'single',
        [],
        null,
        'createdAt',
        [],
        ''
      );
      setNewListings(tokens.data.tokens);
      setIsLoading(false);
    };
    fetchItems();
    updateCollections();
  }, []);

  // Desktop Data
  const banner1 = newListings.slice(0, 4);
  const banner2 = newListings.slice(4, 8);
  const banner3 = newListings.slice(8, 12);
  const banner4 = newListings.slice(12, 16);
  const banner5 = newListings.slice(16);

  //Tablet Data
  const banner1Tab = newListings.slice(0, 2);
  const banner2Tab = newListings.slice(2, 4);
  const banner3Tab = newListings.slice(4, 6);
  const banner4Tab = newListings.slice(6, 8);
  const banner5Tab = newListings.slice(8, 10);

  //Mobile Data
  const banner1Mobile = newListings.slice(0, 1);
  const banner2Mobile = newListings.slice(1, 2);
  const banner3Mobile = newListings.slice(2, 3);
  const banner4Mobile = newListings.slice(3, 4);
  const banner5Mobile = newListings.slice(4, 5);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentMobile}>
        <h2 className={styles.name}>{t('pages.landing.newestListing')}</h2>

        {newListings.length && (
          <Carousel
            className={styles.carousel}
            autoPlay
            showThumbs={false}
            interval={2000}
            infiniteLoop
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            renderArrowNext={clickHandler => {
              return (
                <div className={styles.arrow} onClick={clickHandler}>
                  <NavigateNextIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
            renderArrowPrev={clickHandler => {
              return (
                <div className={styles.arrowLeft} onClick={clickHandler}>
                  <NavigateBeforeIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
          >
            <div className={styles.cardsWrapper}>
              {banner1Mobile.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner2Mobile.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner3Mobile.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner4Mobile.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner5Mobile.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
          </Carousel>
        )}
      </div>
      <div className={styles.contentTab}>
        <h2 className={styles.name}>{t('pages.landing.newestListing')}</h2>

        {newListings.length && (
          <Carousel
            className={styles.carousel}
            autoPlay
            showThumbs={false}
            interval={2000}
            infiniteLoop
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            renderArrowNext={clickHandler => {
              return (
                <div className={styles.arrow} onClick={clickHandler}>
                  <NavigateNextIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
            renderArrowPrev={clickHandler => {
              return (
                <div className={styles.arrowLeft} onClick={clickHandler}>
                  <NavigateBeforeIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
          >
            <div className={styles.cardsWrapper}>
              {banner1Tab.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner2Tab.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner3Tab.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner4Tab.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner5Tab.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
          </Carousel>
        )}
      </div>
      <div className={styles.content}>
        <h2 className={styles.name}>{t('pages.landing.newestListing')}</h2>

        {newListings.length && (
          <Carousel
            className={styles.carousel}
            autoPlay
            showThumbs={false}
            interval={200000000}
            infiniteLoop
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            renderArrowNext={clickHandler => {
              return (
                <div className={styles.arrow} onClick={clickHandler}>
                  <NavigateNextIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
            renderArrowPrev={clickHandler => {
              return (
                <div className={styles.arrowLeft} onClick={clickHandler}>
                  <NavigateBeforeIcon
                    style={{
                      transform: 'scale(2)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }}
          >
            <div className={styles.cardsWrapper}>
              {banner1.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner2.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner3.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner4.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
            <div className={styles.cardsWrapper}>
              {banner5.map((item, index) => (
                <NFTCard
                  key={index}
                  item={item}
                  loading={isLoading}
                  newestListing={true}
                />
              ))}
            </div>
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default NewestListing;
