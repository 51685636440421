import React from 'react';

import styles from './styles.module.scss';

import twitter from 'assets/imgs/about-twitter.png';
import discord from 'assets/imgs/about-discord.png';

const LearnMoreBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <h2 className={styles.title}>Interested in learning more?</h2>
        <div className={styles.description}> Join socials</div>
        <div className={styles.socials}>
          <div className={styles.socialItem}>
            <img src={twitter} alt="twitter icon" className={styles.image} />
            {/* add proper redirection*/}
            <a href="https://twitter.com/KiwiiDAO" target="_blank" rel="noreferrer">
              <span className={styles.itemDescription}>Twitter</span>
            </a>
          </div>
          <div className={styles.socialItem}>
            <img src={discord} alt="discord icon" className={styles.image} />
            {/* add proper redirection*/}
            <a href="https://discord.gg/mc7Qd39rew" target="_blank" rel="noreferrer">
              <span className={styles.itemDescription}>Discord</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreBanner;
