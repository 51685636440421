import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import { useDropzone } from 'react-dropzone';
import nahmiiChainId from '../../constants/nahmiiChainId';
import Select from 'react-dropdown-select';

import CloseIcon from '@material-ui/icons/Close';
import { Stepper, Step, StepLabel } from '@material-ui/core';

import HeaderActions from 'actions/header.actions';
import Header from 'components/header';
import PriceInput from 'components/PriceInput';
import { formatError } from 'utils';
import showToast from 'utils/toast';
import WalletUtils from 'utils/wallet';
import useContract from 'utils/sc.interaction';
import { useApi } from 'api';
import { useSalesContract, getSigner } from 'contracts';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { StyledSwitcher } from 'components/StyledSwitch';
import { MainButton } from 'components/MainButton';
import Footer from 'components/Footer/Footer';
import BootstrapTooltip from 'components/BootstrapTooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ADMIN_ADDRESSES } from 'constants/index';

const accept = ['image/*'];

const FEE_ABI = [
  {
    inputs: [],
    name: 'platformFee',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];

const SINGLE_NFT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'string', name: '_tokenUri', type: 'string' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
];

const MULTI_NFT_ABI = [
  {
    inputs: [
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_supply', type: 'uint256' },
      { internalType: 'string', name: '_uri', type: 'string' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
];

const PaintBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    apiUrl,
    fetchMintableCollections,
    getNonce,
    addUnlockableContent,
    getIsModerator,
  } = useApi();
  const { registerRoyalty } = useSalesContract();
  const { loadContract } = useContract();
  const { t } = useTranslation('common');

  const { account, chainId } = useWeb3React();

  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  const checkAccess = async () => {
    const isModerator = await getIsModerator(account);
    if (
      isModerator === false &&
      !ADMIN_ADDRESSES.includes(account?.toLowerCase())
    ) {
      history.replace('/404');
    }
  };

  const isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);

  const imageRef = useRef();

  const [selected, setSelected] = useState([]);
  const [collections, setCollections] = useState([]);
  const [nft, setNft] = useState();
  const [type, setType] = useState();
  const [collectionName, setCollectionName] = useState();
  const [image, setImage] = useState(null);
  const [fee, setFee] = useState(null);

  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [description, setDescription] = useState('');
  const [royalty, setRoyalty] = useState('');
  const [xtra, setXtra] = useState('');
  const [supply, setSupply] = useState(0);
  const [tags, setTags] = useState('');
  const [hasUnlockableContent, setHasUnlockableContent] = useState(false);
  const [unlockableContent, setUnlockableContent] = useState('');

  const [currentMintingStep, setCurrentMintingStep] = useState(0);
  const [isMinting, setIsMinting] = useState(false);

  const authToken = useSelector(state => state.ConnectWallet.authToken);

  const mintSteps = [
    t('paintBoard.mintStepUpload'),
    t('paintBoard.mintStepCreate'),
    t('paintBoard.mintStepConfirm'),
  ];

  const getFee = async () => {
    setFee(null);

    try {
      const contract = await loadContract(nft, FEE_ABI);
      const _fee = await contract.platformFee();
      setFee(parseFloat(_fee.toString()) / 10 ** 18);
    } catch {
      setFee(0);
    }
  };

  const getCollections = async () => {
    try {
      const { data } = await fetchMintableCollections(authToken);
      setCollections(data);
      if (data.length) {
        setSelected([data[0]]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (authToken) {
      getCollections();
    }
  }, [authToken]);

  useEffect(() => {
    if (!nft) return;

    getFee();
  }, [nft]);

  useEffect(() => {
    checkAccess();
    dispatch(HeaderActions.toggleSearchbar(true));
  }, []);

  useEffect(() => {}, [nft, fee]);

  const onDrop = useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept.join(', '),
    multiple: false,
    onDrop,
    maxSize: 15728640,
  });

  const removeImage = () => {
    setImage(null);
    if (imageRef.current) {
      imageRef.current.value = '';
    }
  };

  const imageToBase64 = () => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = err => {
        reject(err);
      };
    });
  };

  const validateMetadata = () => {
    return (
      name !== '' &&
      account !== '' &&
      image &&
      collectionName !== '' &&
      royalty != ''
    );
  };

  const resetMintingStatus = () => {
    setTimeout(() => {
      setIsMinting(false);
      setCurrentMintingStep(0);
    }, 1000);
  };

  const mintNFT = async () => {
    if (!account) {
      showToast('info', t('errors.connectToWalletFirst'));
      return;
    }

    if (
      chainId !== nahmiiChainId.MAINNET &&
      chainId !== nahmiiChainId.TESTNET
    ) {
      showToast('info', t('errors.connectToNetwork'));
      return;
    }

    const balance = await WalletUtils.checkBalance(account);

    if (balance < fee) {
      showToast('custom', t('errors.balanceLow', { fee: fee }));
      return;
    }

    // show stepper
    setIsMinting(true);
    if (!validateMetadata()) {
      resetMintingStatus();
      return;
    }

    let signature;
    let addr;

    if (hasUnlockableContent && unlockableContent.length > 0) {
      const { data: nonce } = await getNonce(account, authToken);
      try {
        const signer = await getSigner();
        const msg = `Validate user action with ID: ${nonce}`;
        signature = await signer.signMessage(msg);
        addr = ethers.utils.verifyMessage(msg, signature);
      } catch (err) {
        showToast('error', t('errors.signFailed'));
        resetMintingStatus();
        return;
      }
    }

    let formData = new FormData();
    const base64 = await imageToBase64();

    formData.append('image', base64);
    formData.append('name', name);
    formData.append('account', account);
    formData.append('description', description);
    formData.append('symbol', symbol);
    formData.append('xtra', xtra);
    formData.append('collectionName', collectionName);
    const _royalty = parseInt(royalty) * 100;
    formData.append('royalty', isNaN(_royalty) ? 0 : _royalty);
    try {
      let result = await axios({
        method: 'post',
        url: `${apiUrl}/ipfs/uploadImage2Server`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + authToken,
        },
      });

      const jsonHash = result.data.jsonHash;

      const contract = await loadContract(
        nft,
        type === 721 ? SINGLE_NFT_ABI : MULTI_NFT_ABI
      );
      try {
        const args =
          type === 721 ? [account, jsonHash] : [account, supply, jsonHash];

        let tx;
        const options = {
          value: ethers.utils.parseEther(`${parseFloat(fee)}`),
        };
        const gasEstimate = await contract.estimateGas.mint(...args, options);
        options.gasLimit = gasEstimate;
        tx = await contract.mint(...args, options);

        setCurrentMintingStep(1);

        setCurrentMintingStep(2);
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        const confirmedTnx = await tx.wait();

        setCurrentMintingStep(3);
        await sleep(5000);
        let mintedTkId;
        if (type === 721) {
          try {
            const evtCaught = confirmedTnx.events[0].topics;
            mintedTkId = BigNumber.from(evtCaught[3]);
          } catch {
            const evtCaught = confirmedTnx.logs[3].topics;
            mintedTkId = BigNumber.from(evtCaught[3]);
          }
        } else {
          mintedTkId = BigNumber.from(
            ethers.utils.hexDataSlice(confirmedTnx.logs[1].data, 0, 32)
          );
        }
        if (!isNaN(_royalty) && _royalty > 0) {
          try {
            const royaltyTx = await registerRoyalty(
              nft,
              mintedTkId.toNumber(),
              isNaN(_royalty) ? 0 : _royalty
            );
            await royaltyTx.wait();
          } catch (err) {
            console.log(err);
          }
        }
        // save unlockable content
        if (hasUnlockableContent && unlockableContent.length > 0) {
          await addUnlockableContent(
            nft,
            mintedTkId.toNumber(),
            unlockableContent,
            signature,
            addr,
            authToken
          );
        }

        showToast('success', t('messages.newNFTMinted'));
        removeImage();
        setName('');
        setSymbol('');
        setDescription('');
        setTimeout(() => {
          history.push(`/explore/${nft}/${mintedTkId.toNumber()}`);
        }, 1000 + Math.random() * 2000);
      } catch (error) {
        showToast('error', formatError(error.message));
      }
    } catch (error) {
      showToast('error', error.message);
    }
    resetMintingStatus();
  };

  return (
    <div
      className={styles.container}
      style={!isBannerShown ? { paddingTop: '0px' } : { paddingTop: '38px' }}
    >
      <Header />
      <div className={styles.body}>
        <h1 className={styles.title}> {t('paintBoard.createNew')}</h1>
        <div className={styles.content}>
          <div className={styles.columnLeft}>
            <div className={styles.board}>
              <div {...getRootProps({ className: styles.uploadCont })}>
                <input {...getInputProps()} ref={imageRef} />
                {image ? (
                  <>
                    <img
                      className={styles.image}
                      src={URL.createObjectURL(image)}
                    />
                    <div className={styles.overlay}>
                      <CloseIcon
                        className={styles.remove}
                        onClick={removeImage}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.uploadtitle}>
                      {t('generic.dropFiles')}&nbsp;
                      <span
                        className={styles.browse}
                        onClick={() => imageRef.current?.click()}
                      >
                        {t('generic.browse')}
                      </span>
                    </div>
                    <div className={styles.uploadsubtitle}>
                      JPG, PNG, BMP, GIF Max 15mb.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={styles.columnRight}>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                {t('generic.name')}
                &nbsp;
                <div>*</div>
              </p>
              <input
                className={styles.formInput}
                maxLength={40}
                placeholder={t('generic.namePlaceholder')}
                value={name}
                onChange={e => setName(e.target.value)}
                disabled={isMinting}
                data-testid="name-input"
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                {t('generic.symbol')}
                &nbsp;
                <BootstrapTooltip
                  title={t('generic.symbolTooltip')}
                  placement="top"
                >
                  <HelpOutlineIcon htmlColor="#00000080" />
                </BootstrapTooltip>
              </p>
              <input
                className={styles.formInput}
                maxLength={20}
                // placeholder={t('generic.symbolPlaceholder')}
                value={symbol}
                onChange={e => setSymbol(e.target.value)}
                disabled={isMinting}
                data-testid="symbol-input"
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>{t('generic.description')}</p>
              <textarea
                className={cx(styles.formInput, styles.longInput)}
                maxLength={150}
                placeholder={t('generic.descriptionPlaceholder')}
                value={description}
                onChange={e => setDescription(e.target.value)}
                disabled={isMinting}
                data-testid="description-input"
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                {t('generic.collection')}
                &nbsp;
                <div>*</div>
              </p>
              <Select
                options={collections}
                disabled={isMinting}
                values={selected}
                valueField="collectionName"
                onChange={([col]) => {
                  setSelected([col]);
                  setNft(col.erc721Address);
                  setType(col.type);
                  setCollectionName(col.collectionName);
                }}
                className={styles.select}
                data-testid="collection-input"
                itemRenderer={({ item, methods }) => (
                  <div
                    key={item.erc721Address}
                    className={styles.collection}
                    onClick={() => {
                      methods.clearAll();
                      methods.addItem(item);
                    }}
                  >
                    <img
                      src={`${infuraUrl}/${item.logoImageHash}`}
                      className={styles.collectionLogo}
                    />
                    <div className={styles.collectionName}>
                      {item.collectionName}
                    </div>
                  </div>
                )}
                contentRenderer={({ props: { values } }) =>
                  values.length > 0 ? (
                    <div className={styles.collection}>
                      <img
                        src={`${infuraUrl}/${values[0].logoImageHash}`}
                        className={styles.collectionLogo}
                      />
                      <div className={styles.collectionName}>
                        {values[0].collectionName}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.collection} />
                  )
                }
              />
            </div>

            {type === 1155 && (
              <div className={styles.formGroup}>
                <p className={styles.formLabel}>{t('generic.supply')}</p>
                <PriceInput
                  className={styles.formInput}
                  placeholder={t('generic.supply')}
                  decimals={0}
                  value={'' + supply}
                  onChange={setSupply}
                  disabled={isMinting}
                />
              </div>
            )}
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                {t('generic.royalty')} (%)&nbsp;
                <div>*</div>
              </p>
              <PriceInput
                className={styles.formInput}
                placeholder={'0'}
                decimals={2}
                value={royalty || ''}
                onChange={val =>
                  val[val.length - 1] === '.'
                    ? setRoyalty(val)
                    : setRoyalty(Math.min(100, +val))
                }
                disabled={isMinting}
                data-testid="royalty-input"
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>
                {t('paintBoard.ipRights')}{' '}
                <span>({t('generic.optional')})</span>&nbsp;
              </p>
              <input
                className={styles.formInput}
                // placeholder={t('generic.linkPlaceholder')}
                value={xtra}
                onChange={e => setXtra(e.target.value)}
                disabled={isMinting}
              />
            </div>
            <div className={styles.formGroup}>
              <p className={styles.formLabel}>{t('generic.tags')}</p>
              <textarea
                className={cx(styles.formInput, styles.longInput)}
                maxLength={120}
                placeholder={t('generic.tagsPlaceholder')}
                value={tags}
                onChange={e => setTags(e.target.value)}
                disabled={isMinting}
                data-testid="tags-input"
              />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.lockContent}>
                {t('paintBoard.unlockableContentTitle')}&nbsp;
                <StyledSwitcher
                  checked={hasUnlockableContent}
                  onChange={e => {
                    setHasUnlockableContent(e.target.checked);
                    setUnlockableContent('');
                  }}
                  name="unlockableContent"
                />
              </div>
              {hasUnlockableContent && (
                <textarea
                  className={cx(styles.formInput, styles.longInput)}
                  maxLength={500}
                  placeholder={t('paintBoard.unlockableContent')}
                  value={unlockableContent}
                  onChange={e => setUnlockableContent(e.target.value)}
                  disabled={isMinting}
                />
              )}
            </div>

            {isMinting && (
              <div>
                <Stepper activeStep={currentMintingStep} alternativeLabel>
                  {mintSteps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            )}
            <MainButton
              disabled={isMinting || !account || !validateMetadata()}
              onClick={
                isMinting || !account || !validateMetadata() ? null : mintNFT
              }
              data-testid="button-mint"
            >
              {isMinting ? (
                <ClipLoader size="16" color="white"></ClipLoader>
              ) : (
                t('buttons.mint')
              )}
            </MainButton>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaintBoard;
