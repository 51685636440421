import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../pages/NFTItem/styles.module.scss';
import Skeleton from 'react-loading-skeleton';
import { shortenAddress } from '../../../utils';
import cx from 'classnames';
import { formatNumber } from '../../../utils';
import {
  People as PeopleIcon,
  ViewModule as ViewModuleIcon,
} from '@material-ui/icons';
import Identicon from '../../Identicon';
import { useTranslation } from 'react-i18next';

const RenderItemOwner = ({
  tokenType,
  isMine,
  bundleID,
  ownerInfoLoading,
  tokenOwnerLoading,
  owner,
  tokenInfo,
  ownerInfo,
  setOwnersModalVisible,
  holders,
}) => {
  const { t } = useTranslation('common');
  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;
  return (
    <>
      {(ownerInfoLoading || tokenOwnerLoading || owner || tokenInfo) && (
        <div className={styles.itemOwner}>
          {ownerInfoLoading || tokenOwnerLoading ? (
            <Skeleton width={150} height={20} />
          ) : tokenType.current === 721 || bundleID ? (
            <>
              <div className={styles.ownerAvatar}>
                {ownerInfo?.imageHash ? (
                  <img
                    src={`${infuraUrl}/${ownerInfo.imageHash}`}
                    className={styles.avatar}
                  />
                ) : (
                  <Identicon
                    account={owner}
                    size={32}
                    className={styles.avatar}
                  />
                )}
              </div>
              {t('generic.ownedBy')}&nbsp;
              <Link to={`/account/${owner}`} className={styles.ownerName}>
                {isMine ? 'Me' : ownerInfo?.alias || shortenAddress(owner)}
              </Link>
            </>
          ) : tokenInfo ? (
            <>
              <div
                className={cx(styles.itemViews, styles.clickable)}
                onClick={() => setOwnersModalVisible(true)}
              >
                <PeopleIcon style={styles.itemIcon} />
                &nbsp;{formatNumber(holders.length)}
                &nbsp;{t('generic.owner').toLowerCase()}
                {holders.length > 1 && 's'}
              </div>
              <div className={styles.itemViews}>
                <ViewModuleIcon style={styles.itemIcon} />
                &nbsp;{formatNumber(tokenInfo.totalSupply)} {t('generic.total')}
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default RenderItemOwner;
