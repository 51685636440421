import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderActions from 'actions/header.actions';
import FilterActions from 'actions/filter.actions';
import Header from 'components/header';
import ErrorBoundary from 'components/ErrorBoundary';

import styles from './styles.module.scss';
import Footer from 'components/Footer/Footer';
import Discover from 'components/Discover';
import Banner from 'components/Banner';
import NewestListing from 'components/NewestListing';
import TrendingListings from 'components/TrendingListings';
import HeroBanner from 'components/HeroBanner';

const LandingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
  }, []);

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        <Header />
        <div className={styles.body}>
          <HeroBanner />
          <NewestListing />
          <TrendingListings />
          <Discover />
          <Banner />
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default LandingPage;
