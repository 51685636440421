import React, { useEffect, useState, useRef } from 'react';
import { useHistory, withRouter, NavLink, Link } from 'react-router-dom';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Menu } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import WalletConnectActions from 'actions/walletconnect.actions';
import AuthActions from 'actions/auth.actions';
import ModalActions from 'actions/modal.actions';
import { shortenAddress } from 'utils';
import { useApi } from 'api';
import { NETWORK_LABEL } from 'constants/networks';
import { ADMIN_ADDRESSES } from 'constants/index';
import ModModal from 'components/ModModal';
import BanCollectionModal from 'components/BanCollectionModal';
import BanItemModal from 'components/BanItemModal';
import ConnectWalletModal from 'components/ConnectWalletModal';
import Identicon from 'components/Identicon';

import kiwiiLogo from 'assets/imgs/Kiwii_Logo.jpg';
import iconUser from 'assets/svgs/user.svg';
// import iconNotification from 'assets/svgs/notification.svg';
import iconAdd from 'assets/svgs/add.svg';
import iconGrayAdd from 'assets/svgs/add-gray.svg';
import iconEdit from 'assets/svgs/edit.svg';
import iconGrayEdit from 'assets/svgs/edit-gray.svg';

import styles from './styles.module.scss';
import SearchBox from './SearchBox';
import { MainButton } from 'components/MainButton';
import BootstrapTooltip from 'components/BootstrapTooltip';

const Header = ({ border }) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const dispatch = useDispatch();

  const { apiUrl, getAuthToken, getAccountDetails, getIsModerator } = useApi();
  const { account, chainId, deactivate } = useWeb3React();

  const { user } = useSelector(state => state.Auth);
  let isSearchbarShown = useSelector(state => state.HeaderOptions.isShown);
  let isBannerShown = useSelector(state => state.HeaderOptions.isShownBanner);
  const { isModerator } = useSelector(state => state.ConnectWallet);
  const { connectWalletModalVisible } = useSelector(state => state.Modal);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [modModalVisible, setModModalVisible] = useState(false);
  const [isBan, setIsBan] = useState(false);
  const [banCollectionModalVisible, setBanCollectionModalVisible] = useState(
    false
  );
  const [banItemModalVisible, setBanItemModalVisible] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [cancelSource, setCancelSource] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [tokenDetailsLoading, setTokenDetailsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [accessStatus, setAccess] = useState(false);
  const timer = useRef(null);

  const isMenuOpen = Boolean(anchorEl);

  const infuraUrl = window.__RUNTIME_CONFIG__.REACT_APP_INFURA_URL;

  const login = async () => {
    try {
      setLoading(true);
      const token = await getAuthToken(account);
      const isModerator = await getIsModerator(account);

      dispatch(WalletConnectActions.connectWallet(token, isModerator));
      dispatch(AuthActions.fetchStart());
      try {
        const { data } = await getAccountDetails(token);
        dispatch(AuthActions.fetchSuccess(data));
      } catch {
        dispatch(AuthActions.fetchFailed());
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const init = () => {
    login();
  };

  useEffect(() => {
    checkAccess()
    if (account) {
      init();
    }
  }, [account, chainId]);

  const handleConnectWallet = () => {
    dispatch(ModalActions.showConnectWalletModal());
  };

  const resetResults = () => {
    setAccounts([]);
    setCollections([]);
    setTokens([]);
    setBundles([]);
  };

  useEffect(() => {
    resetResults();
  }, [isSearchbarShown]);

  const search = async word => {
    setKeyword(word);

    if (cancelSource) {
      cancelSource.cancel();
    }

    if (word.length === 0) {
      resetResults();

      return;
    }

    try {
      const cancelTokenSource = axios.CancelToken.source();
      setCancelSource(cancelTokenSource);
      setSearchLoading(true);

      const {
        data: {
          data: { accounts, collections, tokens, bundles },
        },
      } = await axios({
        method: 'post',
        url: `${apiUrl}/info/searchNames`,
        data: JSON.stringify({ name: word }),
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: cancelTokenSource.token,
      });

      setSearchLoading(false);
      setAccounts(accounts);
      setCollections(collections);
      setTokenDetailsLoading(true);
      setTokens(tokens);
      setBundles(bundles);
      setTokenDetailsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setCancelSource(null);
    }
  };

  const handleSearch = word => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => search(word), 500);
  };

  const handleSignOut = () => {
    deactivate();
    dispatch(WalletConnectActions.disconnectWallet());
    dispatch(AuthActions.signOut());
    handleMenuClose();
  };

  const checkAccess = async () => {
    const isModerator = await getIsModerator(account);
    if((isModerator === false) && (!ADMIN_ADDRESSES.includes(account?.toLowerCase()))) {
      setAccess(false);
    } else {
      setAccess(true);
    }
  }

  const handleProfileMenuOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const goToMyProfile = () => {
    history.push(`/account/${account}`);
    handleMenuClose();
  };

  // const goToNotificationSettings = () => {
  //   history.push(`/settings/notification`);
  //   handleMenuClose();
  // };

  const handleCreateCollection = () => {
    if (accessStatus) {
      history.push('/collection/create');
    }
    handleMenuClose();
    return;
  };

  const handleRegisterCollection = () => {
    if (accessStatus) {
      history.push('/collection/register');
    }
    handleMenuClose();
    return;
  };

  const addMod = () => {
    setIsAdding(true);
    setModModalVisible(true);
    handleMenuClose();
  };

  const removeMod = () => {
    setIsAdding(false);
    setModModalVisible(true);
    handleMenuClose();
  };

  const approveCollections = () => {
    history.push('/collection/approve');
    handleMenuClose();
  };

  const verifyCollections = () => {
    history.push('/collection/verify');
    handleMenuClose();
  };

  const banCollection = () => {
    setIsBan(true);
    setBanCollectionModalVisible(true);
    handleMenuClose();
  };

  const unbanCollection = () => {
    setIsBan(false);
    setBanCollectionModalVisible(true);
    handleMenuClose();
  };

  const banItems = () => {
    setBanItemModalVisible(true);
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        paper: styles.profilemenu,
        list: styles.menuList,
      }}
    >
      {account && (
        <div
          className={cx(styles.menuItem, styles.topItem)}
          onClick={goToMyProfile}
        >
          <img src={iconUser} className={styles.menuIcon} />
          {t('header.settings.profile')}
        </div>
      )}
      {/* <div className={styles.menuItem} onClick={goToNotificationSettings}>
        <img src={iconNotification} className={styles.menuIcon} />
        {t('header.settings.notifications')}
      </div> */}
      {accessStatus ? (
        <div className={styles.menuItem} onClick={handleCreateCollection}>
          <img src={iconAdd} className={styles.menuIcon} />
          {t('header.settings.createCollection')}
        </div>
      ) : (
        <BootstrapTooltip
          title={'Coming soon'}
          placement="bottom"
        >
          <div
            className={cx(styles.menuItem, styles.menuItemInactive)} 
            onClick={handleCreateCollection}
          >
            <img src={iconGrayAdd} className={styles.menuIcon} />
            {t('header.settings.createCollection')}
          </div>
        </BootstrapTooltip>
      )}
      {accessStatus ? (
        <div className={styles.menuItem} onClick={handleRegisterCollection}>
          <img src={iconEdit} className={styles.menuIcon} />
          {t('header.settings.registerCollection')}
        </div>
      ) : (
        <BootstrapTooltip
          title={'Coming soon'}
          placement="bottom"
        >
          <div
            className={cx(styles.menuItem, styles.menuItemInactive)} 
            onClick={handleRegisterCollection}
          >
            <img src={iconGrayEdit}  className={styles.menuIcon} />
            {t('header.settings.registerCollection')}
          </div>
        </BootstrapTooltip>
      )}
      <div className={styles.menuSeparator} />
      {ADMIN_ADDRESSES.includes(account?.toLowerCase())
        ? [
            <div key={0} className={styles.menuItem} onClick={addMod}>
              {t('header.settings.addMod')}
            </div>,
            <div key={1} className={styles.menuItem} onClick={removeMod}>
              {t('header.settings.removeMod')}
            </div>,
            <div
              key={2}
              className={styles.menuItem}
              onClick={approveCollections}
            >
              {t('header.settings.approveCollections')}
            </div>,
            <div
              key={3}
              className={styles.menuItem}
              onClick={verifyCollections}
            >
              {t('header.settings.verifyCollections')}
            </div>,
            <div key={4} className={styles.menuItem} onClick={banCollection}>
              {t('header.settings.banCollection')}
            </div>,
            <div key={5} className={styles.menuItem} onClick={unbanCollection}>
              {t('header.settings.unbanCollection')}
            </div>,
            <div key={6} className={styles.menuItem} onClick={banItems}>
              {t('header.settings.banItems')}
            </div>,
            <div key={7} className={styles.menuSeparator} />,
          ]
        : isModerator
        ? [
            <div key={1} className={styles.menuItem} onClick={banCollection}>
              {t('header.settings.banCollection')}
            </div>,
            <div key={2} className={styles.menuItem} onClick={unbanCollection}>
              {t('header.settings.unbanCollection')}
            </div>,
            <div key={3} className={styles.menuItem} onClick={banItems}>
              {t('header.settings.banItems')}
            </div>,
            <div
              key={4}
              className={styles.menuItem}
              onClick={approveCollections}
            >
              {t('header.settings.approveCollections')}
            </div>,
            <div
              key={5}
              className={styles.menuItem}
              onClick={verifyCollections}
            >
              {t('header.settings.verifyCollections')}
            </div>,
            <div key={6} className={styles.menuSeparator} />,
          ]
        : null}
      <div className={styles.signOut} onClick={handleSignOut}>
        {t('header.settings.signOut')}
      </div>
    </Menu>
  );

  return (
    <div
      className={cx(styles.header, border && styles.hasBorder)}
      style={!isBannerShown ? {top: '0'}: {top: isMobile ? '58px' : '38px'}}
    >
      <div className={styles.left}>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logo}>
            <img src={kiwiiLogo} alt="logo" />
          </Link>
        </div>
        {isSearchbarShown && (
          <SearchBox
            searchBarActive={searchBarActive}
            handleSearch={handleSearch}
            setSearchBarActive={setSearchBarActive}
            collections={collections}
            accounts={accounts}
            tokens={tokens}
            bundles={bundles}
            keyword={keyword}
            tokenDetailsLoading={tokenDetailsLoading}
            searchLoading={searchLoading}
          />
        )}
        <div className={styles.secondmenu}>
          <NavLink
            to="/explore"
            className={cx(styles.menuLink, styles.link)}
            activeClassName={styles.active}
          >
            {t('buttons.explore')}
          </NavLink>
          <NavLink
            to="/create"
            className={cx(styles.menuLink, styles.link)}
            activeClassName={styles.active}
          >
            {t('buttons.create')}
          </NavLink>
          <NavLink
            to="/about"
            className={cx(styles.menuLink, styles.link)}
            activeClassName={styles.active}
          >
            {t('generic.about')}
          </NavLink>
          {/* <LanguageChooser /> */}
        </div>
      </div>
      <div className={styles.menu}>
        {isSearchbarShown && (
          <SearchBox
            searchBarActive={searchBarActive}
            handleSearch={handleSearch}
            setSearchBarActive={setSearchBarActive}
            collections={collections}
            accounts={accounts}
            tokens={tokens}
            bundles={bundles}
            keyword={keyword}
            tokenDetailsLoading={tokenDetailsLoading}
            searchLoading={searchLoading}
          />
        )}
        <NavLink
          to="/explore"
          className={cx(styles.menuLink, styles.link)}
          activeClassName={styles.active}
        >
          {t('buttons.explore')}
        </NavLink>
        <NavLink
          to="/about"
          className={cx(styles.menuLink, styles.link)}
          activeClassName={styles.active}
        >
          {t('generic.about')}
        </NavLink>
        {accessStatus ? (
          <NavLink
            to={accessStatus ? '/create' : '/explore'}
            className={
              cx(
                  styles.menuLink, 
                  styles.link,
                  accessStatus ? '' : styles.disabledLink
                )}
            activeClassName={styles.active}
          >
            {t('buttons.create')}
          </NavLink>
        ) : (
          <BootstrapTooltip
              title={'Coming soon'}
              placement="bottom"
            >
            <NavLink
              to={accessStatus ? '/create' : '/explore'}
              className={
                cx(
                    styles.menuLink, 
                    styles.link,
                    accessStatus ? '' : styles.disabledLink
                  )}
              // activeClassName={styles.active}
              onClick={(e) => {
                e.preventDefault();
                return;
              }}
            >
              {t('buttons.create')}
            </NavLink>
          </BootstrapTooltip>
        )}
        
        {/* <LanguageChooser /> */}
        {account ? (
          <div
            className={cx(styles.account, styles.menuLink)}
            onClick={handleProfileMenuOpen}
          >
            {loading ? (
              <Skeleton className={styles.avatar} />
            ) : user?.imageHash ? (
              <img
                src={`${infuraUrl}/${user?.imageHash}`}
                width="24"
                height="24"
                className={styles.avatar}
              />
            ) : (
              <Identicon
                account={account}
                size={36}
                className={styles.avatar}
              />
            )}
            <div className={styles.profile}>
              <div className={styles.address}>
                {loading ? (
                  <Skeleton width={120} />
                ) : (
                  user?.alias || shortenAddress(account)
                )}
              </div>
              <div className={styles.network}>
                {loading ? <Skeleton width={80} /> : NETWORK_LABEL[chainId]}
              </div>
            </div>

            <ExpandMore
              className={cx(styles.expand, isMenuOpen && styles.expanded)}
            />
          </div>
        ) : (
          <MainButton
            small
            className={cx(styles.connect, styles.menuLink)}
            onClick={handleConnectWallet}
          >
            {t('buttons.connectWallet')}
          </MainButton>
        )}
      </div>
      {renderMenu}
      <ModModal
        isAdding={isAdding}
        visible={modModalVisible}
        onClose={() => setModModalVisible(false)}
      />
      <BanCollectionModal
        visible={banCollectionModalVisible}
        isBan={isBan}
        onClose={() => setBanCollectionModalVisible(false)}
      />
      <BanItemModal
        visible={banItemModalVisible}
        onClose={() => setBanItemModalVisible(false)}
      />
      <ConnectWalletModal
        visible={connectWalletModalVisible}
        onClose={() => dispatch(ModalActions.hideConnectWalletModal())}
      />
    </div>
  );
};
export default withRouter(Header);
