import React from 'react';
import styles from '../../../pages/NFTItem/styles.module.scss';
import { shortenAddress } from '../../../utils';
import Panel from 'components/Panel';
import { Ballot as BallotIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import nahmiiChainId from 'constants/nahmiiChainId';

const RenderCollectionPanel = ({ explorerUrl, address }) => {
  const { t } = useTranslation('common');
  const isMainnet = window.__RUNTIME_CONFIG__.REACT_APP_ENV === 'MAINNET';
  return (
    <Panel title={t('generic.chainInfo')} icon={BallotIcon}>
      <div className={styles.panelBody}>
        <div className={styles.panelLine}>
          <div className={styles.panelLabel}>{t('generic.collection')}</div>
          <a
            href={`${explorerUrl}/token/${address}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.panelValue}
          >
            {shortenAddress(address)}
          </a>
        </div>
        <div className={styles.panelLine}>
          <div className={styles.panelLabel}>{t('generic.network')}</div>
          <div data-testid="nahmii-network" className={styles.panelValue}>
            {isMainnet ? t('generic.nahmiiMainnet') : t('generic.nahmiiTestnet')}
          </div>
        </div>
        <div className={styles.panelLine}>
          <div className={styles.panelLabel}>{t('generic.chainId')}</div>
          <div data-testid="nahmii-chainid" className={styles.panelValue}>
            {isMainnet ? nahmiiChainId.MAINNET : nahmiiChainId.TESTNET}
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default RenderCollectionPanel;
