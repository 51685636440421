import React from 'react';

import Blog from 'components/AboutPage/Blog';
import FAQ from 'components/AboutPage/FAQ';
import GIFContent from 'components/AboutPage/GIFContent';
import OurStory from 'components/AboutPage/OurStory';
import SkunkBanner from 'components/AboutPage/SkunkBanner';
import Footer from 'components/Footer/Footer';
import Header from 'components/header';
import LearnMoreBanner from 'components/AboutPage/LearnMoreBanner';

import styles from './styles.module.scss';

const About = () => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.container}>
        <GIFContent />
        <FAQ />
        <SkunkBanner />
        <OurStory />
        <Blog />
        <LearnMoreBanner />
      </div>
      <Footer />
    </div>
  );
};

export default About;
