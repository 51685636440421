import * as React from "react"

const IconActivity = (props) => (
  <svg
    width={21}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.785 4.5a.5.5 0 0 1 .5.5v4.232l2.777 1.852a.5.5 0 0 1-.554.832l-3-2a.5.5 0 0 1-.223-.416V5a.5.5 0 0 1 .5-.5Z"
      fill="#000"
    />
    <path
      d="M4.042 8.683a7.25 7.25 0 1 1 1.666 4.95.75.75 0 0 0-1.154.959A8.75 8.75 0 1 0 2.54 8.695l-.725-.725a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l2-2a.75.75 0 0 0-1.06-1.06l-.713.713Z"
      fill="#000"
    />
  </svg>
)

export default IconActivity