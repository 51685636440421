import { MainButton } from 'components/MainButton';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export default function Banner() {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles.mainText}>{t('banner.mainText')}</div>
          <Link to="/explore" className={styles.linkButton}>
            <MainButton small>{t('buttons.exploreMarketplace')}</MainButton>
          </Link>
          <div className={styles.subText}>{t('banner.subText')}</div>
        </div>
      </div>
    </div>
  );
}
