import { ethers } from 'ethers';
import { getAddress } from '@ethersproject/address';

import { Categories } from '../constants/filter.constants.js';

export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function shortenAddress(address, chars = 4) {
  if (!address) return '';

  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export const getHigherGWEI = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const price = (await provider.getGasPrice()) * 2;

  return price;
};

// Limit decimals on card view
// Over 4 decimals places show low value placeholder
// 4 decimal places if n < 0.1
// 2 decimal places otherwise
export const formatNumberCard = num => {
  const minValue = 0.0001;
  if (isNaN(num) || num === null) return '';
  num = Number(num);
  if (num < minValue) return `< ${minValue.toString()}`;
  let truncNum = Number(num) < 0.1 ? Number(num.toFixed(4)) : num.toFixed(2);
  let parts = truncNum.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatNumber = num => {
  if (isNaN(num) || num === null) return '';
  let parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const formatCategory = category => {
  return Categories.find(item => item.id === category).label;
};

export const formatError = error => {
  let startIndex = error.indexOf('message');
  let finalString = String(error.substr(startIndex + 10).replace(`"}}}'`, ''));

  return finalString;
};

const intlFormat = num => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export const formatFollowers = num => {
  if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
  if (num >= 1000) return intlFormat(num / 1000) + 'k';
  return intlFormat(num);
};

export const calculateGasMargin = value => {
  return value
    .mul(ethers.BigNumber.from(10000).add(ethers.BigNumber.from(1000)))
    .div(ethers.BigNumber.from(10000));
};

export function isScientificNotation(input) {
  return /^[-+]?\d+(\.\d+)?[eE][-+]?\d+$/.test(input);
}

export function getNumberAfterMinus(scientificNotation) {
  const match = scientificNotation.match(/-(\d+)/);
  if (match) {
    return parseInt(match[1], 10);
  }
  return parseInt(match[1], 10) || null;
}