import React, { useEffect } from 'react';
import cx from 'classnames';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import { SUPPORTED_WALLETS } from 'constants/wallet';
import usePrevious from 'hooks/usePrevious';
import showToast from 'utils/toast';

import Modal from '../Modal';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const { ethereum } = window;

const Option = ({ onClick = null, header, icon, active = false }) => {
  return (
    <div
      onClick={onClick}
      data-testid={`wallet-option`}
      className={cx(styles.option, active && styles.active)}
    >
      <div className={styles.header}>{header}</div>
      <img src={icon} className={styles.icon} />
    </div>
  );
};

const ConnectWalletModal = ({ visible, onClose }) => {
  const { t } = useTranslation('common');
  const { activate, active, connector, error } = useWeb3React();

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      visible &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      onClose();
    }
  }, [active, error, connector, visible, activePrevious, connectorPrevious]);

  const tryActivation = async connector => {
    let conn = typeof connector === 'function' ? await connector() : connector;

    Object.keys(SUPPORTED_WALLETS).map(key => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return SUPPORTED_WALLETS[key].name;
      }
      return true;
    });

    conn &&
      activate(conn, undefined, true).catch(error => {
        if (error instanceof UnsupportedChainIdError) {
          activate(conn); // a little janky...can't use setError because the connector isn't set
        }

        if (error instanceof NoEthereumProviderError) {
          showToast('error', t('errors.noWalletFound'));
        }
      });
  };

  const getOptions = () => {
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key];

      return (
        <Option
          onClick={() => {
            option.connector === connector
              ? null
              : tryActivation(option.connector);
          }}
          key={key}
          active={option.connector === connector}
          header={option.name}
          icon={option.icon}
        />
      );
    });
  };

  const addNahmiiTestnetNetwork = async () => {
    const chainID = "0x" + parseInt(window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_CHAINID).toString(16);
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainID }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainID,
                chainName: 'Nahmii Testnet',
                rpcUrls: [window.__RUNTIME_CONFIG__.REACT_APP_TESTNET_RPC_URL] /* ... */,
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                blockExplorerUrls: [window.__RUNTIME_CONFIG__.REACT_APP_EXPLORER_URL_TESTNET],
                iconUrls: ''
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          console.error(addError)
        }
      }
      // handle other "switch" errors
    }
  }

  const getModalContent = () => {
    if (error instanceof UnsupportedChainIdError) {
      return (
        <div>
          <div className={styles.text}>
              {t('messages.changeNetworkTestnet')}
          </div>
          <div
            className={styles.switchBtn}
            onClick={addNahmiiTestnetNetwork}
          >
            Switch network
          </div>
        </div>
      );
    }
    return getOptions();
  };

  return (
    <Modal
      visible={visible}
      title={
        error instanceof UnsupportedChainIdError
          ? t('errors.wrongNetwork')
          : t('errors.connectToWallet')
      }
      onClose={onClose}
      small
    >
      {getModalContent()}
    </Modal>
  );
};

export default ConnectWalletModal;
