import * as React from 'react';
import { styled } from '@mui/system';
import SwitchUnstyled, {
  switchUnstyledClasses,
} from '@mui/base/SwitchUnstyled';

const purple = {
  500: '#5850EC',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
};

const Root = styled('span')(
  ({ mainColor }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  min-width: 44px;
  height: 24px;
  margin: 10px; 
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${grey[600]};
    border-radius: 12px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 22px;
    height: 22px;
    top: 1px;
    left: 1px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 21px;
      top: 1px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${
        mainColor
          ? 'linear-gradient(90deg, #D905A6 0%, #FCBE0B 100%)'
          : purple[500]
      }
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
);

export const StyledSwitcher = props => {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  return <SwitchUnstyled component={Root} {...label} {...props} />;
};
