import HeaderConstants from '../constants/header.constants';

const HeaderActions = {
  toggleSearchbar,
  toggleBanner,
};

function toggleSearchbar(toggle) {
  return dispatch => {
    dispatch(_toggleSearchbar(toggle));
  };
}

const _toggleSearchbar = toggle => {
  return {
    type: HeaderConstants.TOGGLESEARCHBAR,
    toggle: toggle,
  };
};
function toggleBanner(toggle) {
  return dispatch => {
    dispatch(_toggleBanner(toggle));
  };
}

const _toggleBanner = toggle => {
  return {
    type: HeaderConstants.TOGGLENOTIFICATIONBANNER,
    toggle: toggle,
  };
};

export default HeaderActions;
