import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import cx from 'classnames';
import axios from 'axios';
import { Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ClipLoader } from 'react-spinners';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import showToast from 'utils/toast';

import { Categories } from 'constants/filter.constants';
import HeaderActions from 'actions/header.actions';
import Header from 'components/header';
import PriceInput from 'components/PriceInput';
import toast from 'utils/toast';
import { useApi } from 'api';
import { useFactoryContract, getSigner } from 'contracts';

import discordIconGrey from 'assets/imgs/discordGrey.png';
import twitterIconGrey from 'assets/imgs/twitterGrey.png';
import instagramIconGrey from 'assets/imgs/instagramGrey.png';
import mediumIconGrey from 'assets/imgs/mediumGrey.png';
import BootstrapTooltip from 'components/BootstrapTooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import nftIcon from 'assets/svgs/nft_black.svg';
import closeIcon from 'assets/svgs/close.svg';

import styles from './styles.module.scss';
import { formatError, isAddress } from 'utils';
import { useTranslation } from 'react-i18next';
// import { StyledSwitcher } from 'components/StyledSwitch';
import { MainButton } from 'components/MainButton';
import Footer from 'components/Footer/Footer';
import { ADMIN_ADDRESSES } from 'constants/index';

import WalletUtils from '../../../utils/wallet';

const CollectionCreate = ({ isRegister }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('common');

  const { account } = useWeb3React();
  const { apiUrl, getNonce, getIsModerator } = useApi();
  const {
    getFactoryContract,
    getPrivateFactoryContract,
    createNFTContract,
    getFactoryFee,
    getPrivateFactoryFee,
    getFactoryContractAddress,
    getPrivateFactoryContractAddress,
  } = useFactoryContract();

  const inputRef = useRef(null);
  const bannerRef = useRef(null);

  const { authToken } = useSelector(state => state.ConnectWallet);

  const [deploying, setDeploying] = useState(false);
  const [creating, setCreating] = useState(false);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [symbol, setSymbol] = useState('');
  const [symbolError, setSymbolError] = useState(null);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(null);
  const [royalty, setRoyalty] = useState('');
  const [feeRecipient, setFeeRecipient] = useState('');
  const [recipientError, setRecipientError] = useState(null);
  const [URLvalue, setURLvalue] = useState('');
  const [URLError, setURLError] = useState(null);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [discord, setDiscord] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');
  const [mediumHandle, setMediumHandle] = useState('');
  const [telegram, setTelegram] = useState('');
  const [isPrivate, ] = useState(false);
  const [sufficientBalance, setSufficientBalance] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const checkAccess = async () => {
    const isModerator = await getIsModerator(account);
    if((isModerator === false) && (!ADMIN_ADDRESSES.includes(account?.toLowerCase()))) {
      history.replace('/404');
    }
  }

  useEffect(() => {
    checkAccess()
    dispatch(HeaderActions.toggleSearchbar(true));
  }, []);

  useEffect(() => {
    if (account) {
      checkBalance();
    }
  }, [account, isPrivate]);

  useEffect(() => {
    setLogo(null);
    setBanner(null);
    setAnchorEl(null);
    setSelected([]);
    setName('');
    setNameError(null);
    setSymbol('');
    setSymbolError(null);
    setDescription('');
    setDescriptionError(null);
    setURLvalue('');
    setURLError(null);
    setAddress('');
    setAddressError(null);
    setDiscord('');
    setTwitterHandle('');
    setInstagramHandle('');
    setMediumHandle('');
    setTelegram('');
  }, [isRegister]);

  const options = Categories.filter(cat => selected.indexOf(cat.id) === -1);
  const selectedCategories = Categories.filter(
    cat => selected.indexOf(cat.id) > -1
  );

  const removeImage = () => {
    setLogo(null);
  };

  const removeBanner = () => {
    setBanner(null);
  };

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = function(e) {
        setLogo(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleBannerFileSelect = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = function(e) {
        setBanner(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const validateName = () => {
    if (name.length === 0) {
      setNameError(t('pages.collections.noBlank'));
    } else {
      setNameError(null);
    }
  };

  const validateSymbol = () => {
    if (symbol.length === 0) {
      setSymbolError(t('pages.collections.noBlank'));
    } else if (symbol.includes(' ')) {
      setSymbolError(t('pages.collections.noSpaces'));
    } else {
      setSymbolError(null);
    }
  };

  const validateDescription = () => {
    if (description.length === 0) {
      setDescriptionError(t('pages.collections.noBlank'));
    } else {
      setDescriptionError(null);
    }
  };

  const validateFeeRecipient = () => {
    if (royalty > 0 && feeRecipient.length === 0) {
      setRecipientError(t('pages.collections.noBlank'));
    } else if (royalty > 0 && !isAddress(feeRecipient)) {
      setRecipientError(t('errors.invalidAddress'));
    } else {
      setRecipientError(null);
    }
  };

  const validateEmail = () => {
    const re = new RegExp(/\S+@\S+\.\S+/);
    if (email.length === 0 || !re.test(email.toLowerCase())) {
      setEmailError(t('pages.collections.emailNotValid'));
    } else {
      setEmailError(null);
    }
  };

  const validateAddress = () => {
    if (address.length === 0) {
      setAddressError(t('pages.collections.noBlank'));
    } else {
      setAddressError(null);
    }
  };

  const handleMenuOpen = e => {
    if (selected.length < 3) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const selectCategory = catId => {
    setSelected([...selected, catId]);
    if (selected.length === 2) {
      setAnchorEl(null);
    }
  };

  const deselectCategory = catId => {
    setSelected(selected.filter(id => id !== catId));
  };

  const checkBalance = async () => {
    const balance = await WalletUtils.checkBalanceBigNumber(account);
    if (isPrivate) {
      const fee = await getPrivateFactoryFee();
      const privateFactoryAddress = await getPrivateFactoryContractAddress();

      if (!!account && !!privateFactoryAddress) {
        if (fee !== null && balance.gte(fee)) {
          setSufficientBalance(true);
        } else {
          setSufficientBalance(false);
        }
      }
    }
    if (!isPrivate) {
      const factoryAddress = await getFactoryContractAddress();
      const fee = await getFactoryFee();
      if (!!account && !!factoryAddress) {
        if (fee !== null && balance.gte(fee)) {
          setSufficientBalance(true);
        } else {
          setSufficientBalance(false);
        }
      }
    }
  };

  const checkEmptyString = (...variables) => {
    return variables.filter(variable => variable === '').length === 0;
  }

  const variablesValid = () => {
    return checkEmptyString(name,  isRegister ? address : symbol, description, email)
  }

  const isValid = (() => {
    if (
      !logo ||
      !banner ||
      !variablesValid() ||
      nameError ||
      descriptionError ||
      addressError ||
      emailError ||
      (isRegister && (royalty > 0 && feeRecipient === '')) ||
      (isRegister && (royalty > 0 && !isAddress(feeRecipient))) ||
      (isRegister && !isAddress(address)) ||
      (!isRegister && (symbol.length === 0 || symbol.includes(' ')))
    ) {
      return false;
    }
    return true;
  })();

  const clipImage = (image, clipX, clipY, clipWidth, clipHeight, cb) => {
    const CANVAS_SIZE = 128;
    const canvas = document.createElement('canvas');
    canvas.width = CANVAS_SIZE;
    canvas.height = CANVAS_SIZE;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      clipX,
      clipY,
      clipWidth,
      clipHeight,
      0,
      0,
      CANVAS_SIZE,
      CANVAS_SIZE
    );
    cb(canvas.toDataURL());
  };

  const handleRegister = async () => {
    if (creating) return;

    setCreating(true);

    if (!sufficientBalance) {
      toast('error', 'Insufficient account balance');
      setCreating(false);
      return;
    }

    const img = new Image();
    img.onload = function() {
      const w = this.width;
      const h = this.height;
      const size = Math.min(w, h);
      const x = (w - size) / 2;
      const y = (h - size) / 2;
      clipImage(img, x, y, size, size, async logodata => {
        try {
          const { data: nonce } = await getNonce(account, authToken);

          let signature;
          try {
            const signer = await getSigner();
            signature = await signer.signMessage(
              `Validate user action with ID: ${nonce}`
            );
          } catch (err) {
            toast('error', t('errors.signFailedRegister'));
            setCreating(false);
            return;
          }

          const formData = new FormData();
          formData.append('collectionName', name);
          formData.append('erc721Address', address);
          formData.append('imgData', logodata);
          const result = await axios({
            method: 'post',
            url: `${apiUrl}/ipfs/uploadCollectionImage2Server`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authToken}`,
            },
          });

          const logoImageHash = result.data.data;
          const formData2 = new FormData();
          formData2.append('collectionName', name);
          formData2.append('erc721Address', address);
          formData2.append('imgData', banner);
          const bannerResult = await axios({
            method: 'post',
            url: `${apiUrl}/ipfs/uploadCollectionImage2Server`,
            data: formData2,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authToken}`,
            },
          });
          const bannerImageHash = bannerResult.data.data;
          const feeRec =
            feeRecipient || '0x0000000000000000000000000000000000000000';
          const data = {
            URLvalue,
            erc721Address: address,
            collectionName: name,
            description,
            categories: selected.join(','),
            logoImageHash,
            bannerImageHash,
            discord,
            twitterHandle,
            instagramHandle,
            mediumHandle,
            telegram,
            signature,
            royalty,
            feeRecipient: feeRec,
            email: email,
          };

          let response = await axios({
            method: 'post',
            url: `${apiUrl}/collection/collectiondetails`,
            data: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (response?.data?.status == 'failed') {
            toast('error', response?.data?.data)
          }
          
          if (response?.status == 'success' || response?.data?.status == 'success') {
            toast(
              'success',
              t('messages.applicationSubmitted'),
              t('messages.collectionRegSuccessfull')
            );
          }

          history.push('/explore');
        } catch (e) {
          const { data } = e.response;
          toast('error', data.data);
          setCreating(false);
        }
      });
    };
    img.src = logo;
  };

  const handleCreate = async () => {
    setDeploying(true);
    if (!sufficientBalance) {
      toast('error', 'Insufficient account balance');
      setDeploying(false);
      return;
    }
    try {
      const tx = await createNFTContract(
        isPrivate
          ? await getPrivateFactoryContract()
          : await getFactoryContract(),
        name,
        symbol,
        account
      );
      const res = await tx.wait();
      res.events.map(async evt => {
        if (
          evt.topics[0] ===
          '0x2d49c67975aadd2d389580b368cfff5b49965b0bd5da33c144922ce01e7a4d7b'
        ) {
          setDeploying(false);
          setCreating(true);
          const address = ethers.utils.hexDataSlice(evt.data, 44);
          const img = new Image();
          img.onload = function() {
            const w = this.width;
            const h = this.height;
            const size = Math.min(w, h);
            const x = (w - size) / 2;
            const y = (h - size) / 2;
            clipImage(img, x, y, size, size, async logodata => {
              try {
                const { data: nonce } = await getNonce(account, authToken);
                let signature;
                try {
                  const signer = await getSigner();
                  signature = await signer.signMessage(
                    `Validate user action with ID: ${nonce}`
                  );
                } catch (err) {
                  toast('error', t('errors.signFailedCreate'));
                  setCreating(false);
                  return;
                }

                const formData = new FormData();
                formData.append('collectionName', name);
                formData.append('erc721Address', address);
                formData.append('imgData', logodata);
                const result = await axios({
                  method: 'post',
                  url: `${apiUrl}/ipfs/uploadCollectionImage2Server`,
                  data: formData,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                  },
                });
                const logoImageHash = result.data.data;
                const formData2 = new FormData();
                formData2.append('collectionName', name);
                formData2.append('erc721Address', address);
                formData2.append('imgData', banner);
                const bannerResult = await axios({
                  method: 'post',
                  url: `${apiUrl}/ipfs/uploadCollectionImage2Server`,
                  data: formData2,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                  },
                });
                const bannerImageHash = bannerResult.data.data;
                const feeRec =
                  feeRecipient || '0x0000000000000000000000000000000000000000';
                const data = {
                  URLvalue,
                  erc721Address: address,
                  collectionName: name,
                  description,
                  categories: selected.join(','),
                  logoImageHash,
                  bannerImageHash,
                  discord,
                  twitterHandle,
                  instagramHandle,
                  mediumHandle,
                  telegram,
                  signature,
                  royalty,
                  feeRecipient: feeRec,
                  private: isPrivate,
                  email: email,
                };
                await axios({
                  method: 'post',
                  url: `${apiUrl}/collection/collectiondetails`,
                  data: JSON.stringify(data),
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                  },
                });
                showToast(
                  'info',
                  t('pages.collections.collCreate'),
                  '',
                  () => {},
                  10000
                );
                setCreating(false);

                history.push('/explore');
              } catch (e) {
                setCreating(false);
              }
            });
          };
          img.src = logo;
        }
      });
    } catch (err) {
      err.message ? showToast('error', err.message) : showToast('error', formatError(err.data.message));
      console.log(err);
      setDeploying(false);
    }
  };

  const menuId = 'select-category-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        paper: styles.menu,
      }}
    >
      {options.map((cat, idx) => (
        <MenuItem
          key={idx}
          className={styles.category}
          onClick={() => selectCategory(cat.id)}
        >
          <img src={cat.icon} className={styles.catImg} />
          <span className={styles.categoryLabel}>{cat.label}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.inner}>
        <div className={styles.title}>
          {isRegister
            ? t('pages.collections.register')
            : t('pages.collections.createNew')}{' '}
          {t('generic.collection')}
        </div>
        <div className={styles.content}>
          <div className={styles.columnLeft}>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('pages.collections.logoImage')}&nbsp;
                <div>*</div>
              </div>
              <div className={styles.inputSubTitle}>
                {t('pages.collections.imageSize')}
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.logoUploadBox}>
                  {logo ? (
                    <>
                      <img src={logo} />
                      <div className={styles.removeLogoOverlay}>
                        <div
                          className={styles.removeIcon}
                          onClick={removeImage}
                        >
                          <img src={closeIcon} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={styles.uploadOverlay}
                      onClick={() => inputRef.current?.click()}
                    >
                      <input
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleFileSelect}
                        data-testid={'input-logo'}
                      />

                      <div className={styles.uploadtitle}>
                        {t('generic.dropFullText')}&nbsp;JPG, PNG, BMP, GIF Max
                        15mb.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('pages.collections.bannerImage')}&nbsp;
                <div>*</div>
              </div>
              <div className={styles.inputSubTitle}>
                {t('pages.collections.bannerSize')}
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.bannerUploadBox}>
                  {banner ? (
                    <>
                      <img src={banner} />
                      <div className={styles.removeOverlay}>
                        <div
                          className={styles.removeIcon}
                          onClick={removeBanner}
                        >
                          <img src={closeIcon} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`${styles.uploadOverlay} ${styles.banner}`}
                      onClick={() => bannerRef.current?.click()}
                    >
                      <input
                        ref={bannerRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleBannerFileSelect}
                        data-testid={'input-banner'}
                      />

                      <div className={styles.uploadtitle}>
                        {t('generic.dropFullText')}&nbsp;JPG, PNG, BMP, GIF Max
                        15mb.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.columnRight}>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('generic.collName')}
                &nbsp;
                <div>*</div>
              </div>
              <div className={styles.inputWrapper}>
                <input
                  className={cx(styles.input, nameError && styles.hasError)}
                  maxLength={20}
                  placeholder={t('generic.name')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  onBlur={validateName}
                  data-testid="collection-name-input"
                />
                {nameError && <div className={styles.error}>{nameError}</div>}
              </div>
            </div>

            {!isRegister && (
              <div className={styles.inputGroup}>
                <div className={styles.inputTitle}>
                  {t('generic.symbol')}&nbsp;
                  <div>*</div>&nbsp;
                  <BootstrapTooltip
                    title={t('generic.symbolTooltip')}
                    placement="top"
                  >
                    <HelpOutlineIcon htmlColor="#00000080" />
                  </BootstrapTooltip>
                </div>
                <div className={styles.inputWrapper}>
                  <input
                    className={cx(styles.input, symbolError && styles.hasError)}
                    maxLength={20}
                    // placeholder={t('generic.collSymbol')}
                    value={symbol}
                    onChange={e => setSymbol(e.target.value)}
                    onBlur={validateSymbol}
                    data-testid="collection-symbol-input"
                  />
                  {symbolError && (
                    <div className={styles.error}>{symbolError}</div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('generic.category')}&nbsp;
                <div>*</div>
              </div>

              <div className={cx(styles.inputWrapper, styles.categoryList)}>
                <div
                  className={cx(
                    styles.categoryButton,
                    selected.length === 3 && styles.disabled
                  )}
                  onClick={handleMenuOpen}
                >
                  {t('generic.addCategory')}
                </div>
                {selectedCategories.map((cat, idx) => (
                  <div
                    className={styles.selectedCategory}
                    key={idx}
                    onClick={() => deselectCategory(cat.id)}
                  >
                    <img src={cat.icon} className={styles.categoryIcon} />
                    <span className={styles.categoryLabel}>{cat.label}</span>
                    <CloseIcon className={styles.closeIcon} />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('generic.description')}&nbsp;
                <div>*</div>&nbsp;
              </div>
              <div className={styles.inputWrapper}>
                <textarea
                  className={cx(
                    styles.input,
                    styles.longInput,
                    descriptionError && styles.hasError
                  )}
                  maxLength={200}
                  placeholder={t('pages.collections.provideDescription')}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  onBlur={validateDescription}
                  data-testid="collection-description-input"
                />
                {descriptionError && (
                  <div className={styles.error}>{descriptionError}</div>
                )}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('generic.royalty')} %&nbsp;
                <div>*</div>
              </div>
              <div className={styles.inputWrapper}>
                <PriceInput
                  className={styles.input}
                  placeholder={t('generic.collRoyalty')}
                  decimals={2}
                  value={'' + royalty}
                  onChange={val =>
                    val[val.length - 1] === '.'
                      ? setRoyalty(val)
                      : setRoyalty(Math.min(100, +val))
                  }
                />
              </div>
            </div>
            {royalty > 0 ? (
              <div className={styles.inputGroup}>
                <div className={styles.inputTitle}>
                  {t('generic.feeRecipient')}&nbsp;
                  <div>*</div>
                </div>
                <div className={styles.inputWrapper}>
                  <input
                    className={cx(
                      styles.input,
                      recipientError && styles.hasError
                    )}
                    placeholder={t('generic.feeRecipient')}
                    value={feeRecipient}
                    onChange={e => setFeeRecipient(e.target.value)}
                    onBlur={validateFeeRecipient}
                    data-testid="collection-fee-recipient-input"
                  />
                  {recipientError && (
                    <div className={styles.error}>{recipientError}</div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>{t('generic.URL')}&nbsp;</div>
              <div className={styles.inputWrapper}>
                <input
                  className={cx(styles.input, URLError && styles.hasError)}
                  // placeholder={t('generic.URLPlaceholder')}
                  value={URLvalue}
                  onChange={e => setURLvalue(e.target.value)}
                  // onBlur={validateURL}
                  data-testid="collection-URL-input"
                />
                {URLError && <div className={styles.error}>{URLError}</div>}
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>
                {t('messages.emailAddress')}&nbsp;
                <div>*</div>&nbsp;
              </div>

              <div className={styles.inputWrapper}>
                <input
                  className={cx(styles.input, emailError && styles.hasError)}
                  placeholder={t('messages.emailAddress')}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  data-testid="collection-email-input"
                />
                {emailError && <div className={styles.error}>{emailError}</div>}
              </div>
            </div>

            {/* {!isRegister && (
              <div className={styles.inputGroup}>
                <div className={styles.switcherContent}>
                  {t('pages.collections.allowOthers')}
                  &nbsp;
                  <StyledSwitcher
                    mainColor
                    checked={isPrivate}
                    onChange={e => {
                      setIsPrivate(e.target.checked);
                    }}
                  />
                </div>
              </div>
            )} */}

            {isRegister && (
              <div className={styles.inputGroup}>
                <div className={styles.inputTitle}>
                  {t('generic.collAddress')}&nbsp;
                  <div>*</div>
                </div>
                <div className={styles.inputWrapper}>
                  <div
                    className={cx(
                      styles.linkItem,
                      addressError && styles.hasError
                    )}
                  >
                    <div className={styles.linkIconWrapper}>
                      <img src={nftIcon} className={styles.linkIcon} />
                    </div>
                    <input
                      className={styles.linkInput}
                      placeholder={t('pages.collections.enterCollAdd')}
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      onBlur={validateAddress}
                      data-testid="collection-address-input"
                    />
                  </div>
                  {addressError && (
                    <div className={styles.error}>{addressError}</div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.inputGroup}>
              <div className={styles.inputTitle}>{t('generic.link')}&nbsp;</div>
              <div className={styles.inputWrapper}>
                <div className={styles.linksWrapper}>
                  <div className={styles.linkItem}>
                    <div className={styles.linkIconWrapper}>
                      <img src={discordIconGrey} className={styles.linkIcon} />
                    </div>
                    <input
                      className={styles.linkInput}
                      placeholder={t('pages.collections.enterDiscord')}
                      value={discord}
                      onChange={e => setDiscord(e.target.value)}
                    />
                  </div>
                  <div className={styles.linkItem}>
                    <div className={styles.linkIconWrapper}>
                      <img src={twitterIconGrey} className={styles.linkIcon} />
                    </div>
                    <input
                      className={styles.linkInput}
                      placeholder={t('pages.collections.enterTwitter')}
                      value={twitterHandle}
                      onChange={e => setTwitterHandle(e.target.value)}
                    />
                  </div>
                  <div className={styles.linkItem}>
                    <div className={styles.linkIconWrapper}>
                      <img
                        src={instagramIconGrey}
                        className={styles.linkIcon}
                      />
                    </div>
                    <input
                      className={styles.linkInput}
                      placeholder={t('pages.collections.enterInstagram')}
                      value={instagramHandle}
                      onChange={e => setInstagramHandle(e.target.value)}
                    />
                  </div>

                  <div className={styles.linkItem}>
                    <div className={styles.linkIconWrapper}>
                      <img src={mediumIconGrey} className={styles.linkIcon} />
                    </div>
                    <input
                      className={styles.linkInput}
                      placeholder={t('pages.collections.enterMedium')}
                      value={mediumHandle}
                      onChange={e => setMediumHandle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.buttonsWrapper}>
              {isRegister ? (
                <MainButton
                  small
                  disabled={creating || !isValid}
                  onClick={isValid ? handleRegister : null}
                >
                  {creating ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    t('buttons.submit')
                  )}
                </MainButton>
              ) : (
                <MainButton
                  small
                  disabled={
                    creating ||
                    deploying ||
                    !isValid
                  }
                  // onClick={isValid ? handleCreate : null}
                  onClick={handleCreate}
                >
                  {creating ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : deploying ? (
                    t('buttons.deploying')
                  ) : (
                    t('buttons.create')
                  )}
                </MainButton>
              )}
            </div>
          </div>
        </div>
      </div>
      {renderMenu}
      <Footer />
    </div>
  );
};

export default CollectionCreate;
