import React from 'react';

import styles from './styles.module.scss';
import { MainButton } from 'components/MainButton';

const SkunkBanner = () => {
  const handleClick = () => {
    const newWindow = window.open(
      'https://skunkdao.io/',
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className={styles.container}>
      <div className={styles.skunkBanner}></div>
      <h2>About SkunkDAO</h2>
      <div className={styles.description}>
        SkunkDAO is the Web3 incubator for projects launching on Nahmii.
        SkunkDAO provides early-stage projects with a 12-week program, providing
        product strategy, development resources, and community engagement to
        successfully launch their application on Nahmii.
      </div>
      <div className={styles.button}>
        <MainButton small onClick={handleClick}>
          Learn More
        </MainButton>
      </div>
    </div>
  );
};

export default SkunkBanner;
