import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useWeb3React } from '@web3-react/core';

import ProtectedRoute from './ProtectedRoute';
import AccountModal from './AccountModal';
import NotFound from './NotFound';
import PaintBoard from './PaintBoard';
import LandingPage from '../pages/landingpage';
import ExplorePage from '../pages/ExplorePage';
import CollectionPage from '../pages/CollectionDetails';
import AccountDetails from '../pages/AccountDetails';
import NFTItem from '../pages/NFTItem';
import CollectionCreate from '../pages/Collection/Create';
import CollectionReview from '../pages/Collection/Review';
// import NotificationSetting from '../pages/NotificationSetting';
import ScrollTop from 'hooks/ScrollTop';
import PriceActions from 'actions/price.actions';
import { formatUnits } from 'ethers/lib/utils';
import About from 'pages/About';
import NotificationBanner from './NotificationCard';
import { ethProvider } from 'utils/provider';

const App = () => {
  const dispatch = useDispatch();
  const { chainId } = useWeb3React();

  const [priceInterval, setPriceInterval] = useState(null);

  const getPrice = async () => {
    try {
      const { ethers } = require('ethers'); // for nodejs only
      // eslint-disable-next-line no-undef

      const aggregatorV3InterfaceABI = [
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'description',
          outputs: [{ internalType: 'string', name: '', type: 'string' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint80', name: '_roundId', type: 'uint80' },
          ],
          name: 'getRoundData',
          outputs: [
            { internalType: 'uint80', name: 'roundId', type: 'uint80' },
            { internalType: 'int256', name: 'answer', type: 'int256' },
            { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
            { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
            { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'latestRoundData',
          outputs: [
            { internalType: 'uint80', name: 'roundId', type: 'uint80' },
            { internalType: 'int256', name: 'answer', type: 'int256' },
            { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
            { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
            { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'version',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
      ];
      const addr = '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419';
      const priceFeed = new ethers.Contract(
        addr,
        aggregatorV3InterfaceABI,
        ethProvider
      );
      priceFeed.latestRoundData().then(roundData => {
        const rate = Number(formatUnits(roundData.answer, 8));
        dispatch(PriceActions.updatePrice(rate));
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (priceInterval) {
      clearInterval(priceInterval);
    }

    getPrice();
    setPriceInterval(setInterval(getPrice, 1000 * 60 * 5));
  }, [chainId]);

  return (
    <div>
      <Router>
        <NotificationBanner />
        <ScrollTop />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/explore" component={ExplorePage} />
          <Route exact path="/explore/:addr" component={CollectionPage} />
          <Route exact path="/about" component={About} />
          <Route path="/explore/:addr/:id" component={NFTItem} />
          <ProtectedRoute exact path="/create" component={PaintBoard} />
          {/* <Route path="/bundle/:bundleID" component={NFTItem} /> */}
          <Route path="/account/:uid" component={AccountDetails} />
          <ProtectedRoute
            path="/collection/create"
            component={() => <CollectionCreate isRegister={false} />}
          />
          <ProtectedRoute
            path="/collection/register"
            component={() => <CollectionCreate isRegister />}
          />
          <ProtectedRoute
            path="/collection/approve"
            component={() => <CollectionReview isApprove={true} />}
          />
          <ProtectedRoute
            path="/collection/verify"
            component={() => <CollectionReview isApprove={false} />}
          />
          {/* <ProtectedRoute
            path="/settings/notification"
            component={NotificationSetting}
          /> */}
          {/* <ProtectedRoute path="/admin" component={AdminPage} /> */}
          <Route
            path="/404"
            component={() => (
              <NotFound title={404} description={'notFound.cannotFind'} />
            )}
          />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
        <AccountModal />
        <Toaster position="bottom-right" reverseOrder={false} />
      </Router>
    </div>
  );
};

export default App;
