import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
// import Select from 'react-dropdown-select';
import Skeleton from 'react-loading-skeleton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BootstrapTooltip from 'components/BootstrapTooltip';

import { formatNumber } from 'utils';
import useTokens from 'hooks/useTokens';
import PriceInput from 'components/PriceInput';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import DateTimePicker from 'components/DateTimePicker';

const OfferModal = ({
  visible,
  onClose,
  onMakeOffer,
  confirming,
  totalSupply,
}) => {
  const { tokens } = useTokens();

  const { t } = useTranslation('common');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tokenPrice, setTokenPrice] = useState();
  const [tokenPriceInterval, setTokenPriceInterval] = useState();
  const [inputError, setInputError] = useState(null);
  const priceData = useSelector(state => state.Price.price);
  useEffect(() => {
    if (tokens?.length > 1) {
      // TODO: Cleanup when ETH commit & finalise is enabled
      const noNative = tokens.filter(tk => tk.symbol !== 'ETH');
      setOptions(noNative);
    }
  }, [tokens]);

  useEffect(() => {
    if (visible) {
      setPrice('');
      setQuantity('1');
      setEndTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
      if (tokens?.length >= 1) {
        const noNative = tokens.filter(tk => tk.symbol !== 'ETH');
        setSelected([noNative[0]]);
      }
    }
  }, [visible]);

  const handleAddHoursToExpiry = hours => {
    const newEndDate = new Date();
    newEndDate.setTime(newEndDate.getTime() + hours * 60 * 60 * 1000);
    setEndTime(newEndDate);
  };

  const getTokenPrice = () => {
    if (tokenPriceInterval) clearInterval(tokenPriceInterval);
    const func = async () => {
      try {
        setTokenPrice(priceData);
      } catch {
        setTokenPrice(null);
      }
    };
    func();
    setTokenPriceInterval(setInterval(func, 60 * 1000));
  };

  useEffect(() => {
    if (selected.length === 0) return;

    getTokenPrice();
  }, [selected]);

  const handleQuantityChange = e => {
    const val = e.target.value;
    if (!val) {
      setQuantity('');
      return;
    }

    if (isNaN(val)) return;

    const _quantity = parseInt(val);
    setQuantity(Math.min(_quantity, totalSupply));
  };

  const handleMakeOffer = () => {
    let quant = 1;
    if (totalSupply > 1) {
      quant = parseInt(quantity);
    }
    onMakeOffer(selected[0], price, quant, endTime);
  };

  const validateInput = () => {
    if (price.length === 0 || parseFloat(price) == 0) return false;
    if (totalSupply > 1 && quantity.length === 0) return false;
    if (endTime.getTime() < new Date().getTime()) return false;
    return true;
  };

  return (
    <Modal
      visible={visible}
      title={t('offerModal.placeYourOffer')}
      onClose={onClose}
      submitDisabled={confirming || !validateInput() || inputError}
      submitLabel={
        confirming ? (
          <ClipLoader color="#FFF" size={16} />
        ) : (
          t('offerModal.placeOffer')
        )
      }
      onSubmit={() =>
        !confirming && validateInput() ? handleMakeOffer() : null
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>
          {t('generic.price')}&nbsp;
          <BootstrapTooltip
            title={t('offerModal.requiresToken', {
              niifi:
                window.__RUNTIME_CONFIG__.REACT_APP_NIIFI_URL ??
                'testnet.niifi.io',
            })}
            placement="top"
          >
            <HelpOutlineIcon />
          </BootstrapTooltip>
        </div>
        <div className={styles.formInputCont}>
          {/* TODO: Handle WETH hardcode upon new token addition */}
          <div className={styles.defaultSelectedToken}>
            <img src={tokens[1]?.icon} className={styles.tokenIcon} />
            <div className={styles.tokenSymbol}>{tokens[1].symbol}</div>
          </div>
          {/* <Select
            options={options}
            disabled={confirming}
            values={selected}
            onChange={tk => {
              setSelected(tk);
            }}
            className={styles.select}
            placeholder=""
            itemRenderer={({ item, itemIndex, methods }) => (
              <div
                key={itemIndex}
                className={styles.token}
                onClick={() => {
                  methods.clearAll();
                  methods.addItem(item);
                }}
              >
                <img src={item?.icon} className={styles.tokenIcon} />
                <div className={styles.tokenSymbol}>{item.symbol}</div>
              </div>
            )}
            contentRenderer={({ props: { values } }) =>
              values.length > 0 ? (
                <div className={styles.selectedToken}>
                  <img src={values[0]?.icon} className={styles.tokenIcon} />
                  <div className={styles.tokenSymbol}>{values[0].symbol}</div>
                </div>
              ) : (
                <div className={styles.selectedToken} />
              )
            }
          /> */}
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            decimals={selected[0]?.decimals || 0}
            value={'' + price}
            onChange={setPrice}
            disabled={confirming}
            onInputError={err => setInputError(err)}
          />
          <div data-testid="offer-usd-price" className={styles.usdPrice}>
            {!isNaN(tokenPrice) && tokenPrice !== null ? (
              `$${formatNumber(
                ((parseFloat(price) || 0) * tokenPrice).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div>
        </div>
        <InputError text={inputError} />
      </div>
      {totalSupply !== null && (
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>{t('generic.quantity')}</div>
          <div className={styles.formInputCont}>
            <input
              className={styles.formInput}
              placeholder={totalSupply}
              value={quantity}
              onChange={handleQuantityChange}
              disabled={confirming || totalSupply === 1}
            />
          </div>
        </div>
      )}
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>{t('offerModal.offerExp')}</div>
        <div className={styles.formInputCont}>
          <DateTimePicker
            value={endTime}
            onChange={setEndTime}
            data-testid="offer-expiration-datetime"
            disabled={confirming}
            isValidDate={new Date().getTime() - 1000 * 60 * 60 * 24}
          />
        </div>
        <div className={styles.timeButtons}>
          <Button type="secondary" onClick={() => handleAddHoursToExpiry(1)}>
            1 {t('units.hour')}
          </Button>
          <Button type="secondary" onClick={() => handleAddHoursToExpiry(24)}>
            24 {t('units.hours')}
          </Button>
          <Button type="secondary" onClick={() => handleAddHoursToExpiry(168)}>
            1 {t('units.week')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OfferModal;
