import nahmiiChainId from '../constants/nahmiiChainId';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { NetworkConnector } from './NetworkConnector';

import FOOK_LOGO_URL from '../assets/svgs/Fook_Logo.png';

// eslint-disable-next-line no-undef
const isMainnet = window.__RUNTIME_CONFIG__.REACT_APP_ENV === 'MAINNET';

const RPC = isMainnet
  ? {
    [nahmiiChainId.MAINNET]: 'https://l2.nahmii.io/',
  }
  : {
    [nahmiiChainId.TESTNET]: window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_RPC_URL,
  };

export const network = new NetworkConnector({
  defaultChainId: nahmiiChainId.MAINNET,
  urls: RPC,
});

export const injected = new InjectedConnector({
  supportedChainIds: isMainnet
    ? [
      5551, // Nahmii Mainnet
    ]
    : [
      parseInt(window.__RUNTIME_CONFIG__.REACT_APP_NETWORK_CHAINID), // Nahmii Testnet
    ],
});

export const walletlink = new WalletLinkConnector({
  url: RPC,
  appName: 'Fook',
  appLogoUrl: FOOK_LOGO_URL,
});
