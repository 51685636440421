import React from 'react';
import AboutAccordion from './Accordion';
import Link from '@mui/material/Link';

import styles from './styles.module.scss';

const bridgeUrl = window.__RUNTIME_CONFIG__.REACT_APP_BRIDGE_URL;

const accordionData = [
  {
    id: 1,
    title: 'What is Kiwii?',
    description: (
      <>
        Kiwii is an NFT platform powered by Nahmii, a layer-2 protocol with
        uniquely high transaction throughput, instant finality and low fees.
        Kiwii offers NFT creators and collectors access to Ethereum’s NFT
        ecosystem without the high cost of minting, distributing, bidding, and
        purchasing. The Kiwii platform is composed of two applications: Kiwii
        Marketplace and{' '}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="http://kiwiilabs.io"
        >
          Kiwii Labs.
        </Link>
      </>
    ),
    active: true,
  },
  {
    id: 2,
    title: 'How do I trade NFTs on Kiwii?',
    description: (
      <>
        To purchase or receive NFTs, you need an Ethereum wallet. We recommend
        installing MetaMask (a browser extension). You can connect MetaMask to
        Nahmii following{' '}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://docs.nahmii.io/readme/build/get-started"
        >
          these steps.
        </Link>
      </>
    ),
    active: false,
  },
  {
    id: 3,
    title: 'What currency is used to pay for transactions?',
    description:
      'Nahmii uses Ether (ETH) as its native fee currency with a low fixed gas price, which reduces transaction fee volatility. You will need to have some ETH in your wallet to pay for transactions on Kiwii.',
    active: false,
  },
  {
    id: 4,
    title: 'How can I deposit money into my Nahmii wallet?',
    description: (
      <>
        You can use{' '}
        <Link underline="none" target="_blank" rel="noopener" href={bridgeUrl}>
          Nahmii bridge
        </Link>{' '}
        to transfer funds over from Ethereum mainnet.
      </>
    ),
    active: false,
  },
  {
    id: 5,
    title: 'Are there marketplace fees?',
    description:
      'There is a small 0.7% transaction fee for all purchases, paid by the buyer. This fee is much lower than other popular platforms, where users can pay upwards of 2.5%.',
    active: false,
  },
  {
    id: 6,
    title: 'How do I create an NFT collection?',
    description: (
      <>
        <p>
          On Kiwii, go to your account, click on Create New Collection, fill in
          the necessary information and submit your collection for review. We
          aim to review all newly created collections within 24 hours. Once your
          collection has been approved, you can start adding NFTs to your
          collection.
        </p>
        <p>
          Kiwii Labs, an NFT minting station, is still in early access,
          onboarding only a small number of hand-picked artists. You can use{' '}
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc4lBF9PPAM3WFbagn1wqkafYsOh6MfER1oJi-eY8fzDZeofQ/viewform"
          >
            this form
          </Link>{' '}
          to submit your artist profile and get on our radar for our full launch
          next year.
        </p>
      </>
    ),
    active: false,
  },
  {
    id: 7,
    title: 'Is connecting my wallet to Kiwii secure?',
    description: (
      <>
        <p>
          We recommend using MetaMask to connect to Kiwii, which requires you to
          import your wallet into MetaMask itself. Important security
          information, such as your wallet’s private key, is stored within
          MetaMask - it is never seen by Kiwii.
        </p>
        <p>
          Users should always be careful when signing transactions using their
          wallet. While we cannot access your private keys, you are responsible
          for any transactions signed by your wallet. Both Kiwii and Nahmii have
          been built with security in mind; however, no system is guaranteed to
          be 100% safe. We strongly recommend the use of hardware wallets and
          best practice private key management.
        </p>
      </>
    ),
    active: false,
  },
  {
    id: 8,
    title: 'How do I bridge my NFTs on Kiwii?',
    description: (
      <>
        As an Ethereum layer 2, Nahmii is compatible with the rest of Ethereum’s
        NFT ecosystem. Owners of NFTs that are natively minted on Nahmii will
        always be able to bridge their assets easily to the Ethereum mainnet
        should they choose. Using the Nahmii Bridge, anyone will be able to port
        existing ERC-721 and ERC-1155 tokens to Kiwii and begin trading them on
        an entirely new market. At any point, these assets can also be sent back
        to the Ethereum network for hosting and trading there. Bridging will be
        available soon through the{' '}
        <Link underline="none" target="_blank" rel="noopener" href={bridgeUrl}>
          Nahmii bridge.
        </Link>
      </>
    ),
    active: false,
  },
  {
    id: 9,
    title: 'Where can I learn about Kiwii and Nahmii?',
    description: (
      <>
        Follow us on{' '}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://twitter.com/KiwiiDAO"
        >
          Twitter
        </Link>
        , join our{' '}
        <Link
          underline="none"
          target="_blank"
          rel="noopener"
          href="https://t.co/jcJqWg5KzN"
        >
          Discord
        </Link>{' '}
        and browse our blog for cool educational content. Our community is happy
        to welcome you (and we’re sure they’ll love your art, too).
      </>
    ),
    active: false,
  },
];

const FAQ = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>FAQ</h2>
        <div className={styles.accordionsWrapper}>
          {accordionData.map(item => (
            <AboutAccordion
              key={item.id}
              defaultExpanded={item.active}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
