import React from 'react';

import styles from './styles.module.scss';

const BlogCard = ({ title, description, image, url }) => {
  const handleClick = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <div className={styles.wrapper} onClick={() => handleClick(url)}>
      <div className={styles.image}>
        <img src={image} alt="card image" />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default BlogCard;
