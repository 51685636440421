import React, { useState } from 'react';

import styles from './styles.module.scss';

import animation1 from 'assets/gifs/animation-1.gif';
import animation2 from 'assets/gifs/animation-2.gif';

const GIFContent = () => {
  const [showAnimation1, setShowAnimation1] = useState(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h1 className={styles.title}>
            Welcome to Kiwii, the NFT marketplace built on Nahmii
          </h1>
          <div className={styles.description}>
            Enter a new world of NFT trading, with lightning-fast transactions,
            low fees and instant finality.
          </div>
        </div>
        <div
          className={styles.right}
          onClick={() => setShowAnimation1(!showAnimation1)}
        >
          <img
            src={showAnimation1 ? animation1 : animation2}
            alt="kiwii animation"
          />
        </div>
      </div>
    </div>
  );
};

export default GIFContent;
